import {FC, Fragment} from "react";
import styled from "styled-components";
import {TNav} from "../../types/TNav";
import {NavItem} from "./NavItem";

interface INavList {
  title?: string;
  data: Array<TNav>;
  box?: boolean;
  flat?: boolean;
  divider?: boolean;
  iconMuted?: boolean;
}

export const NavList: FC<INavList> = (
  {
    data,
    title,
    box ,
    flat,
    divider,
    iconMuted,
  }) => {
  const list = () => data.map((item, index) => {
    if (divider && index > 0) {
      return (
        <Fragment key={index}>
          <Divider />
          <NavItem
            key={index}
            flat={flat}
            iconMuted={iconMuted}
            { ...item } isUlItem />
        </Fragment>
      );
    }

    return (
      <NavItem
        key={index}
        flat={flat}
        iconMuted={iconMuted}
        { ...item } isUlItem />
    );
  })

  if (box) return (
    <Wrapper>
      {title && <Title $flat={flat}>{title}</Title>}
      <ListWrapper>
        {list()}
      </ListWrapper>
    </Wrapper>
  )

  return (
    <ListWrapper>
      {list()}
    </ListWrapper>
  )
}

const Divider = styled.li`
  background-color: ${({ theme }) => theme.colors.secondary.dark.three};
  height: 1px;
`

const Title = styled.div<{ $flat?:boolean }>`
  font-family: ${({theme}) => theme.fonts.bold.family};
  font-weight: ${({theme}) => theme.fonts.bold.weight};
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.secondary.dark.four};
  
  ${({ $flat }) => {
    if ($flat) return `
      padding: 12px 0;
    `;
    
    return `
      padding: 12px 16px;
    `;
  }}
`

const Wrapper = styled.div`
  padding: 16px 0;
`

const ListWrapper = styled.ul`
  list-style: none;
`
