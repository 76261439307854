import {FC} from "react";
import styled from "styled-components";

export const Favorite: FC<{
  onChange: () => void,
  isActive:boolean,
  size?: 'default' | 'small'
}> = ({ onChange, isActive, size }) => {
  return (
    <Wrapper
      onClick={e => {
        e.stopPropagation();
        onChange();
      }}
      $size={size === 'small'}>
      { isActive
        ? <span className="icon-heart-full active" />
        : <span className="icon-heart" />
      }
    </Wrapper>
  );
};

const Wrapper = styled.button<{ $size?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 0 none;
  border-radius: 50%;
  background-color: ${({theme}) => theme.colors.secondary.dark.two};
  font-size: 16px;
  transition: all 0.12s ease;
  color: ${({theme}) => theme.colors.secondary.dark.six};
  
  &:hover {
    background-color: ${({theme}) => theme.colors.secondary.dark.four};
  }
  
  .active {
    color: ${({theme}) => theme.colors.accent.yellow.default};
  }
  
  transform: scale(1);
  
  &:hover {
    transform: scale(1.12);
  }
  
  ${({ $size }) => {
    if ($size)return `
      width: 32px;
      height: 32px;
    `;
      
    return `
      width: 36px;
      height: 36px;
    `;
  }}
`;
