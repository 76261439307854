import {createContext, FC, ReactNode, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";

type ModalType = 'login' | 'register' | 'forgotPassword';
interface IModalContext {
  isModal: boolean;
  modalType: ModalType | null;
  onModalOpen: (type: ModalType) => void;
  onModalClose: () => void;
}

export const ModalContext = createContext<Partial<IModalContext>>({});

export const ModalProvider: FC<{ children: ReactNode }> = ({children}) => {
  const [state, setState] = useState<{
    isModal: boolean;
    modalType: ModalType | null;
  }>({
    isModal: false,
    modalType: null,
  });
  const location = useLocation();
  const navigate = useNavigate();
  let { pathname } = location;


  const onModalOpen = (type: ModalType) => {
    console.log('onModalOpen', location)
    setState({isModal: true, modalType: type});
  }

  const onModalClose = () => {
    setState(prev => {
      switch (prev.modalType) {
        case 'register':
          console.log('register', location)
          navigate(pathname.replace('/sign-up', ''), { replace: false , state: { someData: 'someValue' }});
          break;
      }

      return { isModal: false, modalType: null };
    });
  }

  return (
    <ModalContext.Provider value={{
      onModalOpen, onModalClose,
      isModal: state.isModal, modalType: state.modalType
    }}>
      { children }
    </ModalContext.Provider>
  );
};
