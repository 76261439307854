import {FC} from "react";
import styled from "styled-components";
import {NavLink} from "react-router-dom";
import {TNavIcon} from "../../types/TNavIcon";

export const NavItem: FC<{
  isUlItem?: boolean;
  flat?: boolean;
  text: string;
  link: string;
  icon?: string;
  iconSize?: TNavIcon;
  target?: '_blank' | '_self' | 'default';
  iconMuted?: boolean;
}> = (
  {
    isUlItem,
    text,
    link,
    icon,
    iconSize,
    target,
    flat,
    iconMuted,
  }) => {

  const Body = () => {
    return (
      <StyledLink to={link} $flat={flat}>
        {icon && <Icon
          $iconMuted={iconMuted}
          className={icon}
          size={iconSize} />}
        <Text>{text}</Text>
        <Arrow className="icon-chevron-sm-right" />
      </StyledLink>
    )
  }

  if (isUlItem) return (
    <WrapperLi>
      <Body />
    </WrapperLi>
  )

  return (
    <WrapperDiv>
      <Body />
    </WrapperDiv>
  )
}

const WrapperDiv = styled.div`

`

const WrapperLi = styled.li`

`

const StyledLink = styled(NavLink)<{ $flat?:boolean }>`
  min-height: 56px;
  text-decoration: none;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.secondary.dark.six};
  transition: color 0.12s ease-in-out;
  
  ${({ $flat }) => {
    if ($flat) return `
      padding: 16px 0;
    `;
    
    return `
      padding: 16px;
    `;
  }}
  
  &:hover {
    color: ${({ theme }) => theme.colors.accent.dark.hover};
  }
  
  &.active {
    color: ${({ theme }) => theme.colors.accent.dark.default};
  }
`

const Icon = styled.i<{ size: "default" | "large" | undefined, $iconMuted?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  max-width: 24px;
  min-width: 24px;
  margin-right: 16px;
  
  ${({ size }) => {
    if (size === "large") {
      return `
        font-size: 28px;
      `;
    }
    return `
      font-size: 20px;
    `;
  }}
  
  ${({ $iconMuted, theme }) => {
    if ($iconMuted) return `
      color: ${theme.colors.secondary.dark.four};
    `;
    
    return `
      color: ${theme.colors.accent.dark.default};
    `;
  }}
`

const Text = styled.span`
  display: block;
  width: 100%;
  font-size: 16px;
  line-height: 22px;
  padding-right: 16px;
`

const Arrow = styled.i`
  height: 24px;
  font-size: 20px;
  color: ${({ theme }) => theme.colors.secondary.dark.four};
`
