import React, {useContext} from "react";
import styled from "styled-components";
import {HeroHomepage} from "../blocks/Promo/HeroHomepage";
import {PaymentsBrands} from "../blocks/Brands/PaymentsBrands";
import {GamesCarousel} from "../blocks/Games/GamesCarousel";
import {TGame} from "../types/TGame";
import {Divider} from "../components/Divider/Divider";
import {JackpotCarousel} from "../blocks/Jackpot/JackpotCarousel";
import {TopWinsTable} from "../blocks/TopWins/TopWinsTable";
import {GamesCollections} from "../blocks/Promo/GamesCollections";
import {TGamesCollection} from "../types/TGamesCollection";
import {TCardFeatures} from "../types/TCardFeatures";
import {FeaturesGames} from "../blocks/Promo/FeaturesGames";
import {Bloggers} from "../blocks/Promo/Bloggers";
import {TBlogger} from "../types/TBlogger";
import {TJackpotProvider} from "../types/TJackpotProvider";
import {TProvider} from "../types/TProvider";
import {ProvidersCarousel} from "../blocks/Providers/ProvidersCarousel";
import {TBrand} from "../types/TBrand";
import {FooterProviders} from "../blocks/Brands/Providers";
import {FixedBar} from "../blocks/Nav/FixedBar";
import {ThemeContext} from "../ThemeSetup";
import {MainBanner} from "../blocks/Hero/MainBanner";
import {FixedNav} from "../components/Navbar/FixedNav";
import {TBanner} from "../types/TBanner";

const heroData: Array<TBanner> = [
  {
    id: 'tournament-01',
    title: 'Mega Million',
    type: 'tournament',
    link: '/tournament',
    image: 'cards/banner/main_01.png',
    backgroundImage: 'cards/banner/banner_01.png',
    prize: {
      value: 30000.00,
      currency: 'USD',
    },
    badge: 'strategySpin',
    borderRadius: true,
  },
  {
    id: 'lottery-01',
    title: 'Grand Lottery',
    type: 'lottery',
    link: '/lottery',
    image: 'cards/banner/main_02.png',
    backgroundImage: 'cards/banner/banner_02.png',
    timerEnd: Date.now() + (1000 * 60 * 24 * 12),
    borderRadius: true,
  },
  {
    id: 'bonus-01',
    title: 'Welcome package',
    type: 'bonus',
    link: '/bonus',
    image: 'cards/banner/main_01.png',
    backgroundImage: 'cards/banner/banner_03.png',
    badge: 'hot',
    description: 'Giving the right gift is an art form that we have mastered! Your special bonus gift',
    borderRadius: true,
  },
];

const gamesNew: Array<TGame> = [
  {
    id: 'buffalodalegrandways',
    title: 'Buffalo Dale: GrandWays',
    image: 'cards/games/buffalodalegrandways.png',
    provider: 'netent',
    url: '/slots-online/buffalodalegrandways',
    isRealMoney: true,
    isNew: false,
    isTop: true,
    isDemoMode: true,
    hotRTP: 96.5,
    jackpot: 'jackpot_01',
    tournament: 'tournament_01',
  },
  {
    id: 'bookofhor',
    title: 'Book of Hor',
    image: 'cards/games/bookofhor.png',
    provider: 'netent',
    url: '/slots-online/bookofhor',
    isRealMoney: false,
    isNew: true,
    isTop: false,
    isDemoMode: true,
    hotRTP: 96.5,
    jackpot: null,
    tournament: null,
  },
  {
    id: 'raginglion',
    title: 'Raging Lion',
    image: 'cards/games/raginglion.png',
    provider: 'netent',
    url: '/slots-online/buffalodalegrandways',
    isRealMoney: true,
    isNew: false,
    isTop: false,
    isDemoMode: true,
    hotRTP: 96.5,
    jackpot: null,
    tournament: null,
  },
  {
    id: 'lordoftheseas',
    title: 'Lord Of The Seas',
    image: 'cards/games/lordoftheseas.png',
    provider: 'netent',
    url: '/slots-online/lordoftheseas',
    isRealMoney: false,
    isNew: false,
    isTop: false,
    isDemoMode: true,
    hotRTP: 96.5,
    jackpot: 'jackpot_01',
    tournament: 'tournament_01',
  },
  {
    id: 'fortunethreexmas',
    title: 'Fortune Five Double',
    image: 'cards/games/fortunethreexmas.png',
    provider: 'netent',
    url: '/slots-online/fortunethreexmas',
    isRealMoney: false,
    isNew: false,
    isTop: false,
    hotRTP: 96.5,
    isDemoMode: true,
    jackpot: null,
    tournament: 'tournament_01',
  },
  {
    id: 'bookofelixir',
    title: 'Book of Elixir',
    image: 'cards/games/bookofelixir.png',
    provider: 'netent',
    url: '/slots-online/bookofelixir',
    isRealMoney: true,
    isNew: false,
    isTop: true,
    isDemoMode: false,
    hotRTP: 96.5,
    jackpot: null,
    tournament: null,
  },
  {
    id: 'witchtreasures',
    title: 'Witch Treasures',
    image: 'cards/games/witchtreasures.png',
    provider: 'netent',
    url: '/slots-online/witchtreasures',
    isRealMoney: false,
    isNew: true,
    isTop: false,
    isDemoMode: false,
    hotRTP: 96.5,
    jackpot: 'jackpot_01',
    tournament: null,
  },
  {
    id: 'fortunethree',
    title: 'Fortune Three',
    image: 'cards/games/fortunethree.png',
    provider: 'netent',
    url: '/slots-online/fortunethree',
    isRealMoney: false,
    isNew: false,
    isTop: true,
    isDemoMode: true,
    hotRTP: 96.5,
    jackpot: 'jackpot_01',
    tournament: 'tournament_01',
  },
  {
    id: 'bookofancients',
    title: 'Book Of Ancients',
    image: 'cards/games/bookofancients.png',
    provider: 'netent',
    url: '/slots-online/bookofancients',
    isRealMoney: false,
    isNew: false,
    isTop: false,
    isDemoMode: false,
    hotRTP: 96.5,
    jackpot: null,
    tournament: null,
  },
  {
    id: 'seasecret',
    title: 'Sea Secret',
    image: 'cards/games/seasecret.png',
    provider: 'netent',
    url: '/slots-online/seasecret',
    isRealMoney: true,
    isNew: true,
    isTop: true,
    isDemoMode: true,
    hotRTP: 96.5,
    jackpot: null,
    tournament: null,
  },
  {
    id: 'maxminer',
    title: 'Max Miner',
    image: 'cards/games/maxminer.png',
    provider: 'netent',
    url: '/slots-online/maxminer',
    isRealMoney: false,
    isNew: false,
    isTop: false,
    isDemoMode: true,
    hotRTP: 96.5,
    jackpot: null,
    tournament: null,
  },
  {
    id: 'throneofcamelot',
    title: 'Throne Of Camelot',
    image: 'cards/games/throneofcamelot.png',
    provider: 'netent',
    url: '/slots-online/throneofcamelot',
    isRealMoney: false,
    isNew: false,
    isTop: false,
    isDemoMode: true,
    hotRTP: 96.5,
    jackpot: null,
    tournament: null,
  },
  {
    id: 'buffalotrail',
    title: 'Buffalo Trail',
    image: 'cards/games/buffalotrail.png',
    provider: 'netent',
    url: '/slots-online/buffalotrail',
    isRealMoney: false,
    isNew: false,
    isTop: false,
    isDemoMode: true,
    hotRTP: 96.5,
    jackpot: null,
    tournament: null,
  },
  {
    id: 'odinstree',
    title: "Odin's Tree",
    image: 'cards/games/odinstree.png',
    provider: 'netent',
    url: '/slots-online/odinstree',
    isRealMoney: false,
    isNew: false,
    isTop: true,
    isDemoMode: true,
    hotRTP: 96.5,
    jackpot: null,
    tournament: 'tournament_01',
  },
  {
    id: 'conquestera',
    title: "Conquest Era",
    image: 'cards/games/conquestera.png',
    provider: 'netent',
    url: '/slots-online/conquestera',
    isRealMoney: false,
    isNew: false,
    isTop: false,
    isDemoMode: false,
    hotRTP: 96.5,
    jackpot: null,
    tournament: null,
  },
  {
    id: 'dangerousmonster',
    title: "Dangerous Monster",
    image: 'cards/games/dangerousmonster.png',
    provider: 'netent',
    url: '/slots-online/dangerousmonster',
    isRealMoney: false,
    isNew: false,
    isTop: false,
    isDemoMode: true,
    hotRTP: 96.5,
    jackpot: null,
    tournament: null,
  },
  {
    id: 'fortunefive',
    title: "Fortune Five",
    image: 'cards/games/fortunefive.png',
    provider: 'netent',
    url: '/slots-online/fortunefive',
    isRealMoney: false,
    isNew: true,
    isTop: false,
    isDemoMode: true,
    hotRTP: 96.5,
    jackpot: null,
    tournament: null,
  },
  {
    id: 'sweetrushmegaways',
    title: "Sweet Rush Megaways",
    image: 'cards/games/sweetrushmegaways.png',
    provider: 'netent',
    url: '/slots-online/sweetrushmegaways',
    isRealMoney: true,
    isNew: false,
    isTop: false,
    isDemoMode: true,
    hotRTP: 96.5,
    jackpot: null,
    tournament: null,
  },
  {
    id: 'luckycashnfruits',
    title: "Lucky Cash'n'Fruit",
    image: 'cards/games/luckycashnfruits.png',
    provider: 'netent',
    url: '/slots-online/luckycashnfruits',
    isRealMoney: false,
    isNew: false,
    isTop: true,
    isDemoMode: false,
    hotRTP: 96.5,
    jackpot: null,
    tournament: 'tournament_01',
  },
];

const gamesPopular: Array<TGame> = [
  {
    id: 'bookofancients',
    title: 'Book Of Ancients',
    image: 'cards/games/bookofancients.png',
    provider: 'netent',
    url: '/slots-online/bookofancients',
    isRealMoney: false,
    isNew: false,
    isTop: false,
    isDemoMode: false,
    hotRTP: 96.5,
    jackpot: null,
    tournament: null,
  },
  {
    id: 'seasecret',
    title: 'Sea Secret',
    image: 'cards/games/seasecret.png',
    provider: 'netent',
    url: '/slots-online/seasecret',
    isRealMoney: true,
    isNew: true,
    isTop: true,
    isDemoMode: true,
    hotRTP: 96.5,
    jackpot: null,
    tournament: null,
  },
  {
    id: 'maxminer',
    title: 'Max Miner',
    image: 'cards/games/maxminer.png',
    provider: 'netent',
    url: '/slots-online/maxminer',
    isRealMoney: false,
    isNew: false,
    isTop: false,
    isDemoMode: true,
    hotRTP: 96.5,
    jackpot: null,
    tournament: null,
  },
  {
    id: 'throneofcamelot',
    title: 'Throne Of Camelot',
    image: 'cards/games/throneofcamelot.png',
    provider: 'netent',
    url: '/slots-online/throneofcamelot',
    isRealMoney: false,
    isNew: false,
    isTop: false,
    isDemoMode: true,
    hotRTP: 96.5,
    jackpot: null,
    tournament: null,
  },
  {
    id: 'buffalotrail',
    title: 'Buffalo Trail',
    image: 'cards/games/buffalotrail.png',
    provider: 'netent',
    url: '/slots-online/buffalotrail',
    isRealMoney: false,
    isNew: false,
    isTop: false,
    isDemoMode: true,
    hotRTP: 96.5,
    jackpot: null,
    tournament: null,
  },
  {
    id: 'odinstree',
    title: "Odin's Tree",
    image: 'cards/games/odinstree.png',
    provider: 'netent',
    url: '/slots-online/odinstree',
    isRealMoney: false,
    isNew: false,
    isTop: true,
    isDemoMode: true,
    hotRTP: 96.5,
    jackpot: null,
    tournament: 'tournament_01',
  },
  {
    id: 'conquestera',
    title: "Conquest Era",
    image: 'cards/games/conquestera.png',
    provider: 'netent',
    url: '/slots-online/conquestera',
    isRealMoney: false,
    isNew: false,
    isTop: false,
    isDemoMode: false,
    hotRTP: 96.5,
    jackpot: null,
    tournament: null,
  },
  {
    id: 'dangerousmonster',
    title: "Dangerous Monster",
    image: 'cards/games/dangerousmonster.png',
    provider: 'netent',
    url: '/slots-online/dangerousmonster',
    isRealMoney: false,
    isNew: false,
    isTop: false,
    isDemoMode: true,
    hotRTP: 96.5,
    jackpot: null,
    tournament: null,
  },
  {
    id: 'fortunefive',
    title: "Fortune Five",
    image: 'cards/games/fortunefive.png',
    provider: 'netent',
    url: '/slots-online/fortunefive',
    isRealMoney: false,
    isNew: true,
    isTop: false,
    isDemoMode: true,
    hotRTP: 96.5,
    jackpot: null,
    tournament: null,
  },
  {
    id: 'sweetrushmegaways',
    title: "Sweet Rush Megaways",
    image: 'cards/games/sweetrushmegaways.png',
    provider: 'netent',
    url: '/slots-online/sweetrushmegaways',
    isRealMoney: true,
    isNew: false,
    isTop: false,
    isDemoMode: true,
    hotRTP: 96.5,
    jackpot: null,
    tournament: null,
  },
  {
    id: 'luckycashnfruits',
    title: "Lucky Cash'n'Fruit",
    image: 'cards/games/luckycashnfruits.png',
    provider: 'netent',
    url: '/slots-online/luckycashnfruits',
    isRealMoney: false,
    isNew: false,
    isTop: true,
    isDemoMode: false,
    hotRTP: 96.5,
    jackpot: null,
    tournament: 'tournament_01',
  },
];

const gamesLive: Array<TGame> = [
  {
    id: 'cashorcrash',
    title: 'Cash or Crash',
    image: 'cards/live/cashorcrash.png',
    provider: 'netent',
    url: '/slots-online/cashorcrash',
    isRealMoney: true,
    isNew: false,
    isTop: false,
    isDemoMode: false,
    hotRTP: null,
    jackpot: null,
    tournament: null,
  },
  {
    id: 'lightningblackjack',
    title: 'Lightning Blackjack',
    image: 'cards/live/lightningblackjack.png',
    provider: 'netent',
    url: '/slots-online/lightningblackjack',
    isRealMoney: true,
    isNew: false,
    isTop: false,
    isDemoMode: false,
    hotRTP: null,
    jackpot: null,
    tournament: null,
  },
  {
    id: 'lightning_roulette',
    title: 'Lightning Roulette',
    image: 'cards/live/lightning_roulette.png',
    provider: 'netent',
    url: '/slots-online/lightning_roulette',
    isRealMoney: true,
    isNew: false,
    isTop: false,
    isDemoMode: false,
    hotRTP: null,
    jackpot: null,
    tournament: null,
  },
  {
    id: 'immersive_roulette',
    title: 'Immersive Roulette',
    image: 'cards/live/immersive_roulette.png',
    provider: 'netent',
    url: '/slots-online/immersive_roulette',
    isRealMoney: true,
    isNew: false,
    isTop: false,
    isDemoMode: false,
    hotRTP: null,
    jackpot: null,
    tournament: null,
  },
  {
    id: 'auto_roulette',
    title: 'Auto Roulette',
    image: 'cards/live/auto_roulette.png',
    provider: 'netent',
    url: '/slots-online/auto_roulette',
    isRealMoney: true,
    isNew: false,
    isTop: false,
    isDemoMode: false,
    hotRTP: null,
    jackpot: null,
    tournament: null,
  },
  {
    id: 'russian_roulette',
    title: 'Russian Roulette',
    image: 'cards/live/russian_roulette.png',
    provider: 'netent',
    url: '/slots-online/russian_roulette',
    isRealMoney: true,
    isNew: false,
    isTop: false,
    isDemoMode: false,
    hotRTP: null,
    jackpot: null,
    tournament: null,
  },
  {
    id: 'monopoly',
    title: "Monopoly",
    image: 'cards/live/monopoly.png',
    provider: 'netent',
    url: '/slots-online/monopoly',
    isRealMoney: true,
    isNew: false,
    isTop: false,
    isDemoMode: false,
    hotRTP: null,
    jackpot: null,
    tournament: null,
  },
  {
    id: 'lightningdice',
    title: "Lightning Dice",
    image: 'cards/live/lightningdice.png',
    provider: 'netent',
    url: '/slots-online/lightningdice',
    isRealMoney: true,
    isNew: false,
    isTop: false,
    isDemoMode: false,
    hotRTP: null,
    jackpot: null,
    tournament: null,
  },
  {
    id: 'infinite_blackjack',
    title: "Infinite Blackjack",
    image: 'cards/live/infinite_blackjack.png',
    provider: 'netent',
    url: '/slots-online/infinite_blackjack',
    isRealMoney: true,
    isNew: false,
    isTop: false,
    isDemoMode: false,
    hotRTP: null,
    jackpot: null,
    tournament: null,
  },
];

const gamesJackpot: Array<TGame> = [
  {
    id: 'buffalotrail',
    title: 'Buffalo Trail',
    image: 'cards/games/buffalotrail.png',
    provider: 'netent',
    url: '/slots-online/buffalotrail',
    isRealMoney: false,
    isNew: false,
    isTop: false,
    isDemoMode: true,
    hotRTP: 96.5,
    jackpot: 'jackpot_01',
    tournament: null,
  },
  {
    id: 'odinstree',
    title: "Odin's Tree",
    image: 'cards/games/odinstree.png',
    provider: 'netent',
    url: '/slots-online/odinstree',
    isRealMoney: false,
    isNew: false,
    isTop: true,
    isDemoMode: true,
    hotRTP: 96.5,
    jackpot: 'jackpot_01',
    tournament: 'tournament_01',
  },
  {
    id: 'conquestera',
    title: "Conquest Era",
    image: 'cards/games/conquestera.png',
    provider: 'netent',
    url: '/slots-online/conquestera',
    isRealMoney: false,
    isNew: false,
    isTop: false,
    isDemoMode: false,
    hotRTP: 96.5,
    jackpot: 'jackpot_01',
    tournament: null,
  },
  {
    id: 'dangerousmonster',
    title: "Dangerous Monster",
    image: 'cards/games/dangerousmonster.png',
    provider: 'netent',
    url: '/slots-online/dangerousmonster',
    isRealMoney: false,
    isNew: false,
    isTop: false,
    isDemoMode: true,
    hotRTP: 96.5,
    jackpot: 'jackpot_01',
    tournament: null,
  },
  {
    id: 'fortunefive',
    title: "Fortune Five",
    image: 'cards/games/fortunefive.png',
    provider: 'netent',
    url: '/slots-online/fortunefive',
    isRealMoney: false,
    isNew: true,
    isTop: false,
    isDemoMode: true,
    hotRTP: 96.5,
    jackpot: 'jackpot_01',
    tournament: null,
  },
  {
    id: 'sweetrushmegaways',
    title: "Sweet Rush Megaways",
    image: 'cards/games/sweetrushmegaways.png',
    provider: 'netent',
    url: '/slots-online/sweetrushmegaways',
    isRealMoney: true,
    isNew: false,
    isTop: false,
    isDemoMode: true,
    hotRTP: 96.5,
    jackpot: 'jackpot_01',
    tournament: null,
  },
  {
    id: 'luckycashnfruits',
    title: "Lucky Cash'n'Fruit",
    image: 'cards/games/luckycashnfruits.png',
    provider: 'netent',
    url: '/slots-online/luckycashnfruits',
    isRealMoney: false,
    isNew: false,
    isTop: true,
    isDemoMode: false,
    hotRTP: 96.5,
    jackpot: 'jackpot_01',
    tournament: 'tournament_01',
  },
  {
    id: 'buffalodalegrandways',
    title: 'Buffalo Dale: GrandWays',
    image: 'cards/games/buffalodalegrandways.png',
    provider: 'netent',
    url: '/slots-online/buffalodalegrandways',
    isRealMoney: true,
    isNew: false,
    isTop: true,
    isDemoMode: true,
    hotRTP: 96.5,
    jackpot: 'jackpot_01',
    tournament: 'tournament_01',
  },
  {
    id: 'bookofhor',
    title: 'Book of Hor',
    image: 'cards/games/bookofhor.png',
    provider: 'netent',
    url: '/slots-online/bookofhor',
    isRealMoney: false,
    isNew: true,
    isTop: false,
    isDemoMode: true,
    hotRTP: 96.5,
    jackpot: 'jackpot_01',
    tournament: null,
  },
  {
    id: 'raginglion',
    title: 'Raging Lion',
    image: 'cards/games/raginglion.png',
    provider: 'netent',
    url: '/slots-online/buffalodalegrandways',
    isRealMoney: true,
    isNew: false,
    isTop: false,
    isDemoMode: true,
    hotRTP: 96.5,
    jackpot: 'jackpot_01',
    tournament: null,
  },
  {
    id: 'lordoftheseas',
    title: 'Lord Of The Seas',
    image: 'cards/games/lordoftheseas.png',
    provider: 'netent',
    url: '/slots-online/lordoftheseas',
    isRealMoney: false,
    isNew: false,
    isTop: false,
    isDemoMode: true,
    hotRTP: 96.5,
    jackpot: 'jackpot_01',
    tournament: 'tournament_01',
  },
  {
    id: 'fortunethreexmas',
    title: 'Fortune Five Double',
    image: 'cards/games/fortunethreexmas.png',
    provider: 'netent',
    url: '/slots-online/fortunethreexmas',
    isRealMoney: false,
    isNew: false,
    isTop: false,
    hotRTP: 96.5,
    isDemoMode: true,
    jackpot: 'jackpot_01',
    tournament: 'tournament_01',
  },
  {
    id: 'bookofelixir',
    title: 'Book of Elixir',
    image: 'cards/games/bookofelixir.png',
    provider: 'netent',
    url: '/slots-online/bookofelixir',
    isRealMoney: true,
    isNew: false,
    isTop: true,
    isDemoMode: false,
    hotRTP: 96.5,
    jackpot: 'jackpot_01',
    tournament: null,
  },
];

const jackpotProviders: Array<TJackpotProvider> = [
  {
    name: 'netEnt',
    title: 'NetEnt',
    logoUrl: 'providers/normal/netent.svg',
    url: '/netent',
    gamesCount: 123,
    jackpot: {
      currency: 'EUR',
      value: 123456,
    }
  },
  {
    name: 'playNGo',
    title: "Play'n Go",
    logoUrl: 'providers/normal/playn_go.svg',
    url: '/play_n_go',
    gamesCount: 45,
    jackpot: {
      currency: 'EUR',
      value: 123456,
    }
  },
  {
    name: 'microgaming',
    title: "Microgaming",
    logoUrl: 'providers/normal/microgaming.svg',
    url: '/microgaming',
    gamesCount: 234,
    jackpot: {
      currency: 'EUR',
      value: 123456,
    }
  },
  {
    name: 'yggdrasil',
    title: "Yggdrasil",
    logoUrl: 'providers/normal/yggdrasil.svg',
    url: '/yggdrasil',
    gamesCount: 45,
    jackpot: {
      currency: 'EUR',
      value: 123456,
    }
  },
  {
    name: 'gamzix',
    title: "Gamzix",
    logoUrl: 'providers/normal/gamzix.svg',
    url: '/gamzix',
    gamesCount: 66,
    jackpot: {
      currency: 'EUR',
      value: 123456,
    }
  },
  {
    name: 'amatic',
    title: "Amatic",
    logoUrl: 'providers/normal/amatic.svg',
    url: '/amatic',
    gamesCount: 66,
    jackpot: {
      currency: 'EUR',
      value: 123456,
    }
  },
  {
    name: 'egt',
    title: "EGT",
    logoUrl: 'providers/normal/egt.svg',
    url: '/amatic',
    gamesCount: 38,
    jackpot: {
      currency: 'EUR',
      value: 123456,
    }
  },
  {
    name: 'igrosoft',
    title: "Igrosoft",
    logoUrl: 'providers/normal/igrosoft.svg',
    url: '/igrosoft',
    gamesCount: 112,
    jackpot: {
      currency: 'EUR',
      value: 123456,
    }
  },
  {
    name: 'pragmaticPlay',
    title: "Pragmatic Play",
    logoUrl: 'providers/normal/pragmatic_play.svg',
    url: '/pragmatic_play',
    gamesCount: 205,
    jackpot: {
      currency: 'EUR',
      value: 123456,
    }
  },
  {
    name: 'thunderkick',
    title: "Thunderkick",
    logoUrl: 'providers/normal/thunderkick.svg',
    url: '/thunderkick',
    gamesCount: 45,
    jackpot: {
      currency: 'EUR',
      value: 123456,
    }
  },
];

const gamesCollectBooks: Array<TGame> = [
  {
    id: 'bookofancients',
    title: 'Book Of Ancients',
    image: 'cards/games/bookofancients.png',
    provider: 'netent',
    url: '/slots-online/bookofancients',
    isRealMoney: false,
    isNew: false,
    isTop: false,
    isDemoMode: false,
    hotRTP: 96.5,
    jackpot: null,
    tournament: null,
  },
  {
    id: 'throneofcamelot',
    title: 'Throne Of Camelot',
    image: 'cards/games/throneofcamelot.png',
    provider: 'netent',
    url: '/slots-online/throneofcamelot',
    isRealMoney: false,
    isNew: false,
    isTop: false,
    isDemoMode: true,
    hotRTP: 96.5,
    jackpot: null,
    tournament: null,
  },
  {
    id: 'buffalotrail',
    title: 'Buffalo Trail',
    image: 'cards/games/buffalotrail.png',
    provider: 'netent',
    url: '/slots-online/buffalotrail',
    isRealMoney: false,
    isNew: false,
    isTop: false,
    isDemoMode: true,
    hotRTP: 96.5,
    jackpot: null,
    tournament: null,
  },
  {
    id: 'odinstree',
    title: "Odin's Tree",
    image: 'cards/games/odinstree.png',
    provider: 'netent',
    url: '/slots-online/odinstree',
    isRealMoney: false,
    isNew: false,
    isTop: true,
    isDemoMode: true,
    hotRTP: 96.5,
    jackpot: null,
    tournament: 'tournament_01',
  },
  {
    id: 'conquestera',
    title: "Conquest Era",
    image: 'cards/games/conquestera.png',
    provider: 'netent',
    url: '/slots-online/conquestera',
    isRealMoney: false,
    isNew: false,
    isTop: false,
    isDemoMode: false,
    hotRTP: 96.5,
    jackpot: null,
    tournament: null,
  },
  {
    id: 'dangerousmonster',
    title: "Dangerous Monster",
    image: 'cards/games/dangerousmonster.png',
    provider: 'netent',
    url: '/slots-online/dangerousmonster',
    isRealMoney: false,
    isNew: false,
    isTop: false,
    isDemoMode: true,
    hotRTP: 96.5,
    jackpot: null,
    tournament: null,
  },
  {
    id: 'fortunefive',
    title: "Fortune Five",
    image: 'cards/games/fortunefive.png',
    provider: 'netent',
    url: '/slots-online/fortunefive',
    isRealMoney: false,
    isNew: true,
    isTop: false,
    isDemoMode: true,
    hotRTP: 96.5,
    jackpot: null,
    tournament: null,
  },
  {
    id: 'sweetrushmegaways',
    title: "Sweet Rush Megaways",
    image: 'cards/games/sweetrushmegaways.png',
    provider: 'netent',
    url: '/slots-online/sweetrushmegaways',
    isRealMoney: true,
    isNew: false,
    isTop: false,
    isDemoMode: true,
    hotRTP: 96.5,
    jackpot: null,
    tournament: null,
  },
  {
    id: 'seasecret',
    title: 'Sea Secret',
    image: 'cards/games/seasecret.png',
    provider: 'netent',
    url: '/slots-online/seasecret',
    isRealMoney: true,
    isNew: true,
    isTop: true,
    isDemoMode: true,
    hotRTP: 96.5,
    jackpot: null,
    tournament: null,
  },
  {
    id: 'maxminer',
    title: 'Max Miner',
    image: 'cards/games/maxminer.png',
    provider: 'netent',
    url: '/slots-online/maxminer',
    isRealMoney: false,
    isNew: false,
    isTop: false,
    isDemoMode: true,
    hotRTP: 96.5,
    jackpot: null,
    tournament: null,
  },
  {
    id: 'luckycashnfruits',
    title: "Lucky Cash'n'Fruit",
    image: 'cards/games/luckycashnfruits.png',
    provider: 'netent',
    url: '/slots-online/luckycashnfruits',
    isRealMoney: false,
    isNew: false,
    isTop: true,
    isDemoMode: false,
    hotRTP: 96.5,
    jackpot: null,
    tournament: 'tournament_01',
  },
];

const collections: Array<TGamesCollection> = [
  {
    title: 'Crime Collection Lorem',
    url: '/crime-collection',
    image: 'cards/collections/image_01.png',
    itemsCount: 123,
    previewGames: [
      { title: 'Book Of Ancients', image: 'cards/games/bookofancients.png', url: '/bookofancients' },
      { title: 'Buffalo Trail', image: 'cards/games/buffalotrail.png', url: '/buffalotrail' },
      { title: 'Max Miner', image: 'cards/games/maxminer.png', url: '/maxminer' },
      { title: "Odin's Tree", image: 'cards/games/odinstree.png', url: '/odinstree' },
      { title: "Sea Secret", image: 'cards/games/seasecret.png', url: '/seasecret' },
    ],
  },
  {
    title: 'Books Collection',
    url: '/crime-collection',
    image: 'cards/collections/image_02.png',
    itemsCount: 243,
    previewGames: [
      { title: 'Witch Treasure', image: 'cards/games/witchtreasures.png', url: '/witchtreasures' },
      { title: 'Lord Of The Seas', image: 'cards/games/lordoftheseas.png', url: '/lordoftheseas' },
      { title: 'Fortune Three', image: 'cards/games/fortunethree.png', url: '/fortunethree' },
      { title: "Conquest Era", image: 'cards/games/conquestera.png', url: '/conquestera' },
      { title: "Book Of Elixir", image: 'cards/games/bookofelixir.png', url: '/bookofelixir' },
    ],
  },
  {
    title: 'Monster Collection',
    url: '/crime-collection',
    image: 'cards/collections/image_03.png',
    itemsCount: 34,
    previewGames: [
      { title: 'Dangerous Monster', image: 'cards/games/dangerousmonster.png', url: '/dangerousmonster' },
      { title: 'Book Of Hor', image: 'cards/games/bookofhor.png', url: '/bookofhor' },
      { title: 'Fortune Five', image: 'cards/games/fortunefive.png', url: '/fortunefive' },
      { title: "Lucky Cash'n Fruits", image: 'cards/games/luckycashnfruits.png', url: '/luckycashnfruits' },
      { title: "Book Of Elixir", image: 'cards/games/bookofelixir.png', url: '/bookofelixir' },
    ],
  },
  {
    title: 'Crime Collection Lorem',
    url: '/crime-collection',
    image: 'cards/collections/image_04.png',
    itemsCount: 123,
    previewGames: [
      { title: 'Book Of Ancients', image: 'cards/games/bookofancients.png', url: '/bookofancients' },
      { title: 'Buffalo Trail', image: 'cards/games/buffalotrail.png', url: '/buffalotrail' },
      { title: 'Max Miner', image: 'cards/games/maxminer.png', url: '/maxminer' },
      { title: "Odin's Tree", image: 'cards/games/odinstree.png', url: '/odinstree' },
      { title: "Sea Secret", image: 'cards/games/seasecret.png', url: '/seasecret' },
    ],
  },
  {
    title: 'Books Collection',
    url: '/crime-collection',
    image: 'cards/collections/image_05.png',
    itemsCount: 243,
    previewGames: [
      { title: 'Witch Treasure', image: 'cards/games/witchtreasures.png', url: '/witchtreasures' },
      { title: 'Lord Of The Seas', image: 'cards/games/lordoftheseas.png', url: '/lordoftheseas' },
      { title: 'Fortune Three', image: 'cards/games/fortunethree.png', url: '/fortunethree' },
      { title: "Conquest Era", image: 'cards/games/conquestera.png', url: '/conquestera' },
      { title: "Book Of Elixir", image: 'cards/games/bookofelixir.png', url: '/bookofelixir' },
    ],
  },
];

const features: Array<TCardFeatures> = [
  {
    title: 'Expanding Symbols ',
    url: '/crime-collection',
    color: 'salmon',
    itemsCount: 123,
    previewGames: [
      { title: 'Book Of Ancients', image: 'cards/games/bookofancients.png', url: '/bookofancients' },
      { title: 'Buffalo Trail', image: 'cards/games/buffalotrail.png', url: '/buffalotrail' },
      { title: 'Max Miner', image: 'cards/games/maxminer.png', url: '/maxminer' },
      { title: "Odin's Tree", image: 'cards/games/odinstree.png', url: '/odinstree' },
      { title: "Sea Secret", image: 'cards/games/seasecret.png', url: '/seasecret' },
    ],
  },
  {
    title: 'Megaways',
    url: '/crime-collection',
    color: 'indired',
    itemsCount: 243,
    previewGames: [
      { title: 'Witch Treasure', image: 'cards/games/witchtreasures.png', url: '/witchtreasures' },
      { title: 'Lord Of The Seas', image: 'cards/games/lordoftheseas.png', url: '/lordoftheseas' },
      { title: 'Fortune Three', image: 'cards/games/fortunethree.png', url: '/fortunethree' },
      { title: "Conquest Era", image: 'cards/games/conquestera.png', url: '/conquestera' },
      { title: "Book Of Elixir", image: 'cards/games/bookofelixir.png', url: '/bookofelixir' },
    ],
  },
  {
    title: 'Bonus Games',
    url: '/crime-collection',
    color: 'blue',
    itemsCount: 34,
    previewGames: [
      { title: 'Dangerous Monster', image: 'cards/games/dangerousmonster.png', url: '/dangerousmonster' },
      { title: 'Book Of Hor', image: 'cards/games/bookofhor.png', url: '/bookofhor' },
      { title: 'Fortune Five', image: 'cards/games/fortunefive.png', url: '/fortunefive' },
      { title: "Lucky Cash'n Fruits", image: 'cards/games/luckycashnfruits.png', url: '/luckycashnfruits' },
      { title: "Book Of Elixir", image: 'cards/games/bookofelixir.png', url: '/bookofelixir' },
    ],
  },
  {
    title: 'Respins',
    url: '/crime-collection',
    color: 'green',
    itemsCount: 123,
    previewGames: [
      { title: 'Book Of Ancients', image: 'cards/games/bookofancients.png', url: '/bookofancients' },
      { title: 'Buffalo Trail', image: 'cards/games/buffalotrail.png', url: '/buffalotrail' },
      { title: 'Max Miner', image: 'cards/games/maxminer.png', url: '/maxminer' },
      { title: "Odin's Tree", image: 'cards/games/odinstree.png', url: '/odinstree' },
      { title: "Sea Secret", image: 'cards/games/seasecret.png', url: '/seasecret' },
    ],
  },
  {
    title: 'Bonus Buy',
    url: '/crime-collection',
    color: 'purple',
    itemsCount: 243,
    previewGames: [
      { title: 'Witch Treasure', image: 'cards/games/witchtreasures.png', url: '/witchtreasures' },
      { title: 'Lord Of The Seas', image: 'cards/games/lordoftheseas.png', url: '/lordoftheseas' },
      { title: 'Fortune Three', image: 'cards/games/fortunethree.png', url: '/fortunethree' },
      { title: "Conquest Era", image: 'cards/games/conquestera.png', url: '/conquestera' },
      { title: "Book Of Elixir", image: 'cards/games/bookofelixir.png', url: '/bookofelixir' },
    ],
  },
  {
    title: 'Instant Games',
    url: '/crime-collection',
    color: 'cian',
    itemsCount: 123,
    previewGames: [
      { title: 'Book Of Ancients', image: 'cards/games/bookofancients.png', url: '/bookofancients' },
      { title: 'Buffalo Trail', image: 'cards/games/buffalotrail.png', url: '/buffalotrail' },
      { title: 'Max Miner', image: 'cards/games/maxminer.png', url: '/maxminer' },
      { title: "Odin's Tree", image: 'cards/games/odinstree.png', url: '/odinstree' },
      { title: "Sea Secret", image: 'cards/games/seasecret.png', url: '/seasecret' },
    ],
  },
  {
    title: 'Expanding Symbols',
    url: '/crime-collection',
    color: 'darkblue',
    itemsCount: 243,
    previewGames: [
      { title: 'Witch Treasure', image: 'cards/games/witchtreasures.png', url: '/witchtreasures' },
      { title: 'Lord Of The Seas', image: 'cards/games/lordoftheseas.png', url: '/lordoftheseas' },
      { title: 'Fortune Three', image: 'cards/games/fortunethree.png', url: '/fortunethree' },
      { title: "Conquest Era", image: 'cards/games/conquestera.png', url: '/conquestera' },
      { title: "Book Of Elixir", image: 'cards/games/bookofelixir.png', url: '/bookofelixir' },
    ],
  },
  {
    title: 'Bonus Buy',
    url: '/crime-collection',
    color: 'orange',
    itemsCount: 34,
    previewGames: [
      { title: 'Dangerous Monster', image: 'cards/games/dangerousmonster.png', url: '/dangerousmonster' },
      { title: 'Book Of Hor', image: 'cards/games/bookofhor.png', url: '/bookofhor' },
      { title: 'Fortune Five', image: 'cards/games/fortunefive.png', url: '/fortunefive' },
      { title: "Lucky Cash'n Fruits", image: 'cards/games/luckycashnfruits.png', url: '/luckycashnfruits' },
      { title: "Book Of Elixir", image: 'cards/games/bookofelixir.png', url: '/bookofelixir' },
    ],
  },
];

const bloggers: Array<TBlogger> = [
  {
    title: 'Takes out the casino Egor creed',
    isViewed: false,
    url: '/bloggers/egor-creed',
    image: 'cards/bloggers/image_01.png',
    game: {
      title: 'Buffalo Dale: GrandWays',
      image: 'cards/games/buffalodalegrandways.png',
      url: '/buffalodalegrandways',
      provider: {
        title: 'netent',
        url: '/buffalodalegrandways',
      }
    }
  },
  {
    title: 'Takes out the casino Egor creed',
    isViewed: true,
    url: '/bloggers/egor-creed',
    image: 'cards/bloggers/image_02.png',
    game: {
      title: 'Raging Lion',
      image: 'cards/games/raginglion.png',
      url: '/buffalodalegrandways',
      provider: {
        title: 'netent',
        url: '/buffalodalegrandways',
      }
    }
  },
  {
    title: 'Takes out the casino Egor creed',
    isViewed: true,
    url: '/bloggers/egor-creed',
    image: 'cards/bloggers/image_03.png',
    game: {
      title: "Fortune Five",
      image: 'cards/games/fortunefive.png',
      url: '/buffalodalegrandways',
      provider: {
        title: 'netent',
        url: '/buffalodalegrandways',
      }
    }
  },
  {
    title: 'Takes out the casino Egor creed',
    isViewed: false,
    url: '/bloggers/egor-creed',
    image: 'cards/bloggers/image_04.png',
    game: {
      title: "Sweet Rush Megaways",
      image: 'cards/games/sweetrushmegaways.png',
      url: '/buffalodalegrandways',
      provider: {
        title: 'netent',
        url: '/buffalodalegrandways',
      }
    }
  },
  {
    title: 'Takes out the casino Egor creed',
    isViewed: false,
    url: '/bloggers/egor-creed',
    image: 'cards/bloggers/image_05.png',
    game: {
      title: "Odin's Tree",
      image: 'cards/games/odinstree.png',
      url: '/buffalodalegrandways',
      provider: {
        title: 'netent',
        url: '/buffalodalegrandways',
      }
    }
  },
  {
    title: 'Takes out the casino Egor creed',
    isViewed: false,
    url: '/bloggers/egor-creed',
    image: 'cards/bloggers/image_01.png',
    game: {
      title: "Dangerous Monster",
      image: 'cards/games/dangerousmonster.png',
      url: '/buffalodalegrandways',
      provider: {
        title: 'netent',
        url: '/buffalodalegrandways',
      }
    }
  },
  {
    title: 'Takes out the casino Egor creed',
    isViewed: false,
    url: '/bloggers/egor-creed',
    image: 'cards/bloggers/image_02.png',
    game: {
      title: 'Raging Lion',
      image: 'cards/games/raginglion.png',
      url: '/buffalodalegrandways',
      provider: {
        title: 'netent',
        url: '/buffalodalegrandways',
      }
    }
  },
  {
    title: 'Takes out the casino Egor creed',
    isViewed: false,
    url: '/bloggers/egor-creed',
    image: 'cards/bloggers/image_03.png',
    game: {
      title: "Fortune Five",
      image: 'cards/games/fortunefive.png',
      url: '/buffalodalegrandways',
      provider: {
        title: 'netent',
        url: '/buffalodalegrandways',
      }
    }
  },
];

const providers: Array<TProvider> = [
  {
    name: 'netEnt',
    title: 'NetEnt',
    logoUrl: 'providers/normal/netent.svg',
    url: '/netent',
    gamesCount: 123
  },
  {
    name: 'playNGo',
    title: "Play'n Go",
    logoUrl: 'providers/normal/playn_go.svg',
    url: '/play_n_go',
    gamesCount: 45
  },
  {
    name: 'microgaming',
    title: "Microgaming",
    logoUrl: 'providers/normal/microgaming.svg',
    url: '/microgaming',
    gamesCount: 234
  },
  {
    name: 'yggdrasil',
    title: "Yggdrasil",
    logoUrl: 'providers/normal/yggdrasil.svg',
    url: '/yggdrasil',
    gamesCount: 45
  },
  {
    name: 'gamzix',
    title: "Gamzix",
    logoUrl: 'providers/normal/gamzix.svg',
    url: '/gamzix',
    gamesCount: 66
  },
  {
    name: 'amatic',
    title: "Amatic",
    logoUrl: 'providers/normal/amatic.svg',
    url: '/amatic',
    gamesCount: 66
  },
  {
    name: 'egt',
    title: "EGT",
    logoUrl: 'providers/normal/egt.svg',
    url: '/amatic',
    gamesCount: 38
  },
  {
    name: 'igrosoft',
    title: "Igrosoft",
    logoUrl: 'providers/normal/igrosoft.svg',
    url: '/igrosoft',
    gamesCount: 112
  },
  {
    name: 'pragmaticPlay',
    title: "Pragmatic Play",
    logoUrl: 'providers/normal/pragmatic_play.svg',
    url: '/pragmatic_play',
    gamesCount: 205
  },
  {
    name: 'thunderkick',
    title: "Thunderkick",
    logoUrl: 'providers/normal/thunderkick.svg',
    url: '/thunderkick',
    gamesCount: 45
  },
];

const gamesRtp: Array<TGame> = [
  {
    id: 'bookofancients',
    title: 'Book Of Ancients',
    image: 'cards/games/bookofancients.png',
    provider: 'netent',
    url: '/slots-online/bookofancients',
    isRealMoney: false,
    isNew: false,
    isTop: false,
    isDemoMode: false,
    hotRTP: 96.5,
    jackpot: 'jackpot_01',
    tournament: null,
  },
  {
    id: 'seasecret',
    title: 'Sea Secret',
    image: 'cards/games/seasecret.png',
    provider: 'netent',
    url: '/slots-online/seasecret',
    isRealMoney: true,
    isNew: true,
    isTop: true,
    isDemoMode: true,
    hotRTP: 107.3,
    jackpot: null,
    tournament: null,
  },
  {
    id: 'buffalotrail',
    title: 'Buffalo Trail',
    image: 'cards/games/buffalotrail.png',
    provider: 'netent',
    url: '/slots-online/buffalotrail',
    isRealMoney: false,
    isNew: false,
    isTop: false,
    isDemoMode: true,
    hotRTP: 203.6,
    jackpot: 'jackpot_01',
    tournament: null,
  },
  {
    id: 'odinstree',
    title: "Odin's Tree",
    image: 'cards/games/odinstree.png',
    provider: 'netent',
    url: '/slots-online/odinstree',
    isRealMoney: false,
    isNew: false,
    isTop: true,
    isDemoMode: true,
    hotRTP: 96.6,
    jackpot: null,
    tournament: 'tournament_01',
  },
  {
    id: 'conquestera',
    title: "Conquest Era",
    image: 'cards/games/conquestera.png',
    provider: 'netent',
    url: '/slots-online/conquestera',
    isRealMoney: false,
    isNew: false,
    isTop: false,
    isDemoMode: false,
    hotRTP: 126,
    jackpot: null,
    tournament: null,
  },
  {
    id: 'dangerousmonster',
    title: "Dangerous Monster",
    image: 'cards/games/dangerousmonster.png',
    provider: 'netent',
    url: '/slots-online/dangerousmonster',
    isRealMoney: false,
    isNew: false,
    isTop: false,
    isDemoMode: true,
    hotRTP: 209,
    jackpot: 'jackpot_01',
    tournament: null,
  },
  {
    id: 'fortunefive',
    title: "Fortune Five",
    image: 'cards/games/fortunefive.png',
    provider: 'netent',
    url: '/slots-online/fortunefive',
    isRealMoney: false,
    isNew: true,
    isTop: false,
    isDemoMode: true,
    hotRTP: 304.2,
    jackpot: null,
    tournament: null,
  },
  {
    id: 'sweetrushmegaways',
    title: "Sweet Rush Megaways",
    image: 'cards/games/sweetrushmegaways.png',
    provider: 'netent',
    url: '/slots-online/sweetrushmegaways',
    isRealMoney: true,
    isNew: false,
    isTop: false,
    isDemoMode: true,
    hotRTP: 96.5,
    jackpot: 'jackpot_01',
    tournament: null,
  },
  {
    id: 'luckycashnfruits',
    title: "Lucky Cash'n'Fruit",
    image: 'cards/games/luckycashnfruits.png',
    provider: 'netent',
    url: '/slots-online/luckycashnfruits',
    isRealMoney: false,
    isNew: false,
    isTop: true,
    isDemoMode: false,
    hotRTP: 245.45,
    jackpot: null,
    tournament: 'tournament_01',
  },
  {
    id: 'maxminer',
    title: 'Max Miner',
    image: 'cards/games/maxminer.png',
    provider: 'netent',
    url: '/slots-online/maxminer',
    isRealMoney: false,
    isNew: false,
    isTop: false,
    isDemoMode: true,
    hotRTP: 123,
    jackpot: null,
    tournament: null,
  },
  {
    id: 'throneofcamelot',
    title: 'Throne Of Camelot',
    image: 'cards/games/throneofcamelot.png',
    provider: 'netent',
    url: '/slots-online/throneofcamelot',
    isRealMoney: false,
    isNew: false,
    isTop: false,
    isDemoMode: true,
    hotRTP: 296.5,
    jackpot: null,
    tournament: null,
  },
];

const brands: Array<TBrand> = [
  {
    title: 'NetEnt',
    image: 'providers/footer/netent.svg',
    hover: 'providers/footer/netent_h.svg',
    url: '/netent',
  },
  {
    title: 'EGT',
    image: 'providers/footer/egt.svg',
    hover: 'providers/footer/egt_h.svg',
    url: '/egt',
  },
  {
    title: 'Amatic',
    image: 'providers/footer/amatic.svg',
    hover: 'providers/footer/amatic_h.svg',
    url: '/amatic',
  },
  {
    title: 'Endorphina',
    image: 'providers/footer/endorphina.svg',
    hover: 'providers/footer/endorphina_h.svg',
    url: '/endorphina',
  },
  {
    title: 'Relax',
    image: 'providers/footer/relax.svg',
    hover: 'providers/footer/relax_h.svg',
    url: '/relax',
  },
  {
    title: 'Gamzix',
    image: 'providers/footer/gamzix.svg',
    hover: 'providers/footer/gamzix_h.svg',
    url: '/gamzix',
  },
  {
    title: 'Booming Games',
    image: 'providers/footer/booming_games.svg',
    hover: 'providers/footer/booming_games_h.svg',
    url: '/booming_games',
  },
  {
    title: 'Igrosoft',
    image: 'providers/footer/igrosoft.svg',
    hover: 'providers/footer/igrosoft_h.svg',
    url: '/igrosoft',
  },
  {
    title: 'Pragmatic Play',
    image: 'providers/footer/pragmatic_play.svg',
    hover: 'providers/footer/pragmatic_play_h.svg',
    url: '/pragmatic_play',
  },
  {
    title: 'Yggdrasil',
    image: 'providers/footer/yggdrasil.svg',
    hover: 'providers/footer/yggdrasil_h.svg',
    url: '/yggdrasil',
  },
  {
    title: 'Microgaming',
    image: 'providers/footer/microgaming.svg',
    hover: 'providers/footer/microgaming_h.svg',
    url: '/microgaming',
  },
  {
    title: 'Playtech',
    image: 'providers/footer/playtech.svg',
    hover: 'providers/footer/playtech_h.svg',
    url: '/playtech',
  },
  {
    title: 'Thunderkick',
    image: 'providers/footer/thunderkick.svg',
    hover: 'providers/footer/thunderkick_h.svg',
    url: '/thunderkick',
  },
  {
    title: '1Spin4Win',
    image: 'providers/footer/1spin4win.svg',
    hover: 'providers/footer/1spin4win_h.svg',
    url: '/1spin4win',
  },
  {
    title: 'ELK',
    image: 'providers/footer/elk.svg',
    hover: 'providers/footer/elk_h.svg',
    url: '/elk',
  },
  {
    title: 'Red Tiger',
    image: 'providers/footer/redtiger.svg',
    hover: 'providers/footer/redtiger_h.svg',
    url: '/redtiger',
  },
  {
    title: 'Evolution Gaming',
    image: 'providers/footer/evolution_gaming.svg',
    hover: 'providers/footer/evolution_gaming_h.svg',
    url: '/evolution_gaming',
  },
  {
    title: 'Bet Soft',
    image: 'providers/footer/betsoft.svg',
    hover: 'providers/footer/betsoft_h.svg',
    url: '/betsoft',
  },
  {
    title: 'Wazdan',
    image: 'providers/footer/wazdan.svg',
    hover: 'providers/footer/wazdan_h.svg',
    url: '/wazdan',
  },
  {
    title: '1x2 Gaming',
    image: 'providers/footer/1x2_gaming.svg',
    hover: 'providers/footer/1x2_gaming_h.svg',
    url: '/1x2_gaming',
  },
  {
    title: 'Fugaso',
    image: 'providers/footer/fugaso.svg',
    hover: 'providers/footer/fugaso_h.svg',
    url: '/fugaso',
  },
  {
    title: 'iSoftBet',
    image: 'providers/footer/isoftbet.svg',
    hover: 'providers/footer/isoftbet_h.svg',
    url: '/isoftbet',
  },
  {
    title: 'Rabcat',
    image: 'providers/footer/rabcat.svg',
    hover: 'providers/footer/rabcat_h.svg',
    url: '/rabcat',
  },
  {
    title: 'BTG',
    image: 'providers/footer/btg.svg',
    hover: 'providers/footer/btg_h.svg',
    url: '/btg',
  },
  {
    title: 'Felix Gaming',
    image: 'providers/footer/felix_gaming.svg',
    hover: 'providers/footer/felix_gaming_h.svg',
    url: '/felix_gaming',
  },
  {
    title: 'Blue Print',
    image: 'providers/footer/blueprint.svg',
    hover: 'providers/footer/blueprint_h.svg',
    url: '/blueprint',
  },
  {
    title: 'Merkur Gaming',
    image: 'providers/footer/merkur_gaming.svg',
    hover: 'providers/footer/merkur_gaming_h.svg',
    url: '/merkur_gaming',
  },
  {
    title: 'August Gaming',
    image: 'providers/footer/august_gaming.svg',
    hover: 'providers/footer/august_gaming_h.svg',
    url: '/august_gaming',
  },
  {
    title: '2by2 Gaming',
    image: 'providers/footer/2by2_gaming.svg',
    hover: 'providers/footer/2by2_gaming_h.svg',
    url: '/2by2_gaming',
  },
  {
    title: 'Nolimit',
    image: 'providers/footer/nolimit.svg',
    hover: 'providers/footer/nolimit_h.svg',
    url: '/nolimit',
  },
  {
    title: 'CT Interactive',
    image: 'providers/footer/ctinteractive.svg',
    hover: 'providers/footer/ctinteractive_h.svg',
    url: '/ctinteractive',
  },
  {
    title: 'Genesis Gaming',
    image: 'providers/footer/genesis_gaming.svg',
    hover: 'providers/footer/genesis_gaming_h.svg',
    url: '/genesis_gaming',
  },
  {
    title: 'Zillion',
    image: 'providers/footer/zillion.svg',
    hover: 'providers/footer/zillion_h.svg',
    url: '/zillion',
  },
  {
    title: 'Iron Dog',
    image: 'providers/footer/iron_dog.svg',
    hover: 'providers/footer/iron_dog_h.svg',
    url: '/iron_dog',
  },
  {
    title: 'Mancala Gaming',
    image: 'providers/footer/mancala_gaming.svg',
    hover: 'providers/footer/mancala_gaming_h.svg',
    url: '/mancala_gaming',
  },
  {
    title: 'Onlyplay',
    image: 'providers/footer/onlyplay.svg',
    hover: 'providers/footer/onlyplay_h.svg',
    url: '/onlyplay',
  },
  {
    title: 'Tom Horn',
    image: 'providers/footer/tom_horn.svg',
    hover: 'providers/footer/tom_horn_h.svg',
    url: '/tom_horn',
  },
  {
    title: 'Mascot Gaming',
    image: 'providers/footer/mascot_gaming.svg',
    hover: 'providers/footer/mascot_gaming_h.svg',
    url: '/mascot_gaming',
  },
];

export const MainPage = () => {
  const {screen} = useContext(ThemeContext);

  return (
    <Wrapper>
      {screen?.size === 'mob' && screen.touch
        ? <>
          <MainBanner data={heroData} />
        </>
        : <>
          <HeroHomepage data={heroData} />
          <PaymentsBrands />
          <FixedNav />
        </>
      }

      <GamesCarousel
        title="New"
        type="dual"
        moreUrl="/games"
        data={gamesNew} />

      <Divider />

      <GamesCarousel
        title="Popular"
        size="small"
        moreUrl="/popular"
        data={gamesPopular} />

      <Divider />

      <GamesCarousel
        title="Live Games"
        size="large"
        moreUrl="/live"
        data={gamesLive} />

      <Divider />

      <JackpotCarousel
        providers={jackpotProviders}
        data={gamesJackpot} />

      <TopWinsTable />

      <GamesCarousel
        title="Collection Books"
        size="verySmall"
        data={gamesCollectBooks} />

      <Divider />

      <GamesCollections
        data={collections}
        moreUrl="/collections" />

      <Divider />

      <FeaturesGames
        data={features}
        moreUrl="/collections" />

      <Divider />

      <Bloggers data={bloggers} />

      <Divider />

      <ProvidersCarousel data={providers} />

      <Divider />

      <GamesCarousel
        title="Popular"
        type="rtp"
        moreUrl="/popular"
        data={gamesRtp} />

      <FooterProviders data={brands} />

    </Wrapper>
  )
};

const Wrapper = styled.div`
  
`;
