import {FC, useContext, useEffect, useState} from "react";
import styled from "styled-components";
import {TGame} from "../../types/TGame";
import {Favorite} from "../Button/Favorite";
import {Link} from "react-router-dom";
import {Ribbon} from "../Ribbon/Ribbon";
import grImg from "./gradient_image.png"
import {ThemeContext} from "../../ThemeSetup";
import {GamesContext} from "../../providers/GamesProvider";

export const CardGame: FC<{
  data: TGame,
  isRtp?: boolean,
  size?: 'large' | 'default' | 'small' | 'verySmall'
}> = ({ data, isRtp, size }) => {
  const { id, title, image, provider, url, isRealMoney, isNew, isTop, isDemoMode, hotRTP, jackpot, tournament } = data;
  const [state, setState] = useState({
    isFavorite: false,
    hover: false,
    isOpened: false
  });
  const {screen} = useContext(ThemeContext);
  const { openedGame, onCloseGame, onOpenGame } = useContext(GamesContext);

  const handleHover = (hover: boolean) => {
    if (screen?.touch) return;
    setState(prev => ({...prev, hover}));
  };

  useEffect(() => {
    if (state.isOpened && openedGame?.id !== id) {
      setState(prev => ({...prev, isOpened: false}));
    }
  }, [openedGame, state.isOpened]);

  const handleOpenGame = () => {
    if (!state.isOpened) {
      onOpenGame && onOpenGame(id);
      setState(prev => ({...prev, isOpened: true}));
    } else {
      onCloseGame && onCloseGame();
      setState(prev => ({...prev, isOpened: false}));
    }
  };


  return (
    <Wrapper
      onClick={() => handleOpenGame()}
      onMouseEnter={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}>
      { !screen?.touch && <Content>
        <Header $hover={state.hover}>
          <span>{title}</span>
        </Header>
        <Interface>
          <Provider $hover={state.hover}>
            <ProviderButton
              onClick={e => e.stopPropagation()}
              to="/netent">
              <ProviderImage src="providers/rounded/netent.svg" alt="Netent" />
            </ProviderButton>
          </Provider>
          <PlayWrapper $hover={state.hover}>
            <PlayButton
              onClick={e => e.stopPropagation()}
              to={url} >
              <i className="icon-play" />
            </PlayButton>
          </PlayWrapper>
          <FavoriteWrapper $hover={state.hover}>
            <Favorite
              size={screen?.size !== 'desc' ? 'small' : 'default'}
              onChange={() => setState(prev => ({...prev, isFavorite: !prev.isFavorite}))}
              isActive={state.isFavorite} />
          </FavoriteWrapper>
        </Interface>
        <Footer></Footer>
      </Content> }
      { !(screen?.touch && screen?.size === 'mob') && <PromoInfo $hover={state.hover}>
        { tournament && <span>Tournament</span> }
        { isRealMoney && <span>RM</span> }
      </PromoInfo> }

      { !screen?.touch && isRealMoney && <RealMoney $hover={state.hover}>
          <i className="icon-real-money" /> Real Money
        </RealMoney>
      }

      { !screen?.touch && isDemoMode && <DemoButton
        onClick={e => e.stopPropagation()}
        $hover={state.hover} to="/demo">
          Demo
        </DemoButton>
      }

      { (isRtp || jackpot) && <Jackpot $hover={state.hover}>
          { isRtp ? <>RTP: {hotRTP}%</> : <>234 566 775.34 $</> }
        </Jackpot>
      }

      <ImageWrapper $hover={state.hover}>
        { (isRtp || jackpot) &&
          <Gradient $isRtp={isRtp} $hover={state.hover} />
        }
        <RibbonWrapper>
          { isNew
            ? <Ribbon type="new" dir="left" />
            : isTop && <Ribbon type="top" dir="left" />
          }
        </RibbonWrapper>
        <ImageBody $hover={state.hover}>
          <Image src={image} alt={title} />
        </ImageBody>
      </ImageWrapper>

      <OpenIndicator
        $hover={state.hover}
        $isOpened={state.isOpened} />
    </Wrapper>
  );
};


const OpenIndicator = styled.div<{ $hover: boolean, $isOpened: boolean }>`
  pointer-events: none;
  position: absolute;
  z-index: 500;
  border: 2px solid #FFDE6A;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  
  ${({$isOpened, $hover, theme}) => {
    const {screen} = theme;
    
    if ($isOpened) {
      
      if ($hover) return `
          border-radius: 28px;
          top: -10px;
          left: -10px;
          bottom: -10px;
          right: -10px;
          opacity: 1;
      `;
      
      return `
        border-radius: 28px;
        top: -2px;
        left: -2px;
        bottom: -2px;
        right: -2px;
        opacity: 1;
      `;
    }
    
    if (screen.size === 'tab' || screen.size === 'mob' && !screen.touch) return `
      border-radius: 16px;
    `;

    if (screen.size === 'mob' && screen.touch) return `
      border-radius: 12px;
    `;

    return `
      border-radius: 20px;
    `;
  }}
`;

const Wrapper = styled.div`
  position: relative;
  cursor: pointer;
  z-index: 100;
  padding-top: 123.81%;
  background-color: ${({theme}) => theme.colors.secondary.dark.two};
  border-radius: 20px;
  
  &:hover {
    z-index: 200;
  }
`;

const Jackpot = styled.div<{$hover: boolean}>`
  pointer-events: none;
  position: absolute;
  z-index: 300;
  left: 50%;
  transform: translate(-50%, 0);
  white-space: nowrap;
  font-family: ${({theme}) => theme.fonts.bold.family};
  font-weight: ${({theme}) => theme.fonts.bold.weight};
  
  ${({theme}) => {
    const {screen} = theme;
    if (screen.size === 'tab' || screen.size === 'mob' && !screen.touch) return `
      font-size: 14px;
      line-height: 16px;
    `;
    
    if (screen.size === 'mob' && screen.touch) return `
      font-size: 12px;
      line-height: 16px;
    `;

    return `
      font-size: 16px;
      line-height: 20px;
    `;
  }}
  
  ${({$hover, theme}) => {
    if ($hover) {
      return `
        opacity: 0;
        bottom: 20px;
        transition: all 0s ease;
      `;
    }

    const {screen} = theme;
    if (screen.size === 'mob' && screen.touch) return `
      opacity: 1;
      bottom: 8px;
      transition: all 0.2s ease;
    `;

    return `
      opacity: 1;
      bottom: 12px;
      transition: all 0.2s ease;
    `;
  }}
`;

const Gradient = styled.div<{ $isRtp?: boolean, $hover: boolean }>`
  position: absolute;
  z-index: 200;
  bottom: 0;
  left: 0;
  right: 0;
  height: 80px;

  ${({theme}) => {
    const {screen} = theme;
    if (screen.size === 'tab' || screen.size === 'mob' && !screen.touch) return `
      border-radius: 0 0 16px 16px;
    `;

    if (screen.size === 'mob' && screen.touch) return `
      border-radius: 0 0 12px 12px;
    `;

    return `
      border-radius: 0 0 20px 20px;
    `;
  }}
  
  ${({$isRtp}) => {
    if ($isRtp) {
      return `
        background: url(${grImg}), linear-gradient(180deg, rgba(220, 82, 72, 0) 0%, rgba(220, 82, 72, 0.8) 66.67%, rgba(220, 82, 72, 0.96) 100%);
      `;
    }
    return `
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.52) 66.67%, rgba(0, 0, 0, 0.624) 100%);
    `;
  }}

  transition: all 0.12s ease;
  ${({$hover}) => {
    if ($hover) return `
      opacity: 0;
    `;

    return `
      opacity: 1;
    `;
  }}
`;

const PromoInfo = styled.div<{$hover: boolean}>`
  position: absolute;
  z-index: 300;
  font-family: ${({theme}) => theme.fonts.bold.family};
  font-weight: ${({theme}) => theme.fonts.bold.weight};
  text-transform: uppercase;
  color: ${({theme}) => theme.colors.accent.yellow.default};
  margin: 0 -4px;

  ${({theme}) => {
    const {screen} = theme;
    if (screen.size === 'tab' || screen.size === 'mob') return `
      right: 12px;
      top: 8px;
      font-size: 10px;
      line-height: 16px;
    `;

    return `
      right: 12px;
      top: 12px;
      font-size: 12px;
      line-height: 16px;
    `;
  }}
  
  span {
    padding: 0 4px;
  }
  
  ${({$hover}) => {
    if ($hover) return `
      opacity: 0;
    `;

    return `
      opacity: 1;
    `;
  }}
`;

const RibbonWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 300;
`;

const Provider = styled.div<{$hover: boolean}>`
  width: 100%;
  padding-left: 8px;
  display: flex;
  align-items: center;
  

  transition: all 0.12s ease;
  ${({$hover}) => {
    if ($hover) return `
      opacity: 1;
      transform: scale(1) translate(0, 0);
    `;

    return `
      opacity: 0;
      transform: scale(0.08) translate(24px, 0);
    `;
  }}
`;

const ProviderButton = styled(Link)`
  position: relative;
  display: flex;
  
  img {
    transform: scale(1);
    transition: transform 0.12s ease;
  }
  
  &:hover {
    img {
      transform: scale(1.2);
    }
  }
  
  ${({theme}) => {
    const {screen} = theme;
    if (screen.size === 'tab' || screen.size === 'mob') return `
      img {
        width: 32px;
        height: 32px;
      }
    `;

    return `
      img {
        width: 36px;
        height: 36px;
      }
    `;
  }}
`;

const ProviderImage = styled.img`
  width: 36px;
  height: 36px;
`;

const PlayWrapper = styled.div<{$hover: boolean}>`
  min-height: 48px;
  max-height: 48px;
  min-width: 48px;
  max-width: 48px;

  transition: all 0.12s ease;
  ${({$hover}) => {
    if ($hover) return `
      opacity: 1;
    `;

    return `
      opacity: 0;
    `;
  }}
`;

const Header = styled.h3<{$hover: boolean}>`
  height: 100%;
  font-family: ${({theme}) => theme.fonts.header.family};
  font-weight: ${({theme}) => theme.fonts.header.weight};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 8px;

  ${({theme}) => {
    const {screen} = theme;
    if (screen.size === 'tab' || screen.size === 'mob') return `
      font-size: 14px;
      line-height: 18px;
    `;

    return `
      font-size: 16px;
      line-height: 20px;
    `;
  }}
  
  span {
    transition: all 0.2s ease;
  }
  
  transition: all 0.2s ease;
  ${({$hover}) => {
    if ($hover) return `
      span {
        transform: translate(0, 0);
        opacity: 1;
      }
    `;

    return `
      span {
        transform: translate(0, 12px);
        opacity: 0;
      }
    `;
  }}
`;

const Interface = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 48px;
  max-height: 48px;
`;

const Footer = styled.div`
  height: 100%;
`;

const Content = styled.div`
  position: absolute;
  z-index: 300;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
`;

const ImageWrapper = styled.div<{$hover: boolean}>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  ${({$hover}) => {
    if ($hover) return `
      top: -8px;
      bottom: -8px;
      right: -8px;
      left: -8px;
      border-radius: 28px;
      
      img {
        transform: scale(1.12);
        transition: all 0.12s ease;
      }
    `;

    return `
      transition: all 0.12s ease;
      img {
        transform: scale(1);
        transition: all 0.12s ease;
      }
    `;
  }}
`;

const ImageBody = styled.div<{$hover: boolean}>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  
  ${({theme}) => {
    const {screen} = theme;
    if (screen.size === 'tab' || screen.size === 'mob' && !screen.touch) return `
      border-radius: 16px;
    `;
    
    if (screen.size === 'mob' && screen.touch) return `
      border-radius: 12px;
    `;
    
    return `
      border-radius: 20px;
    `;
  }}
  
  &::after {
    content: "";
    position: absolute;
    z-index: 200;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.68);
    opacity: 0;
    transition: all 0.08s ease;
  }

  ${({$hover}) => {
    if ($hover) return `
      &::after {
        opacity: 1;
      }
    `;

    return ``;
  }}
`;

const Image = styled.img`
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const FavoriteWrapper = styled.div<{$hover: boolean}>`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 8px;

  transition: all 0.12s ease;
  ${({$hover}) => {
    if ($hover) return `
      opacity: 1;
      transform: scale(1) translate(0, 0);
    `;

    return `
      opacity: 0;
      transform: scale(0.08) translate(-24px, 0);
    `;
  }}
`;

const PlayButton = styled(Link)`
  width: 48px;
  height: 48px;
  padding-left: 8px;
  text-decoration: none;
  color: ${({theme}) => theme.colors.secondary.dark.six};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.12s ease;
    
  &:hover {
    color: ${({theme}) => theme.colors.secondary.dark.six};
  }
  
  ${({theme}) => {
    const {screen} = theme;
    if (screen.size === 'tab' || screen.size === 'mob') return `
      font-size: 36px;
      &:hover {
        font-size: 44px;
      }
    `;

    return `
      font-size: 40px;
      &:hover {
        font-size: 48px;
      }
    `;
  }}
`;

const RealMoney = styled.div<{$hover: boolean}>`
  white-space: nowrap;
  position: absolute;
  z-index: 300;
  left: 50%;
  transform: translate(-50%, 0);
  color: ${({theme}) => theme.colors.accent.yellow.default};
  font-size: 14px;
  
  i {
    margin-right: 4px;
  }

  transition: all 0.12s ease;
  ${({theme, $hover}) => {
    const {screen} = theme;
    if (screen.size === 'tab' || screen.size === 'mob') {
      if ($hover) return `
        opacity: 1;
        bottom: 28px;
      `;

      return `
        opacity: 0;
        bottom: 36px;
      `;
    }

    if ($hover) return `
      opacity: 1;
      bottom: 40px;
    `;

    return `
      opacity: 0;
      bottom: 48px;
    `;
  }}
`;

const DemoButton = styled(Link)<{$hover: boolean}>`
  position: absolute;
  z-index: 300;
  font-family: ${({theme}) => theme.fonts.bold.family};
  font-weight: ${({theme}) => theme.fonts.bold.weight};
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: ${({theme}) => theme.colors.secondary.dark.six};
  text-decoration: none;
  left: 50%;
  transform: translate(-50%, 0);
  transition: all 0.12s ease;
  
  &:hover {
    text-decoration: underline;
    color: ${({theme}) => theme.colors.accent.dark.hover};
  }
  
  ${({theme}) => {
    const {screen} = theme;
    if (screen.size === 'tab' || screen.size === 'mob') return `
      bottom: 16px;
    `;

    return `
      bottom: 20px;
    `;
  }}
  
  ${({$hover, theme}) => {
    if ($hover) {
      const {screen} = theme;
      if (screen.size === 'tab' || screen.size === 'mob') return `
        bottom: 4px;
      `;
      return `
        bottom: 12px;
      `;
    }

    return `
      opacity: 0;
    `;
  }}
`;
