import {FC} from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";

export const Social: FC = () => {
  return (
    <Wrapper>
      <Item>
        <StyledLink to="#">
          <i className="icon-telegram" />
        </StyledLink>
      </Item>
      <Item>
        <StyledLink to="#">
          <i className="icon-facebook" />
        </StyledLink>
      </Item>
      <Item>
        <StyledLink to="#">
          <i className="icon-google" />
        </StyledLink>
      </Item>
    </Wrapper>
  );
};

const Wrapper = styled.ul`
  display: flex;
  list-style: none;
  margin: 0 -6px;
`;

const Item = styled.li`
  padding: 0 6px;
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-decoration: none;
  font-size: 16px;
  line-height: 24px;
  background-color: ${({ theme }) => theme.colors.secondary.dark.three};
  color: ${({ theme }) => theme.colors.secondary.dark.six};
  transition: all 0.12s ease-in-out;
  
  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary.dark.six};
    color: ${({ theme }) => theme.colors.secondary.dark.one};
  }
`;
