import {FC} from "react";
import styled from "styled-components";
import {Image} from "../Image/Image";
import {Currency} from "../Currency/Currency";



export const TableGameInfo: FC<{ data: Array<{ key: string, value: string }> }> = ({ data }) => {
  return (
    <Wrapper>
      {data.map((item, index) => {
        if (index % 2 === 0) {
          const even = data[index + 1];
          return <Row key={index}>
            <Item>
              <Title className="text">{item.key}</Title>
              <Value>{item.value}</Value>
            </Item>

            {even && <Item>
              <Title className="text">{even.key}</Title>
                <Value>{even.value}</Value>
            </Item>}
          </Row>
        }
      })}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  z-index: 100;

  >div {
    &:nth-child(odd) {
      background-color: ${({theme}) => theme.colors.secondary.dark.two};
    }
  }
`;

const Row = styled.div`
  display: flex;
  padding: 0 12px;
`;

const Item = styled.div`
  font-family: ${({theme}) => theme.fonts.bold.family};
  font-weight: ${({theme}) => theme.fonts.bold.weight};
  letter-spacing: 0.04em;
  text-transform: uppercase;
  width: 100%;
  padding: 16px 12px;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  position: relative;
  
  .image {
    transition: all 0.12s ease;
    transform: scale(1);
  }
  
  .text {
    transition: all 0.12s ease;
  }
  
  &:hover {
    .image {
      transform: scale(1.32);
    }

    .text {
      color: ${({theme}) => theme.colors.secondary.dark.six};
    }
  }
`;

const Title = styled.div`
  width: 100%;
  color: ${({theme}) => theme.colors.secondary.dark.five};
`;


const Value = styled.div`
  color: ${({theme}) => theme.colors.secondary.dark.six};
  white-space: nowrap;
`;
