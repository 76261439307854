import {FC, ReactNode, useContext, useEffect, useState} from "react";
import styled from "styled-components";
import {RightBarScroll} from "../components/Scroll/RightBarScroll";
import {GamesContext} from "../providers/GamesProvider";
import {RightGame} from "../right/RightGame";


export const Right: FC<{ children?: ReactNode; }> = ({ children }) => {
  const { openedGame } = useContext(GamesContext);
  const [state, setState] = useState({
    openGame: false,
  });

  useEffect(() => {
    if (!!openedGame) {
      setState({
        openGame: true,
      });
    } else {
      setState({
        openGame: false,
      });
    }
  }, [openedGame]);

  return (
    <Wrapper>
      <Main $slide={state.openGame}>
        {children}
      </Main>
      <Added $slide={state.openGame}>
        <AddedWrapper $slide={state.openGame}>
          <RightBarScroll>
            <RightGame />
          </RightBarScroll>
        </AddedWrapper>
      </Added>
    </Wrapper>
  );
};

const Main = styled.div<{ $slide: boolean }>`
  position: relative;
  transition: all 0.12s ease-in-out;
  
  ${({ $slide }) => {
    if ($slide) return `
      left: -50%;
      opacity: 0;
    `;
    
    return `
      left: 0;
      opacity: 1;
    `;
  }}
`;

const AddedWrapper = styled.div<{ $slide: boolean }>`
  position: relative;
  background-color: ${({theme}) => theme.colors.secondary.dark.one};
  transition: all 0.12s ease-in-out;
  height: 100%;
  width: 100%;
  
  ${({ $slide }) => {
    if ($slide) return `
      left: 0;
    `;
    
    return `
      left: 100%;
    `;
  }}
`;

const Added = styled.div<{ $slide: boolean }>`
  position: fixed;
  z-index: 1000;
  top: 0;
  bottom: 0;
  padding-top: 64px;
  overflow: hidden;

  ${({ $slide }) => {
    if ($slide) return `
    `;

    return `
      pointer-events: none;
    `;
  }}
  
  ${({theme}) => {
    const {breakpoint} = theme.screen;
    const {rightBar} = theme.resolutions[breakpoint];

    return `
      min-width: ${rightBar}px;
      max-width: ${rightBar}px;
    `;
  }}
`;

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  min-height: 100vh;
`;
