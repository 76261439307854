import {FC, useContext, useEffect} from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";
import {NavbarRightPanel} from "./NavbarRightPanel";
import {NavbarContext} from "../../providers/Nabvar";


type TNabvar = {
  onBurger: () => void;
  isBurger: boolean;
}

export const NavbarMobile: FC<TNabvar> = ({onBurger, isBurger}) => {
  const {bgOpacity} = useContext(NavbarContext);

  return (
    <Wrapper
      style={{
        backgroundColor: `rgba(34, 37, 37, ${bgOpacity !== undefined ? bgOpacity / 100 : 0.6})`,
      }}
      $bgOpacity={bgOpacity}>
      <Left>
        <BurgerButton onClick={onBurger}>
          <BurgerImage src="./burger_lg.svg" alt="" />
        </BurgerButton>
        <Logo to="/">
          <LogoImage src="./logo.svg" alt={ 'Drip' } />
        </Logo>
      </Left>
      <Right>
        <NavbarRightPanel />
      </Right>
    </Wrapper>
  );
};


const Wrapper = styled.div<{ $bgOpacity?: number }>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  top: 0;
  left: 0;
  right: 0;
  backdrop-filter: blur(4px);
`;

const Left = styled.div`
  display: flex;
  align-items: center;
`;

const BurgerButton = styled.button`
  cursor: pointer;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0 none;
  height: 60px;
  width: 52px;
`;

const BurgerImage = styled.img`
  height: 28px;
`;

const Right = styled.div`
  
`;

const Logo = styled(Link)`
  display: flex;
  align-items: center;
  height: 32px;
`;

const LogoImage = styled.img`
  height: 24px;
`;
