import {FC, useContext, useState} from "react";
import styled from "styled-components";
import {CardBanner} from "../../components/Card/CardBanner";
import {TBanner} from "../../types/TBanner";
import {makeId} from "../../helpers/makeId";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import {ThemeContext} from "../../ThemeSetup";




export const HeroHomepage: FC<{ data: Array<TBanner> }> = ({ data }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const {screen} = useContext(ThemeContext);

  const [sliderRef, instanceRef] = useKeenSlider({initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
    created() {
      setLoaded(true)
    },
    loop: false,
    mode: screen?.touch ? "free" :"snap",
    rtl: false,
    breakpoints: {
      "(min-width: 0px)": {
        slides: {
          spacing: 20,
          perView: "auto",
        },
      },
      "(min-width: 1366px)": {
        slides: {
          spacing: 24,
          perView: "auto",
        },
      },
    },
  });

  return (
    <Wrapper>
      {screen?.size !== 'mob' && <Left className="arrow">
        <ArrowButtonRounded
          onClick={(e: any) =>
            e.stopPropagation() || instanceRef.current?.prev()
          }
          disabled={currentSlide === 0}>
          <i className="icon-chevron-sm-left" />
        </ArrowButtonRounded>
      </Left>}
      <SliderWrapper>
        <Slider ref={sliderRef} className="keen-slider" >
          {data.map((item, index) =>
            <SliderItem key={index} className="keen-slider__slide" >
              <CardBanner data={item} />
            </SliderItem>
          )}

        </Slider>
      </SliderWrapper>
      {screen?.size !== 'mob' && <Right className="arrow">
        { loaded && <ArrowButtonRounded
          onClick={(e: any) =>
            e.stopPropagation() || instanceRef.current?.next()
          }
          disabled={instanceRef.current?.track.details.maxIdx === instanceRef.current?.track.details.abs} >
          <i className="icon-chevron-sm-right" />
        </ArrowButtonRounded> }
      </Right>}
    </Wrapper>
  );
};

const SliderItem = styled.div`
  overflow: unset;
  ${({theme}) => {
    const {screen} = theme;
    if (screen?.size === 'tab' || screen?.size === 'mob') return `
      min-width: 348px;
      max-width: 348px;
    `;

    return `
      min-width: 432px;
      max-width: 432px;
    `;
  }}
`;

const Slider = styled.div`
  overflow: visible!important;
  ${({theme}) => {
    const {screen} = theme;
    if (screen?.size !== 'desc') return `
      padding: 20px 0;
    `;

    return `
      padding: 32px 0;
    `;
  }}
`;

const Wrapper = styled.div`
  display: flex;
  overflow: hidden;
  
  .arrow {
    opacity: 0;
    transition: all 0.12s ease;
  }
  
  &:hover {
    .arrow {
      opacity: 1;
    }
  }

  ${({ theme }) => {
    if (theme.screen?.size === 'mob') return `
      padding: 0 16px;
    `;
    return ``;
  }}
`;

const Left = styled.div`
  position: relative;
  z-index: 100;
  display: flex;
  align-items: center;
  pointer-events: none;
  
  ${({theme}) => {
    const {screen} = theme;
    if (screen?.breakpoint === 'desc_sm' || screen?.size === 'tab') return `
      max-width: 32px;
      width: 32px;
      >button {
        transform: translateX(12px);
        pointer-events: all;
      }
    `;
    
    return `
      width: 40px;
      >button {
        transform: translateX(20px);
        pointer-events: all;
      }
    `;
  }}
`;

const SliderWrapper = styled.div`
  position: relative;
  z-index: 1;
  min-width: 100%;

  ${({theme}) => {
    const {screen} = theme;
    if (screen?.breakpoint === 'desc_sm' || screen?.size === 'tab') return `
      margin: 0 -32px;
      padding: 0 32px;
    `;

    return `
      margin: 0 -40px;
      padding: 0 40px;
    `;
  }}
`;

const Right = styled.div`
  position: relative;
  z-index: 100;
  display: flex;
  align-items: center;
  pointer-events: none;

  ${({theme}) => {
    const {screen} = theme;
    if (screen?.breakpoint === 'desc_sm' || screen?.size === 'tab') return `
      max-width: 32px;
      width: 32px;
      >button {
        transform: translateX(-12px);
        pointer-events: all;
      }
    `;

    return `
      width: 40px;
      >button {
        transform: translateX(-20px);
        pointer-events: all;
      }
    `;
  }}
`;

const ArrowButtonRounded = styled.button`
  border: 0 none;
  cursor: pointer;
  max-width: 40px;
  max-height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({theme}) => theme.colors.secondary.dark.six};
  color: ${({theme}) => theme.colors.secondary.dark.one};
  transition: all 0.12s ease;
  
  &:hover {
    background-color: ${({theme}) => theme.colors.secondary.dark.five};
  }
  
  &:active {
    background-color: ${({theme}) => theme.colors.secondary.dark.six};
  }
  
  &:disabled {
    pointer-events: none;
    opacity: 0;
  }
`;
