import {FC} from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";

interface IRightBarGameImage {
  src: string;
  alt: string;
  onClose: () => void;
  jackpot?: {
    value: string;
    currency: string;
  };
  tournament?: {
    title: string;
    url: string;
  };
  type?: 'default' | 'tablet'
}

export const RightBarGameImage: FC<IRightBarGameImage> = (
  {
    src,
    alt,
    onClose,
    tournament,
    jackpot,
    type
  }) => {
  return (
    <Wrapper style={{
      backgroundImage: `url(${src})`,
    }}>
      <Header $small={type === 'tablet'}>
        { type !== 'tablet' &&
          <CloseButton onClick={() => onClose && onClose()}>
            <i className="icon-close_bold" />
          </CloseButton>
        }
      </Header>
      <ImageBox>
        <Image alt={alt} src={src} />
      </ImageBox>
      <Footer>
        { jackpot &&
          <FooterItem $centered={!tournament}>
            1 245 673.54 $
            <FooterLabel>Jackpot</FooterLabel>
          </FooterItem>
        }
        { tournament &&
          <FooterItem $centered={!jackpot}>
            <FooterLink to={tournament.url}>
              {tournament.title}
            </FooterLink>
            <FooterLabel>Tournament</FooterLabel>
          </FooterItem>
        }
      </Footer>
    </Wrapper>
  );
};

const FooterItem = styled.div<{ $centered: boolean }>`
  font-family: ${({theme}) => theme.fonts.header.family};
  font-weight: ${({theme}) => theme.fonts.header.weight};
  font-size: 24px;
  line-height: 28px;
  color: ${({theme}) => theme.colors.secondary.dark.six};
  height: 92px;
  padding-top: 24px;
  
  ${({ $centered }) => {
    if ($centered) return `
      width: 100%;
      text-align: center;
    `;
    
    return `
      &:last-child {
        text-align: right;
      }
    `;
  }}
`;

const FooterLink = styled(Link)`
  color: ${({theme}) => theme.colors.secondary.dark.six};
  text-decoration: none;
`;

const FooterLabel = styled.div`
  font-family: ${({theme}) => theme.fonts.bold.family};
  font-weight: ${({theme}) => theme.fonts.bold.weight};
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  opacity: 0.6;
  padding-top: 4px;
`;

const Footer = styled.div`
  width: 100%;
  min-height: 72px;
  position: relative;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  padding: 0 20px 0;
`;

const CloseButton = styled.button`
  cursor: pointer;
  background: none;
  border: none 0;
  width: 44px;
  height: 44px;
  font-size: 22px;
  color: ${({theme}) => theme.colors.secondary.dark.six};
  transition: all 0.12s ease-in-out;
  
  &:hover {
    color: ${({theme}) => theme.colors.secondary.dark.five};
  }
`;

const Image = styled.img`
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Header = styled.div<{ $small: boolean }>`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  position: relative;
  z-index: 100;
  padding: 8px 12px;
  height: ${({ $small }) => $small ? '36px' : '80px'};
`;

const ImageBox = styled.div`
  width: 208px;
  height: 256px;
  position: relative;
  z-index: 100;
  border-bottom: 20px;
  box-shadow: 0 28px 48px rgba(0, 0, 0, 0.62);
  border-radius: 20px;
  overflow: hidden;
`;

const Wrapper = styled.div`
  background-size: cover;
  background-position: center;
  position: relative;    
  display: flex;
  flex-direction: column;
  align-items: center;
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.48);
    backdrop-filter: blur(40px);
  }
`;