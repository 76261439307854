import {FC, useContext} from "react";
import styled from "styled-components";
import {Badge} from "../Badge/Badge";
import {ThemeContext} from "../../ThemeSetup";
import {Timer} from "../CounterBox/Timer";
import {Button} from "../Button/Button";

export const GameTournament: FC = () => {
  const {screen} = useContext(ThemeContext);

  return (
    <Wrapper>
      <Header>
        <SubTitle>
          Tournament
          <Badge icon="icon-circle-info">
            <span className="muted">strategy:</span> Spin
          </Badge>
        </SubTitle>
        <Title>
          Hot Sands
        </Title>
      </Header>

      <Body>
        <BodyItem>
          <Timer time={Date.now() + 23456234} />
          <BodyLabel>Timer end</BodyLabel>
        </BodyItem>
        <BodyItem>
          1 200 000 $
          <BodyLabel>Prize Pool</BodyLabel>
        </BodyItem>
      </Body>

      <Footer>
        <Button url="/tournament" theme="borderDark">
          More Info
        </Button>
      </Footer>
    </Wrapper>
  );
};


const Footer = styled.div`
  padding-top: 27px;
`;

const BodyItem = styled.div`
  font-family: ${({theme}) => theme.fonts.header.family};
  font-weight: ${({theme}) => theme.fonts.header.weight};
  font-size: 24px;
  line-height: 28px;
  color: ${({theme}) => theme.colors.secondary.dark.six};
  
  &:last-child {
    text-align: right;
  }
`;

const BodyLabel = styled.div`
  font-family: ${({theme}) => theme.fonts.bold.family};
  font-weight: ${({theme}) => theme.fonts.bold.weight};
  color: ${({theme}) => theme.colors.system.yellow};
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  padding-top: 4px;
`;

const Body = styled.div`
  width: 100%;
  position: relative;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  padding: 16px 0 0;
`;

const SubTitle = styled.div`
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h3`
  color: ${({theme}) => theme.colors.secondary.dark.five};
  font-size: 28px;
  line-height: 32px;
  padding-top: 2px;
`;

const Header = styled.div`
  font-family: ${({theme}) => theme.fonts.header.family};
  font-weight: ${({theme}) => theme.fonts.header.weight};
`;

const Wrapper = styled.div`
  padding: 24px;
`;
