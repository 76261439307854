import React, {FC, useEffect, useState} from "react";
import styled from "styled-components";


export const JackpotCounter: FC<{
  value: number,
  currency: string,
  size?: 'default' | 'small',
}> = ({ value, currency, size }) => {

  const set = '123 456 '
  const [state, setState] = useState({
    value: '669.12',
  })

  useEffect(() => {
    const t = setInterval(() => {
      let num1: any = Math.floor(Math.random() * 1000);
      if (num1 < 100) {
        num1 = num1 + '0'
      }

      let num2: any = Math.floor(Math.random() * 100);
      if (num2 < 10) {
        num2 = num2 + '0';
      }

      if (num1.length < 3) {
        num1 = '666';
      }
      setState(prev => ({ ...prev, value: num1 + '.' + num2 }));
    }, 1000)

    return () => clearInterval(t)
  }, [])

  return (
    <Wrapper $size={size}>
      { set + state.value + ' $'}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ $size?: 'default' | 'small' }>`
  font-family: ${({ theme }) => theme.fonts.header.family};
  font-weight: ${({ theme }) => theme.fonts.header.weight};
  color: ${({ theme }) => theme.colors.accent.yellow.default};
  text-align: center;
  white-space: nowrap;

  ${({ theme, $size }) => {
    const { screen } = theme;
    if ($size === 'small') {
      if (screen?.size === 'tab') return `
        font-size: 36px;
        line-height: 36px;
      `;
      
      return `
        font-size: 44px;
        line-height: 40px;
      `;
    }

    if (screen?.size === 'mob' && screen.touch) return `
      font-size: 36px;
      line-height: 40px;
    `;

    if (screen?.size === 'tab') return `
      font-size: 40px;
      line-height: 40px;
    `;

    return `
      font-size: 48px;
      line-height: 40px;
    `;
  }}
`;
