import {FC} from "react";
import styled from "styled-components";
import {Avatar} from "../Avatar/Avatar";
import {DropButton} from "../Button/DropButton";
import {ProfileProgress} from "./ProfileProgress";
import {ProfileRecords} from "./ProfileRecords";
import {VerifyButton} from "./VerifyButton";

export const ProfileWidget: FC = () => {
  return (
    <Wrapper>
      <Avatar image={ 'avatar.png' } glyph={ 'BetBit' } />
      <Name>
        John Smith <i className="icon-edit" />
      </Name>
      <ProfileProgress />
      <DropButton
        icon="icon-chevron-sm-right"
        url="/profile"
        isLink
        indicator={true} >Profile</DropButton>
      <ProfileRecords />
      <VerifyButton />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 36px 0 0;
`

const Name = styled.a`
  font-family: ${({theme}) => theme.fonts.header.family};
  font-weight: ${({theme}) => theme.fonts.header.weight};
  font-size: 20px;
  line-height: 24px;
  cursor: pointer;
  color: ${({theme}) => theme.colors.secondary.dark.six};
  transition: color 0.04s ease-in-out;
  text-align: center;
  margin-top: 20px;
  padding: 0 16px;
  width: 100%;
  
  
  i {
    font-size: 16px;
    line-height: 24px;
    margin-left: 2px;
    &:hover {
      color: ${({theme}) => theme.colors.accent.dark.hover};
    }
  }
`
