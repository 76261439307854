import {FC} from "react";
import styled from "styled-components";
import {NavLink} from "react-router-dom";

export const Chip: FC<{ text: string, link: string }> = ({ text, link }) => {
  return (
    <Wrapper to={link} >
      {text}
    </Wrapper>
  );
};

const Wrapper = styled(NavLink)`
  font-family: ${({theme}) => theme.fonts.bold.family};
  font-weight: ${({theme}) => theme.fonts.bold.weight};
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  height: 40px;
  padding: 0 15px;
  border-radius: 36px;
  
  border: 1px solid ${({theme}) => theme.colors.secondary.dark.two};
  
  background-color: ${({theme}) => theme.colors.secondary.dark.two};
  color: ${({theme}) => theme.colors.secondary.dark.five};
  
  &:hover {
    border: 1px solid ${({theme}) => theme.colors.secondary.dark.three};
    background-color: ${({theme}) => theme.colors.secondary.dark.three};
    color: ${({theme}) => theme.colors.secondary.dark.six};
  }
  
  &.active {
    border: 1px solid ${({theme}) => theme.colors.accent.dark.default};
    background-color: unset;
    color: ${({theme}) => theme.colors.secondary.dark.six};
  }
`;
