import {FC, useContext, useState} from "react";
import styled from "styled-components";
import {TBlogger} from "../../types/TBlogger";
import {Blogger} from "../../components/Card/Blogger";
import {ThemeContext} from "../../ThemeSetup";
import {useKeenSlider} from "keen-slider/react";

export const Bloggers: FC<{ data: Array<TBlogger> }> = ({ data }) => {
  const {screen} = useContext(ThemeContext);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);

  const [sliderRef, instanceRef] = useKeenSlider({initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
    created() {
      setLoaded(true)
    },
    loop: false,
    mode: screen?.touch ? "free" :"snap",
    rtl: false,
    slides: {
      spacing: 16,
      perView: "auto",
    },
  });

  return (
    <Wrapper>
      <Header>
        <HeaderControls>
          <Title>Highlights</Title>
          {loaded && (screen?.size !== 'mob' || !screen?.touch) && <Navs>
            <ArrowButton
              onClick={(e: any) =>
                e.stopPropagation() || instanceRef.current?.prev()
              }
              disabled={currentSlide === 0} ><i className="icon-chevron-sm-left" /></ArrowButton>
            <ArrowButton
              onClick={(e: any) =>
                e.stopPropagation() || instanceRef.current?.next()
              }
              disabled={instanceRef.current?.track.details.maxIdx === instanceRef.current?.track.details.abs} ><i className="icon-chevron-sm-right" /></ArrowButton>
          </Navs>}
        </HeaderControls>
      </Header>

      <Body>
        {screen?.size !== 'mob' && <Left className="arrow">
          {loaded && <ArrowButtonRounded
            onClick={(e: any) =>
              e.stopPropagation() || instanceRef.current?.prev()
            }
            disabled={currentSlide === 0} >
            <i className="icon-chevron-sm-left" />
          </ArrowButtonRounded>}
        </Left>}
        <SliderWrapper>
          <Slider ref={sliderRef} className="keen-slider">
            {data.map((item, index) => <SliderItem key={index} className="keen-slider__slide">
              <Blogger {...item} />
            </SliderItem>)}
          </Slider>
        </SliderWrapper>
        {screen?.size !== 'mob' && <Right className="arrow">
          {loaded && <ArrowButtonRounded
            onClick={(e: any) =>
              e.stopPropagation() || instanceRef.current?.next()
            }
            disabled={instanceRef.current?.track.details.maxIdx === instanceRef.current?.track.details.abs} >
            <i className="icon-chevron-sm-right" />
          </ArrowButtonRounded>}
        </Right>}
      </Body>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  overflow: hidden;
  ${({ theme }) => {
    if (theme.screen?.size === 'mob') return `
      padding: 20px 0;
    `;

    return `
      padding: 28px 0;
    `;
  }}
`;

const Slider = styled.div`
  overflow: unset!important;
`;

const SliderItem = styled.div`
  overflow: unset!important;
  ${({ theme }) => {
    if (theme.screen?.size === 'mob' && theme.screen.touch) return `
      min-width: 136px;
      max-width: 136px;
    `;
    
    return `
      min-width: 168px;
      max-width: 168px;
    `;
  }}
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ theme }) => {
    const { screen } = theme;
    if (screen?.breakpoint === 'desc_sm' || screen?.size === 'tab') return `
      padding: 0 32px 28px;
    `;

    if (screen?.size === 'mob' && theme.screen.touch) return `
      padding: 0 16px 16px;
    `;

    if (screen?.size === 'mob' && !theme.screen.touch) return `
      padding: 0 16px 24px;
    `;

    return `
      padding: 0 40px 28px;
    `;
  }}
`;

const HeaderControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h2`
  font-family: ${({theme}) => theme.fonts.bold.family};
  font-weight: ${({theme}) => theme.fonts.bold.weight};
  letter-spacing: 0.12em;
  text-transform: uppercase;

  ${({ theme }) => {
    if (theme.screen?.size === 'mob' && theme.screen.touch) return `
      font-size: 18px;
      line-height: 22px;
    `;

    return `
      font-size: 20px;
      line-height: 24px;
    `;
  }}
`;

const Navs = styled.div`
  display: flex;
  max-height: 24px;
  align-items: center;
  margin-left: 12px;
  >* {
    &:first-child {
      margin-right: 8px;
    }
  }
`;

const ArrowButton = styled.button`
  cursor: pointer;
  border: 0 none;
  font-size: 16px;
  line-height: 22px;
  width: 28px;
  height: 28px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({theme}) => theme.colors.secondary.dark.four};
  color: ${({theme}) => theme.colors.secondary.dark.six};
    
  &:hover {
    background-color: ${({theme}) => theme.colors.secondary.dark.five};
  }
  
  &:active {
    background-color: ${({theme}) => theme.colors.secondary.dark.four};
  }
  
  &:disabled {
    background-color: ${({theme}) => theme.colors.secondary.dark.two};
    color: ${({theme}) => theme.colors.secondary.dark.four};
  }
`;

const Left = styled.div`
  position: relative;
  z-index: 100;
  width: 40px;
  display: flex;
  align-items: center;
  pointer-events: none;

  ${({ theme }) => {
    const { screen } = theme;
    if (screen?.breakpoint === 'desc_sm' || screen?.size === 'tab') return `
      width: 32px;
      >button {
        transform: translateX(12px);
        pointer-events: all;
      }
    `;

    return `
      width: 40px;
      >button {
        transform: translateX(20px);
        pointer-events: all;
      }
    `;
  }}
`;

const Body = styled.div`
  display: flex;
  
  .arrow {
    opacity: 0;
    transition: all 0.12s ease;
  }
  
  &:hover {
    .arrow {
      opacity: 1;
    }
  }

  ${({ theme }) => {
    if (theme.screen?.size === 'mob') return `
      padding: 0 16px;
    `;
    return ``;
  }}
`;

const Right = styled.div`
  position: relative;
  z-index: 100;
  width: 40px;
  display: flex;
  align-items: center;
  pointer-events: none;

  ${({ theme }) => {
    const { screen } = theme;
    if (screen?.breakpoint === 'desc_sm' || screen?.size === 'tab') return `
      width: 32px;
      >button {
        transform: translateX(-12px);
        pointer-events: all;
      }
    `;

    return `
      width: 40px;
      >button {
        transform: translateX(-20px);
        pointer-events: all;
      }
    `;
  }}
`;

const SliderWrapper = styled.div`
  position: relative;
  z-index: 1;
  min-width: 100%;

  ${({ theme }) => {
    const { screen } = theme;
    if (screen?.breakpoint === 'desc_sm' || screen?.size === 'tab') return `
      margin: 0 -32px;
      padding: 0 32px;
    `;

    return `
      margin: 0 -40px;
      padding: 0 40px;
    `;
  }}
`;

const ArrowButtonRounded = styled.button`
  border: 0 none;
  cursor: pointer;
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  border-radius: 50%;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({theme}) => theme.colors.secondary.dark.six};
  color: ${({theme}) => theme.colors.secondary.dark.one};
  transition: all 0.12s ease;
  
  &:hover {
    background-color: ${({theme}) => theme.colors.secondary.dark.five};
  }
  
  &:active {
    background-color: ${({theme}) => theme.colors.secondary.dark.six};
  }
  
  &:disabled {
    pointer-events: none;
    opacity: 0;
  }
`;
