import {FC, useContext, useEffect, useState} from "react";
import styled from "styled-components";
import {GamesContext} from "../providers/GamesProvider";
import {TGame} from "../types/TGame";
import {RightBarGameImage} from "../components/Image/RightBarGameImage";
import {GameDescription} from "../components/Card/GameDescription";
import {Divider} from "../components/Divider/Divider";
import {GameTournament} from "../components/Card/GameTournament";

export const RightGame: FC<{ type?: 'default' | 'tablet' }> = ({ type }) => {
  const { openedGame, onCloseGame } = useContext(GamesContext);
  const [state, setState] = useState<{
    game: null | TGame;
  }>({
    game: null,
  });

  useEffect(() => {
    if (!!openedGame) {
      setState(prev => ({
        ...prev,
        game: { ...openedGame }
      }));
    }
  }, [openedGame]);

  const { game } = state;

  return (
    <Wrapper>
      { game?.image && game?.title &&
        <RightBarGameImage
          type={type}
          tournament={{
            title: 'Hot Sands',
            url: '/tournament',
          }}
          jackpot={{
            value: '1000',
            currency: 'EUR',
          }}
          onClose={() => onCloseGame && onCloseGame()}
          src={game?.image}
          alt={game?.title} />
      }

      <GameDescription />

      <Divider />

      <GameTournament />

    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-bottom: 24px;
`;
