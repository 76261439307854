import {FC} from "react";
import styled from "styled-components";


export const ProfileProgress: FC = () => {
  return (
    <Wrapper>
      <PreHeader>
        <span className="label">Your VIP Progress</span> 66%
      </PreHeader>
      <Bar>
        <Indicator $percent={66} />
      </Bar>
      <Info>
        <span>None</span> Bronze
      </Info>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 18px 16px 16px;
  width: 100%;
`

const Info = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-family: ${({theme}) => theme.fonts.bold.family};
  font-weight: ${({theme}) => theme.fonts.bold.weight};
  letter-spacing: 0.04em;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 16px;
  color: ${({theme}) => theme.colors.accent.yellow.default};
  padding-top: 8px;
`

const Bar = styled.div`
  margin-top: 7px;
  width: 100%;
  background: ${({theme}) => theme.colors.secondary.dark.three};
  border-radius: 8px;
  overflow: hidden;
`

const Indicator = styled.div<{ $percent: number }>`
  width: ${({$percent}) => $percent}%;
  height: 8px;
  border-radius: 8px;
  background: ${({theme}) => theme.colors.accent.gradient.default};
`

const PreHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 12px;
  line-height: 16px;
  color: ${({theme}) => theme.colors.accent.yellow.default};
  
  .label {
    color: ${({theme}) => theme.colors.secondary.dark.five};
  }
`