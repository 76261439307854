import {FC} from "react";
import styled from "styled-components";
import {TNav} from "../../types/TNav";
import {NavLink} from "react-router-dom";

export const NavMainIcons: FC<{ data: Array<TNav> }> = ({ data }) => {
  return (
    <Wrapper>
      {data.map((item, index) => <Item key={index}>
        <StyledLink to={item.link}>
          <Icon className={item.icon} />
          {item.text}
        </StyledLink>
      </Item>)}
    </Wrapper>
  );
};

const Wrapper = styled.ul`
  list-style: none;
  display: flex;
`;

const Item = styled.li`
  width: 100%;
`;

const Icon = styled.i`
  font-size: 24px;
  line-height: 28px;
  padding-bottom: 8px;
  color: ${({ theme }) => theme.colors.accent.dark.default};
`;

const StyledLink = styled(NavLink)`
  white-space: nowrap;
  font-family: ${({ theme }) => theme.fonts.bold.family};
  font-weight: ${({ theme }) => theme.fonts.bold.weight};
  color: ${({ theme }) => theme.colors.secondary.dark.six};
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.13em;
  text-transform: uppercase;
  text-decoration: none;
  min-height: 88px;
  max-height: 88px;
  padding: 2px 12px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  &:hover {
    color: ${({ theme }) => theme.colors.accent.dark.default};
  }
  
  &.active {
    color: ${({ theme }) => theme.colors.accent.dark.default};
  }
`;
