import React, {FC, Fragment, ReactNode, useContext, useEffect, useLayoutEffect, useRef, useState} from "react";
import styled, {createGlobalStyle} from "styled-components";
import {ThemeContext} from "../ThemeSetup";
import {Navbar} from "../components/Navbar/Navbar";
import {Burger} from "../components/Burger/Burger";
// import {RightBar} from "../components/RightBar/RightBar";
import StickyBox, {useStickyBox} from "react-sticky-box";
import {Footer} from "../blocks/Info/Footer";
import {Routes, Route, useLocation, NavLink, Outlet, useOutletContext, useParams} from "react-router-dom";
import {NavbarContext} from "../providers/Nabvar";
import {BurgerScroll} from "../components/Scroll/BurgerScroll";
import {NavbarMobile} from "../components/Navbar/NavbarMobile";
// import {BottomSheet} from "../components/BottomSheet/BottomSheet";
import {MainPage} from "../pages/MainPage";
import {Modal} from "./Modal";
import {SignUp} from "../launchers/SignUp";
import {ModalContext} from "../providers/ModalProvider";
import {Right} from "./Right";
import {RightDefault} from "../right/RightDefault";
import {RightSwipe} from "../right/RightSwipe";
import {BottomSheetRef, BottomSheet} from "react-spring-bottom-sheet";


import 'react-spring-bottom-sheet/dist/style.css'
import {GameLauncher} from "../launchers/GameLauncher";


export const MainLayout: FC = () => {
  const { isModal, onModalClose } = useContext(ModalContext);
  const { screen, resolutions } = useContext(ThemeContext);
  const { onBurger, isBurger, onBgOpacity } = useContext(NavbarContext);

  useEffect(() => {
    if (isBurger && onBurger) onBurger();
    console.log('screen', screen)
  }, [screen]);

  // const ref = useRef(null);
  // useEffect(() => {
  //   new ResizeObserver((entries) => {
  //     entries.forEach((entry) => {
  //       document.documentElement.style.setProperty("--webkit-footer-gap", `${entry.contentRect.height}px`);
  //     });
  //     // @ts-ignore
  //   }).observe(ref.current);
  // }, [screen]);


  // const location = useLocation();
  // useLayoutEffect(() => {
  //   document.documentElement.scrollTo(0, 0);
  //   if (isBurger && onBurger) onBurger();
  //   if (onBgOpacity) onBgOpacity(60);
  // }, [location.pathname]);

  const leftBar = useStickyBox({
    offsetTop: 0,
    offsetBottom: 0,
    bottom: false,
  });

  const rightBar = useStickyBox({
    offsetTop: 0,
    offsetBottom: 0,
    bottom: false,
  });

  const content = useStickyBox({
    offsetTop: 0,
    offsetBottom: 0,
    bottom: false,
  });

  const mobBurgerRef = useRef(null);
  useEffect(() => {
    isModal && onModalClose && onModalClose();

    // @ts-ignore
    if (mobBurgerRef && mobBurgerRef.current) {
      const t = setTimeout(() => {
        // @ts-ignore
        mobBurgerRef.current.scroll({
          top: 0,
          behavior: "smooth"
        });

        clearTimeout(t);
      }, 200);
    }
  }, [isBurger]);


  const [open, setOpen] = useState(false);
  function onDismiss() {
    setOpen(false)
  }

  const [expandOnContentDrag, setExpandOnContentDrag] = useState(true)
  // const focusRef = useRef<HTMLButtonElement>(null)
  const sheetRef = useRef<BottomSheetRef>(null);

  const handleOverlay = () => {
    isBurger && onBurger && onBurger()
  }

  const [swipeRight, setSwipeRight] = useState(false);

  useEffect(() => {
    if (screen?.size !== 'mob' || screen?.breakpoint !== 'tab_sm') {
      setSwipeRight(false);
    }
  }, [screen]);

  return (
    <Fragment>

      <Wrapper>
        <Global $isLocked={!!isBurger || !!isModal || open || swipeRight} />
        { ((screen?.size === 'mob' && !screen.touch) || screen?.size === 'tab') &&
          <Overlay $isOpen={!!isBurger} onClick={handleOverlay} />
        }

        <NavbarBox $isBurger={!!isBurger}>
          <NavbarWrapper>
            {(screen?.size === 'mob' && screen?.touch)
              ? <NavbarMobile
                onBurger={() => onBurger && onBurger()}
                isBurger={!!isBurger} />
              : <Navbar
                onBurger={() => onBurger && onBurger()}
                isBurger={!!isBurger} />
            }
          </NavbarWrapper>
        </NavbarBox>

        { ((screen?.size === 'mob' && !screen.touch) || screen?.size === 'tab') &&
          <BurgerDesktop $isBurger={!!isBurger}>
            <BurgerScroll>
              <Burger />
            </BurgerScroll>
          </BurgerDesktop>
        }

        { (screen?.size === 'mob' && screen.touch) &&
          <BurgerMobile $isBurger={!!isBurger}>
            <BurgerMobileWrapper ref={mobBurgerRef}>
              <Burger />
            </BurgerMobileWrapper>
          </BurgerMobile>
        }

        { (screen?.size === 'mob' || screen?.breakpoint === 'tab_sm') &&
          <RightSwipe onRightSwipe={flag => setSwipeRight(flag)} />
        }

        <MainContainer
          $swipeRight={swipeRight}
          $isBurger={!!isBurger}>
          { screen?.size === 'desc' &&
            <LeftBar ref={leftBar}>
              <Burger />
            </LeftBar>
          }

          <Content ref={content}>
            <div>
              <Routes>
                <Route path="/" element={ <MainPage/> } />
                <Route path="/slots-online" element={ <MainPage/> }>
                  <Route index element={ <MainPage/> } />
                  <Route path="*" element={ <MainPage/> } />
                </Route>
                <Route path="*" element={ <MainPage/> } />
              </Routes>
            </div>

            <Footer />
          </Content>

          { (screen?.size === 'desc' || (screen?.size === 'tab' && screen?.breakpoint !== 'tab_sm')) &&
            <RightBar ref={rightBar}>
              <Right>
                <RightDefault />
              </Right>
            </RightBar>
          }
        </MainContainer>

        {/*<BottomSheet />*/}

        <Routes>
          <Route path="/" element={ null } />
          <Route path="slots-online" element={ null }>
            <Route index element={ null } />
            <Route path=":gameId" element={ <GameLauncher /> } />
            <Route path="sign-up" element={ <SignUp /> } />
          </Route>
          <Route path="sign-up" element={ <SignUp /> } />
        </Routes>
      </Wrapper>
      <Modal />

      {/*<BottomSheet*/}
      {/*  className="tbs"*/}
      {/*  open={open}*/}
      {/*  // skipInitialTransition*/}
      {/*  // sibling={<CloseExample className="z-10" />}*/}
      {/*  ref={sheetRef}*/}
      {/*  // initialFocusRef={focusRef}*/}
      {/*  // defaultSnap={({ maxHeight }) => maxHeight / 2}*/}
      {/*  onDismiss={onDismiss}*/}
      {/*  snapPoints={({ maxHeight }) => [*/}
      {/*    maxHeight - maxHeight / 10,*/}
      {/*    maxHeight / 4,*/}
      {/*    maxHeight * 0.6,*/}
      {/*  ]}*/}
      {/*  // expandOnContentDrag={expandOnContentDrag}*/}
      {/*  header={*/}
      {/*    <h1 className="flex items-center text-xl justify-center font-bold text-gray-800">*/}
      {/*      Header*/}
      {/*    </h1>*/}
      {/*  }*/}
      {/*  footer={*/}
      {/*    <button onClick={onDismiss} className="w-full">*/}
      {/*      Done*/}
      {/*    </button>*/}
      {/*  }*/}
      {/*>*/}
      {/*  Bottom <br/> Bottom <br/>Bottom <br/>Bottom <br/>Bottom <br/>Bottom <br/>Bottom <br/>*/}
      {/*  Bottom <br/> Bottom <br/>Bottom <br/>Bottom <br/>Bottom <br/>Bottom <br/>Bottom <br/>*/}
      {/*  Bottom <br/> Bottom <br/>Bottom <br/>Bottom <br/>Bottom <br/>Bottom <br/>Bottom <br/>*/}
      {/*  <TestWrap>*/}
      {/*    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>*/}
      {/*    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>*/}
      {/*    Bottom <br/> Bottom <br/>Bottom <br/>Bottom <br/>Bottom <br/>Bottom <br/>Bottom <br/>*/}
      {/*    Bottom <br/> Bottom <br/>Bottom <br/>Bottom <br/>Bottom <br/>Bottom <br/>Bottom <br/>*/}
      {/*    Bottom <br/> Bottom <br/>Bottom <br/>Bottom <br/>Bottom <br/>Bottom <br/>Bottom <br/>*/}
      {/*    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>*/}
      {/*    Bottom <br/> Bottom <br/>Bottom <br/>Bottom <br/>Bottom <br/>Bottom <br/>Bottom <br/>*/}
      {/*    Bottom <br/> Bottom <br/>Bottom <br/>Bottom <br/>Bottom <br/>Bottom <br/>Bottom <br/>*/}
      {/*    Bottom <br/> Bottom <br/>Bottom <br/>Bottom <br/>Bottom <br/>Bottom <br/>Bottom <br/>*/}
      {/*    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>*/}
      {/*    Bottom <br/> Bottom <br/>Bottom <br/>Bottom <br/>Bottom <br/>Bottom <br/>Bottom <br/>*/}
      {/*    Bottom <br/> Bottom <br/>Bottom <br/>Bottom <br/>Bottom <br/>Bottom <br/>Bottom <br/>*/}
      {/*    Bottom <br/> Bottom <br/>Bottom <br/>Bottom <br/>Bottom <br/>Bottom <br/>Bottom <br/>*/}
      {/*    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>*/}
      {/*    Bottom <br/> Bottom <br/>Bottom <br/>Bottom <br/>Bottom <br/>Bottom <br/>Bottom <br/>*/}
      {/*    Bottom <br/> Bottom <br/>Bottom <br/>Bottom <br/>Bottom <br/>Bottom <br/>Bottom <br/>*/}
      {/*    Bottom <br/> Bottom <br/>Bottom <br/>Bottom <br/>Bottom <br/>Bottom <br/>Bottom <br/>*/}
      {/*  </TestWrap>*/}
      {/*</BottomSheet>*/}
    </Fragment>
  );
};


const TestWrap = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100dvh;
  overflow: hidden;
`;

const BurgerMobileWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  min-height: 100dvh;
  overflow: auto;
`;


// overflow: hidden;
const BurgerMobile = styled.div<{$isBurger: boolean}>`
  position: fixed;
  z-index: 1500;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  background-color: ${({theme}) => theme.colors.secondary.dark.one};
  overflow: clip;
  transition: left 0.2s ease-in-out;
  
  ${({theme, $isBurger}) => {
    const {breakpoint, size} = theme.screen;
    const {leftBar} = theme.resolutions[breakpoint];
    if ($isBurger) return `
      left: 0;
    `;
    
    return `
      left: -100%;
    `;
  }}
`;

const BurgerDesktop = styled.div<{$isBurger: boolean}>`
  position: fixed;
  z-index: 2000;
  top: 0;
  height: 100dvh;
  background-color: ${({theme}) => theme.colors.secondary.dark.one};
  border-right: 1px solid ${({theme}) => theme.colors.secondary.dark.three};
  transition: left 0.08s ease-in-out;
  
  ${({theme, $isBurger}) => {
    const {breakpoint, size} = theme.screen;
    const {leftBar} = theme.resolutions[breakpoint];
    if ($isBurger) return `
      left: 0;
    `;
    return `
      left: -${leftBar + 1}px;
    `;
  }}
  
  ${({theme}) => {
    const {breakpoint} = theme.screen;
    const {leftBar} = theme.resolutions[breakpoint];
    return `
      min-width: ${leftBar}px;
      max-width: ${leftBar}px;
    `;
  }}
`;

const Wrapper = styled.div`
  max-width: 100%;
  min-width: 100%;
  min-height: 100dvh;

  ${({theme}) => {
    const {size, breakpoint, touch} = theme.screen;
    const {leftBar, rightBar} = theme.resolutions[breakpoint];
    
    // if ((size === 'mob' || breakpoint === 'tab_sm') && touch) return `
    //   overflow: hidden;
    //   overflow: clip;
    // `;
    
    if ((size === 'mob' || breakpoint === 'tab_sm') && touch) return `
      overflow: clip;
    `;
    
    return `
      
    `;
  }}
`;

const NavbarBox = styled.div<{$isBurger: boolean}>`
  position: fixed;
  z-index: 2000;
  top: 0;
  max-width: 100%;
  min-width: 100%;
  pointer-events: none;
  transition: left 0.08s ease-in-out;
  
  ${({theme, $isBurger}) => {
    const {breakpoint, size, touch} = theme.screen;
    const {leftBar} = theme.resolutions[breakpoint];

    if ($isBurger && (!touch || (touch && size !== 'mob'))) return `
      left: ${leftBar}px;
    `;
    
    return `
      left: 0;
    `;
  }}
`;

const NavbarWrapper = styled.div`
  width: 100%;
  max-width: 1628px;
  margin: auto;
  
  >div {
    pointer-events: auto;
  }

  ${({theme}) => {
    const {breakpoint, size} = theme.screen;
    const {leftBar} = theme.resolutions[breakpoint];

    if (size === 'desc') return `
      padding-left: ${leftBar}px;
    `;

    return `
    
    `;
  }}
`;

const MainContainer = styled.div<{ $isBurger: boolean, $swipeRight: boolean }>`
  position: relative;
  width: 100%;
  max-width: 1628px;
  margin: auto;
  min-height: 100dvh;
  display: flex;
  align-items: flex-start;
  transition: left 0.2s ease-in-out, right 0.2s ease-in-out;

  ${({theme, $isBurger}) => {
    const {size, breakpoint, touch} = theme.screen;
    const {leftBar, rightBar} = theme.resolutions[breakpoint];
    if (size === 'mob' && touch) {
      if ($isBurger) return `
        left: 50%;
      `;
      
      return `
        left: 0;
      `;
    }
    
    if ((size === 'mob' && !touch) || size === 'tab') {
      if ($isBurger) return `
        left: ${leftBar}px;
      `;
      
      return `
        left: 0;
      `;
    }
    
    return `
    
    `;
  }}

  ${({theme, $swipeRight}) => {
    const {size, breakpoint, touch} = theme.screen;
    const {leftBar, rightBar} = theme.resolutions[breakpoint];

    if (breakpoint === 'tab_sm' || (size === 'mob' && !touch)) {
      if ($swipeRight) return `
        left: -200px;
      `;
    }

    return `
    `;
  }}
`;

const Content = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${({theme}) => {
    const {size, touch} = theme.screen;
    if (size === 'mob' && touch) return `
      padding-top: 0;
    `;
    
    return `
      padding-top: 64px;
    `;
  }}

  ${({theme}) => {
    const {breakpoint} = theme.screen;
    const {rightBar} = theme.resolutions[breakpoint];
    if (!rightBar) return ``;
    return `
      border-right: 1px solid ${theme.colors.secondary.dark.three};
    `;
  }}
  
  ${({theme}) => {
    const {breakpoint, size} = theme.screen;
    const {leftBar, rightBar} = theme.resolutions[breakpoint];
  
    if (size === 'desc') return `
      min-width: calc(100% - ${leftBar + rightBar}px);
      max-width: calc(100% - ${leftBar + rightBar}px);
    `;
  
    return `
      min-width: calc(100% - ${rightBar}px);
      max-width: calc(100% - ${rightBar}px);
    `;
  }}
`;

const LeftBar = styled.div`
  border-right: 1px solid ${({theme}) => theme.colors.secondary.dark.three};
  min-height: 100dvh;
  background-color: ${({theme}) => theme.colors.secondary.dark.one};

  ${({theme}) => {
    const {breakpoint} = theme.screen;
    const {leftBar} = theme.resolutions[breakpoint];
    return `
      min-width: ${leftBar}px;
      max-width: ${leftBar}px;
    `;
  }}
`;

const RightBar = styled.div`
  min-height: 100dvh;
  background-color: ${({theme}) => theme.colors.secondary.dark.one};

  ${({theme}) => {
    const {size, touch} = theme.screen;
    if (size === 'mob' && touch) return `
      padding-top: 60px;
    `;
    return `
      padding-top: 64px;
    `;
  }}

  ${({theme}) => {
    const {breakpoint} = theme.screen;
    const {rightBar} = theme.resolutions[breakpoint];

    return `
      min-width: ${rightBar}px;
      max-width: ${rightBar}px;
    `;
  }}
`;

const Overlay = styled.div<{ $isOpen: boolean }>`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(22, 25, 25, 0.6);
  backdrop-filter: blur(8px);
  transition: opacity 0.12s ease-in-out;

  ${({ $isOpen, theme }) => {
    if ($isOpen) return `
      opacity: 1;
    `;
  
    return `
      opacity: 0;
      pointer-events: none;
    `;
  }}
`;

const Global = createGlobalStyle<{ $isLocked: boolean }>`

  [aria-hidden="true"] {
    //position: fixed;
    //top: 0;
    //bottom: 0;
    //left: 0;
    //right: 0;
  }
  
  *{
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
  }

  body {
    font-family: 'Open Sans', sans-serif;
    background-color: ${({theme}) => theme.colors.background.dark};
    color: ${({theme}) => theme.colors.secondary.dark.six};
    max-width: 100svw;
    min-width: 100svw;
    position: relative;
  }

  html {
    max-width: 100%;
    min-width: 100%;
    height: 100dvh;
  }

  a {
    color: ${({theme}) => theme.colors.accent.dark.default};
    &:hover {
      color: ${({theme}) => theme.colors.accent.dark.hover};
    }
  }

  ${({theme}) => {
    const {breakpoint, size} = theme.screen;
    if (size === 'desc') return ``;

    return `
      body {
        background-color: ${theme.colors.background.page};
      }
    `;
  }}

  ${({$isLocked}) => {
    // if ($isLocked) return `
    //   html, body {
    //     overflow: hidden;
    //     position: relative;
    //   }
    // `;
    if ($isLocked) return `
      html, body {
        overflow: clip;
        position: relative;
      }
    `;
    return ``;
  }}

  :root {
    --rsbs-backdrop-bg: rgba(0, 0, 0, 0.6);
    --rsbs-bg: #fff;
    --rsbs-handle-bg: hsla(0, 0%, 0%, 0.14);
    --rsbs-max-w: auto;
    --rsbs-ml: env(safe-area-inset-left);
    --rsbs-mr: env(safe-area-inset-right);
    --rsbs-overlay-rounded: 16px;
  }

  .tbs {
    position: fixed;
    top: 0;
    left: 0;
    //bottom: 0;
    //right: 0;
    width: 100vh;
    height: 100vh;
    overflow: hidden;
    z-index: 3000;
  }
`;

// html, body {
//   touch-action: none;
//   -ms-touch-action: none;
//   overflow: hidden;
//
//   height: 100%;
//   width: 100%;
//   position: relative;
// }


/*



const Right = styled.div`
  position: relative;
  min-height: 100vh;

  ${({theme}) => {
    const {size, touch} = theme.screen;
    if (size === 'mob' && touch) return `
      padding-top: 60px;
    `;
    return `
      padding-top: 64px;
    `;
  }}

  ${({theme}) => {
    const {breakpoint} = theme.screen;
    const {rightBar} = theme.resolutions[breakpoint];

    return `
      min-width: ${rightBar}px;
      max-width: ${rightBar}px;
    `;
  }}
`;


const Test = () => {
  useEffect(() => {
    console.log('Open modal')
    return () => {
      console.log('Closed modal')
    }
  }, []);
  return null
}

const Overlay = styled.div<{ $isOpen: boolean }>`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(22, 25, 25, 0.6);
  backdrop-filter: blur(8px);
  transition: opacity 0.12s ease-in-out;

  ${({ $isOpen, theme }) => {
    if ($isOpen) return `
      opacity: 1;
    `;

    return `
      opacity: 0;
      pointer-events: none;
    `;
  }}
`;

const LeftSticky = styled.div<{ $isBurger: boolean }>`
  position: fixed;
  z-index: 1000;
  border-right: 1px solid ${({theme}) => theme.colors.secondary.dark.three};
  min-height: 100dvh;
  background-color: ${({theme}) => theme.colors.secondary.dark.one};
  transition: all 0.08s ease-in-out;

  ${({theme, $isBurger}) => {
    const {breakpoint} = theme.screen;
    const {leftBar} = theme.resolutions[breakpoint];
    if ($isBurger) return `
      left: 0;
    `;

    return `
      left: -${leftBar}px;
    `;
  }}

  ${({theme}) => {
    const {breakpoint} = theme.screen;
    const {leftBar} = theme.resolutions[breakpoint];
    return `
      min-width: ${leftBar}px;
      max-width: ${leftBar}px;
    `;
  }}
`;

const MobileBurgerWrapper = styled.div`
  min-width: 100%;
  height: 100%;
  overflow: auto;
  position: absolute;
  top: 0;
`;

const MobileBurger = styled.div<{ $isBurger: boolean }>`
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  flex-shrink: 0;
  width: 100vw;
  overflow: hidden;
  height: 100dvh;
  background-color: ${({theme}) => theme.colors.secondary.dark.one};

  ${({theme, $isBurger}) => {
  const {breakpoint} = theme.screen;
  const {leftBar} = theme.resolutions[breakpoint];

  if ($isBurger) return `
      left: 0;
      transition: all 0.12s ease-in-out;
    `;

  return `
      left: -100%;
      transition: all 0.12s ease-in-out;
    `;
}}
`;


const MainContent = styled.div<{$isBurger: boolean}>`
  min-height: 100vh;
  min-width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative !important;

  ${({theme}) => {
  const {size, touch} = theme.screen;
  if (size === 'mob' && touch) return ``;

  return `
      padding-top: 64px;
    `;
}}

  ${({theme, $isBurger}) => {
  const {breakpoint, size, touch} = theme.screen;
  const {leftBar} = theme.resolutions[breakpoint];

  if (!touch || size !== 'mob') {
    if ($isBurger) return `
        left: ${leftBar}px;
        transition: all 0.2s ease-in-out;
      `;

    return `
        left: 0;
        transition: all 0.2s ease-in-out;
      `;
  }

  if ($isBurger) return `
      left: 50%;
      transition: all 0.2s ease-in-out;
    `;

  return `
      left: 0;
      transition: all 0.2s ease-in-out;
    `;
}}
`;

const Container = styled.div`
  max-width: 100%;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: flex-start;


  ${({theme}) => {
  const {breakpoint, size} = theme.screen;
  const {leftBar, rightBar} = theme.resolutions[breakpoint];

  if (size === 'desc') return `
      min-width: calc(100% - ${leftBar + rightBar}px);
    `;

  return `
      min-width: calc(100% - ${rightBar}px);
    `;
}}
`;

const NavbarWrapper = styled.div<{$isBurger: boolean}>`
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  transition: all 0.08s ease-in-out;

  ${({theme, $isBurger}) => {
  const {breakpoint, size, touch} = theme.screen;
  const {leftBar} = theme.resolutions[breakpoint];

  if (!touch || size !== 'mob' && touch) {
    if ($isBurger) return `
        left: ${leftBar}px;
      `;

    return `
        left: 0;
      `;
  }

  if ($isBurger) return `
      left: 50%;
    `;

  return `
      left: 0;
    `;
}}

  ${({theme}) => {
    const {breakpoint, size} = theme.screen;
    const {leftBar} = theme.resolutions[breakpoint];
    if (size === 'desc') return `
        padding-left: ${leftBar}px;
      `;

    return ``;
  }}
`;

const Body = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${({theme}) => {
    const {breakpoint} = theme.screen;
    const {rightBar} = theme.resolutions[breakpoint];
    if (!rightBar) return ``;
    return `
      border-right: 1px solid ${theme.colors.secondary.dark.three};
    `;
  }}

  ${({theme}) => {
    const {size, touch} = theme.screen;
    if (size === 'mob' && touch) return ``;
    return `
      padding-top: 64px;
    `;
  }}

  ${({theme}) => {
    const {breakpoint, size} = theme.screen;
    const {leftBar, rightBar} = theme.resolutions[breakpoint];

    if (size === 'desc') return `
      min-width: calc(100% - ${leftBar + rightBar}px);
    `;
    
    return `
      min-width: calc(100% - ${rightBar}px);
    `;
  }}
`;

const Content = styled.div<{ $isBurger: boolean }>`
  display: flex;
  align-items: flex-start;
  
  ${({theme, $isBurger}) => {
    const {size, touch} = theme.screen;
    if ($isBurger && touch && size === 'mob') return `
      position: relative;
      left: 100%;
    `;
    
    return `
    `;
  }}
`;

const NavbarWrapper = styled.div`
  position: sticky;
  z-index: 1000;
  top: 0;
  height: 0;

  ${({theme}) => {
    const {breakpoint, size} = theme.screen;
    const {leftBar} = theme.resolutions[breakpoint];
    if (size === 'desc') return `
      padding-left: ${leftBar}px;
    `;

    return ``;
  }}
`;

const Container = styled.div<{ $isBurger: boolean }>`
  position: relative;
  width: 100%;
  max-width: 1628px;
  margin: auto;
  transition: left 0.12s ease-in-out;
  
  ${({theme, $isBurger}) => {
    const {breakpoint, size, touch} = theme.screen;
    const {leftBar} = theme.resolutions[breakpoint];
    if ($isBurger && (size !== 'mob' && touch || !touch)) return `
      left: ${leftBar}px;
    `;

    return `
      left: 0;
    `;
  }}
  
  ${({theme}) => {
    const {breakpoint} = theme.screen;
    if (breakpoint === 'desc_md' || breakpoint === 'desc_lg') return `
      background-color: ${theme.colors.background.page};
    `;

    return ``;
  }}
`;

 */
