import {FC, useContext, useState} from "react";
import styled from "styled-components";
import {TJackpotProvider} from "../../types/TJackpotProvider";
import {JackpotProvider} from "../../components/Card/JackpotProvider";
import {ThemeContext} from "../../ThemeSetup";
import {useKeenSlider} from "keen-slider/react";

export const ProvidersJackpot: FC<{data: Array<TJackpotProvider>}> = ({ data }) => {
  const {screen} = useContext(ThemeContext);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);

  const [sliderRef, instanceRef] = useKeenSlider({initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
    created() {
      setLoaded(true)
    },
    loop: false,
    mode: screen?.touch ? "free" :"snap",
    rtl: false,
    slides: {
      perView: "auto",
      spacing: 12,
    },
  });

  return (
    <Wrapper>
      {screen?.size !== 'mob' && <Left className="arrow">
        {loaded && <ArrowButtonRounded
            onClick={(e: any) =>
              e.stopPropagation() || instanceRef.current?.prev()
            }
            disabled={currentSlide === 0} >
            <i className="icon-chevron-sm-left" />
        </ArrowButtonRounded>}
      </Left>}
      <SliderWrapper>
        <Slider ref={sliderRef} className="keen-slider">
          {data.map((item, index) =>
            <SliderItem key={item.name} className="keen-slider__slide">
              <JackpotProvider {...item} />
            </SliderItem>
          )}
        </Slider>
      </SliderWrapper>
      {screen?.size !== 'mob' && <Right className="arrow">
        {loaded && <ArrowButtonRounded
            onClick={(e: any) =>
              e.stopPropagation() || instanceRef.current?.next()
            }
            disabled={instanceRef.current?.track.details.maxIdx === instanceRef.current?.track.details.abs} >
          <i className="icon-chevron-sm-right" />
        </ArrowButtonRounded>}
      </Right>}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  
  .arrow {
    opacity: 0;
    transition: all 0.12s ease;
  }
  
  &:hover {
    .arrow {
      opacity: 1;
    }
  }

  ${({ theme }) => {
    if (theme.screen?.size === 'mob') return `
      padding: 12px 16px 28px;
    `;
    return `
      padding: 20px 0 28px;
    `;
  }}
`;

const Slider = styled.div`
  overflow: unset!important;
`;

const SliderItem = styled.div<{ $size?: 'large' | 'default' | 'small' | 'verySmall' }>`
  overflow: unset;
  max-width: 160px;
  min-width: 160px;
`;


const Left = styled.div`
  position: relative;
  z-index: 100;
  display: flex;
  align-items: center;
  pointer-events: none;
  
  ${({ theme }) => {
    const { screen } = theme;
    if (screen?.breakpoint === 'desc_sm' || screen?.size === 'tab') return `
      width: 32px;
      >button {
        transform: translateX(12px);
        pointer-events: all;
      }
    `;

    return `
      width: 40px;
      >button {
        transform: translateX(20px);
        pointer-events: all;
      }
    `;
  }}
`;

const SliderWrapper = styled.div`
  position: relative;
  z-index: 1;
  min-width: 100%;

  ${({ theme }) => {
    const { screen } = theme;
    if (screen?.breakpoint === 'desc_sm' || screen?.size === 'tab') return `
      margin: 0 -32px;
      padding: 0 32px;
    `;

    return `
      margin: 0 -40px;
      padding: 0 40px;
    `;
  }}
`;

const Right = styled.div`
  position: relative;
  z-index: 100;
  display: flex;
  align-items: center;
  pointer-events: none;
  
  ${({ theme }) => {
    const { screen } = theme;
    if (screen?.breakpoint === 'desc_sm' || screen?.size === 'tab') return `
      width: 32px;
      >button {
        transform: translateX(-12px);
        pointer-events: all;
      }
    `;

    return `
      width: 40px;
      >button {
        transform: translateX(-20px);
        pointer-events: all;
      }
    `;
  }}
`;

const ArrowButtonRounded = styled.button`
  border: 0 none;
  cursor: pointer;
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  border-radius: 50%;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({theme}) => theme.colors.secondary.dark.six};
  color: ${({theme}) => theme.colors.secondary.dark.one};
  transition: all 0.12s ease;
  
  &:hover {
    background-color: ${({theme}) => theme.colors.secondary.dark.five};
  }
  
  &:active {
    background-color: ${({theme}) => theme.colors.secondary.dark.six};
  }
  
  &:disabled {
    pointer-events: none;
    opacity: 0;
  }
`;

