import {FC} from "react";
import styled from "styled-components";
import {TGamesCollection} from "../../types/TGamesCollection";
import {Image} from "../Image/Image";
import {Link} from "react-router-dom";
import {BadgeSmall} from "../Badge/BadgeSmall";


export const GamesCollection: FC<TGamesCollection> = ({ title, url, image, itemsCount, previewGames }) => {
  return (
    <Wrapper>
      <Title>{title}</Title>

      <Footer>
        <BadgeSmall>{itemsCount}</BadgeSmall>
        <Games>
          {previewGames.map((item, index) =>
            <GameLink key={index} to={item.url}>
              <Image src={item.image} alt={item.title}/>
            </GameLink>
          )}
        </Games>
      </Footer>

      <ImageWrapper className="image">
        <ImageMain src={image} alt={title} />
      </ImageWrapper>

      <MainLink to={url} />
    </Wrapper>
  );
};

const MainLink = styled(Link)`
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Footer = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
`;

const Games = styled.div`
  display: flex;
  margin-bottom: -4px;
  margin-left: -4px;
  padding-top: 8px;
`;

const GameLink = styled(Link)`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.colors.secondary.dark.three};
  border-radius: 50%;
  margin-right: -12px;
  position: relative;
  z-index: 200;
  transition: transform 0.12s ease;
  transform: scale(1);
  
  &:hover {
    z-index: 200;
    transform: scale(1.16);
  }
`;

const Title = styled.h3`
  position: relative;
  z-index: 100;
  font-family: ${({theme}) => theme.fonts.header.family};
  font-weight: ${({theme}) => theme.fonts.header.weight};
  font-size: 20px;
  line-height: 24px;
  width: 50px;
`;

const Wrapper = styled.div`
  position: relative;
  height: 200px;
  background-color: ${({theme}) => theme.colors.secondary.dark.three};
  border-radius: 16px;
  padding: 18px 20px 20px;
  
  .image {
    transition: all 0.2s ease;
  }
  
  &:hover {
    .image {
      top: -28px;
    }
  }
`;

const ImageWrapper = styled.div`
  position: absolute;
  top: -16px;
  left: 0;
  right: -24px;
  bottom: 0;
  pointer-events: none;
`;

const ImageMain = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
