import {FC, useContext, useEffect} from "react";
import styled from "styled-components";
import {ThemeContext} from "../../ThemeSetup";
import {Link} from "react-router-dom";
import {NavbarRightPanel} from "./NavbarRightPanel";

type TNabvar = {
  onBurger: () => void;
  isBurger: boolean;
}

export const Navbar: FC<TNabvar> = ({onBurger, isBurger}) => {
  const { screen } = useContext(ThemeContext);

  useEffect(() => {
    // console.log(screen?.size === 'desc')
  }, []);

  return (
    <Wrapper $isBurger={isBurger}>
      <Left>
        { screen?.size !== 'desc' &&
          <BurgerButton onClick={() => onBurger()}>
            <BurgerImage src="./burger_lg.svg" alt="" />
          </BurgerButton>
        }

        <Logo to="/">
          <LogoImage src="./logo.svg" alt={ 'Drip' } />
        </Logo>

        <Search>
          <i className="icon-search" />
          <SearchText>
            Game or provider
          </SearchText>
        </Search>
      </Left>
      <Right>
        <NavbarRightPanel />
      </Right>
    </Wrapper>
  );
};


const Search = styled.button`
  border: 0 none;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.secondary.dark.one};
  color: ${({ theme }) => theme.colors.secondary.dark.four};
  border-radius: 12px;
  display: flex;
  align-items: center;
  width: 260px;
  height: 40px;
  margin-left: 32px;
  padding: 0 20px;
  
  i {
    color: ${({ theme }) => theme.colors.secondary.dark.five};
  }
`;

const SearchText = styled.div`
  padding-left: 16px;
`;

const Wrapper = styled.div<{$isBurger: boolean}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  background: ${({ theme }) => theme.colors.secondary.dark.two};
`;

const BurgerButton = styled.button`
  height: 64px;
  border-radius: 0;
  border: 0;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 16px;
  
  ${({ theme }) => {
    if (theme.screen?.size === 'mob') return `
      width: 60px;
    `;
    
    return `
      width: 76px;
    `;
  }}
`;

const BurgerImage = styled.img`
  height: 32px;
`;

const LogoImage = styled.img`
  height: 32px;
`;

const Logo = styled(Link)`
  ${({ theme }) => {
    const { screen } = theme;
    if (screen.size === 'desc' && screen?.breakpoint !== 'desc_sm') {
      return `
        padding-left: 40px;
      `;
    }
    
    if (screen.size === 'desc' && screen?.breakpoint === 'desc_sm') {
      return `
        padding-left: 32px;
      `;
    }
  
    return ``;
  }}
  
  display: flex;
  align-items: center;
  height: 32px;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
`;

const Right = styled.div`
  
`;


