import {FC, useContext} from "react";
import styled from "styled-components";
import {TBanner} from "../../types/TBanner";
import {Badge} from "../Badge/Badge";
import {Button} from "../Button/Button";
import {CounterBox} from "../CounterBox/CounterBox";
import {Timer} from "../CounterBox/Timer";
import {ThemeContext} from "../../ThemeSetup";

export const CardBanner: FC<{ data: TBanner, size?: 'small' | 'default' }>  = ({data, size}) => {
  const { id, title, subtitle, badge, type, link, image, backgroundImage, prize, description, timerEnd, borderRadius, } = data;
  const {screen} = useContext(ThemeContext);
  let subtitleText = '';
  switch (type) {
    case 'tournament':
      subtitleText = subtitle ? subtitle : 'Tournament'; break;

    case 'lottery':
      subtitleText = subtitle ? subtitle : 'Lottery'; break;

    case 'bonus':
      subtitleText = subtitle ? subtitle : 'Bonus'; break;
  };

  return (
    <Wrapper $size={size}>
      <Content>
        <Header>
          <div>
            {subtitleText && <SubTitle>{subtitleText}</SubTitle>}
            <Title $size={size}>{title}</Title>
          </div>
          { badge &&
            <BadgeWrapper>
              {badge === 'strategySpin' && <Badge icon="icon-circle-info">
                {screen?.size === 'desc' && <span className="muted">strategy:</span>}
                Spin
              </Badge>}
              {badge === 'strategyBet' && <Badge icon="icon-circle-info">
                {screen?.size === 'desc' && <span className="muted">strategy:</span>}
                Bet
              </Badge>}
              {badge === 'strategyWin' && <Badge icon="icon-circle-info">
                {screen?.size === 'desc' && <span className="muted">strategy:</span>}
                Win
              </Badge>}
              {badge === 'hot' && <Badge>Hot</Badge>}
            </BadgeWrapper>
          }
        </Header>
        <Footer>
          { prize &&
            <CounterBox label="Prize pool" >
              30 000 $
            </CounterBox>
          }
          { timerEnd &&
            <CounterBox label="Prize pool" >
              <Timer time={timerEnd} />
            </CounterBox>
          }
          { description &&
            <Description>{description}</Description>
          }
          <Button
            size={(size === 'small' || screen?.size !== 'desc') ? 'small' : 'default'}
            url={link} >
            {type === 'bonus' && 'Get bonus'}
            {type === 'lottery' && 'Buy ticket'}
            {type === 'tournament' && 'Join'}
          </Button>
        </Footer>
      </Content>
      <MainImageWrapper>
        <MainImage className="main" src={image} alt={title} />
      </MainImageWrapper>
      <Background $borderRadius={borderRadius} >
        <BackgroundImage src={backgroundImage} alt={title} />
      </Background>
    </Wrapper>
  );
};


const Wrapper = styled.div<{ $size?: 'small' | 'default' }>`
  position: relative;
  
  .main {
    transform: scale(1);
    transition: transform 0.2s ease;
  }
  
  &:hover {
    .main {
      transform: scale(1.08);
    }
  }
  
  ${({theme, $size}) => {
    const {screen} = theme;
    if ($size === 'small' || screen.size !== 'desc') return `
      height: 280px;
    `;
    
    return `
      height: 328px;
    `;
  }}
`;

const MainImageWrapper = styled.div`
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const MainImage = styled.img`
  position: absolute;
  z-index: 150;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const Description = styled.div`
  font-family: ${({theme}) => theme.fonts.header.family};
  font-weight: ${({theme}) => theme.fonts.header.weight};
  font-size: 16px;
  line-height: 22px;
  padding-right: 16px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 24px 24px;
`;


const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Background = styled.div<{ $borderRadius?: boolean }>`
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  ${({$borderRadius}) => {
    if ($borderRadius) return `
      border-radius: 24px;
    `;

    return ``;
  }}
`;

const BackgroundImage = styled.img`
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;


const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 21px 24px 0;
`;

const SubTitle = styled.span`
  color: ${({theme}) => theme.colors.secondary.dark.six};
  font-family: ${({theme}) => theme.fonts.bold.family};
  font-weight: ${({theme}) => theme.fonts.bold.weight};
  opacity: 0.6;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
`;

const Title = styled.h3<{ $size?: 'small' | 'default' }>`
  color: ${({theme}) => theme.colors.secondary.dark.six};
  font-family: ${({theme}) => theme.fonts.header.family};
  font-weight: ${({theme}) => theme.fonts.header.weight};

  ${({theme, $size}) => {
    const {screen} = theme;
    if ($size === 'small' || screen.size !== 'desc') return `
      font-size: 28px;
      line-height: 33px;
      margin-top: 5px;
    `;

    return `
      font-size: 32px;
      line-height: 36px;
      margin-top: 5px;
    `;
  }}
`;

const BadgeWrapper = styled.div`
  padding-top: 3px;
`;