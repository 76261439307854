import {FC, Fragment, useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {TNav} from "../../types/TNav";
import {Link, NavLink} from "react-router-dom";
import {useKeenSlider} from "keen-slider/react";
import {CardBanner} from "../Card/CardBanner";

const data: Array<TNav> = [
  {text: 'Slots', link: '/', icon: 'icon-slot'},
  {text: 'Live Casino', link: '/slots-online', icon: 'icon-dealer'},
  {text: 'Table Games', link: '/table', icon: 'icon-categories'},
  {text: 'Bonus Buy', link: '/bonus-buy', icon: 'icon-gift'},
  {text: 'Instant Games', link: '/instant', icon: 'icon-analitic'},
  {text: 'Sport', link: '/sport', icon: 'icon-balls-bal'},
];

export const FixedNav: FC = () => {
  const [sliderRef] = useKeenSlider({
    initial: 0,
    loop: false,
    mode: "snap",
    rtl: false,
    slides: {
      spacing: 0,
      perView: "auto",
    },
  });

  const ref = useRef<HTMLDivElement>(null);

  const [state, setState] = useState({
    fixed: false,
  });

  const checkScroll = () => {
    const { scrollTop } = document.documentElement;
    const top = ref.current?.offsetTop || 0;
    const fixed = scrollTop > top - 64;
    setState(prev => ({ ...prev, fixed }));
  }

  useEffect(() => {
    if (ref.current) {
      checkScroll();
      window.addEventListener('scroll', checkScroll);
      return () => window.removeEventListener('scroll', checkScroll);
    }
  }, []);

  return (
    <Fragment>
      <Checker ref={ref} />
      <Wrapper $fixed={state.fixed}>
        <Container $fixed={state.fixed}>
          <List>
            <Slider ref={sliderRef} className="keen-slider" >
              {data.map((item, index) =>
                <SliderItem key={index} className="keen-slider__slide" >
                  <StyledLink $fixed={state.fixed} to={item.link}>
                    {item.icon && <i className={item.icon} />}
                    <span>{item.text}</span>
                  </StyledLink>
                </SliderItem>
              )}
            </Slider>
          </List>
        </Container>
      </Wrapper>
    </Fragment>
  );
};

const Checker = styled.div`
  height: 0;
`;

const Container = styled.div<{ $fixed: boolean }>`
  overflow: hidden;
  ${({ theme }) => {
    const { screen } = theme;
    if (screen?.breakpoint === 'desc_sm' || screen?.size === 'tab') return `
      padding: 0 32px;
    `;

    if (screen?.size === 'mob') return `
      padding: 0 16px;
    `;

    return `
      padding: 0 40px;
    `;
  }}

  ${({ $fixed, theme }) => {
    if ($fixed) return `
      background: rgba(22, 25, 25, 0.8);
      backdrop-filter: blur(4px);
    `;

    return `
      border-bottom: 1px solid ${theme.colors.secondary.dark.three};
    `;
  }}
`;

const Wrapper = styled.div<{ $fixed: boolean }>`
  width: 100%;
  position: sticky;
  top: 64px;
  height: 64px;
  z-index: 900;
`;

const SliderItem = styled.div`
  overflow: unset!important;
  display: block;
  width: auto!important;
`;

const Slider = styled.div`
  overflow: visible!important;
`;

const StyledLink = styled(NavLink)<{ $fixed: boolean }>`
  position: relative;
  font-family: ${({ theme }) => theme.fonts.bold.family};
  font-weight: ${({ theme }) => theme.fonts.bold.weight};
  color: ${({ theme }) => theme.colors.secondary.dark.five};
  list-style: none;
  display: block;
  align-items: center;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  text-decoration: none;
  padding: 20px 12px 22px;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
  
  i {
    line-height: 24px;
    margin-right: 8px;
  }
  
  &::before {
    content: '';
    height: 2px;
    background: ${({ theme }) => theme.colors.accent.gradient.default};
    position: absolute;
    bottom: 0;
    left: 12px;
    right: 12px;
    opacity: 0;
  }
  
  &.active {
    span {
      color: ${({ theme }) => theme.colors.secondary.dark.six};
    }

    &::before {
      opacity: 1;
    }
  }
  
  &:hover {
    span {
      color: ${({ theme }) => theme.colors.accent.dark.hover};
    }
  }

  ${({ $fixed }) => {
    if ($fixed) return `
      padding: 12px 12px 12px;
    `;

    return `
      padding: 20px 12px 22px;
    `;
  }}
`;

const List = styled.div`
  list-style: none;
  display: flex;
  margin: 0 -12px;
`;
