import {FC} from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";
import {Currency} from "../Currency/Currency";


export const ProfileRecords: FC = () => {
  return (
    <Wrapper>
      <Item>
        <StyledLink to="/max-wins">
          <Text>
            <Value className="value">
              <Currency value={4456.45} code="USD" />
            </Value>
            max win
          </Text>
          <Icon className="icon-chevron-sm-right" />
        </StyledLink>
      </Item>
      <Item>
        <StyledLink to="/max-wins">
          <Text>
            <Value className="value">
              9 996.6x
            </Value>
            max multiplier
          </Text>
          <Icon className="icon-chevron-sm-right" />
        </StyledLink>
      </Item>
    </Wrapper>
  )
}



const Wrapper = styled.ul`
  width: 100%;
  border-top: 1px solid ${({theme}) => theme.colors.secondary.dark.three};
  display: flex;
  list-style: none;
  margin-top: 20px;
`

const Item = styled.li`
  width: 100%;
  border-left: 1px solid ${({theme}) => theme.colors.secondary.dark.three};
  &:first-child {
    border-left: none;
  }
`

const StyledLink = styled(Link)`
  display: flex;
  width: 100%;
  text-decoration: none;
  color: ${({theme}) => theme.colors.secondary.dark.five};
  min-height: 72px;
  max-height: 72px;
  padding: 17px 16px 0;
  justify-content: space-between;
  
  &:hover {
    color: ${({theme}) => theme.colors.secondary.dark.five};
    .value {
      color: ${({theme}) => theme.colors.secondary.dark.five};
    }
  }
`

const Text = styled.div`
  font-size: 12px;
  line-height: 16px;
`

const Icon = styled.i`
  font-size: 20px;
`

const Value = styled.div`
  font-family: ${({theme}) => theme.fonts.bold.family};
  font-weight: ${({theme}) => theme.fonts.bold.weight};
  color: ${({theme}) => theme.colors.secondary.dark.six};
  text-transform: uppercase;
  font-size: 16px;
  line-height: 20px;
  padding-bottom: 4px;
`
