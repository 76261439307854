import {FC, Fragment, useContext, useEffect, useRef, useState} from "react";
import {GamesContext} from "../providers/GamesProvider";
import styled from "styled-components";
import {RightGame} from "./RightGame";

export const RightSwipe: FC<{
  onRightSwipe: (flag: boolean) => void
}> = ({ onRightSwipe }) => {
  const { openedGame, onCloseGame } = useContext(GamesContext);
  const [state, setState] = useState({
    openBurger: false,
    openGame: false,
  });

  const gameRef = useRef(null);


  useEffect(() => {
    if (!!openedGame) {
      setState(prev => ({
        ...prev, openGame: true, openBurger: true,
      }));
      onRightSwipe(true);

      if (gameRef && gameRef.current) {
        // @ts-ignore
        gameRef.current.scroll({
          top: 0,
        });
      }
    } else {
      setState(prev => ({
        ...prev, openGame: false, openBurger: false,
      }));
      onRightSwipe(false);

    }
  }, [openedGame]);

  const handleClose = () => {
    openedGame && onCloseGame && onCloseGame();
  }

  return (
    <Fragment>
      <Wrapper $openBurger={state.openBurger}>
        <Slide ref={gameRef}>
          <RightGame type="tablet" />
          {/*<RightBarScroll>*/}
          {/*</RightBarScroll>*/}
        </Slide>
      </Wrapper>
      <Overlay
        onClick={handleClose}
        $isOpen={state.openBurger} />
    </Fragment>
  );
};


const Overlay = styled.div<{ $isOpen: boolean }>`
  position: fixed;
  z-index: 2900;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(22, 25, 25, 0.6);
  backdrop-filter: blur(8px);
  transition: opacity 0.12s ease-in-out;

  ${({ $isOpen, theme }) => {
    if ($isOpen) return `
      opacity: 1;
    `;
  
    return `
      opacity: 0;
      pointer-events: none;
    `;
  }}
`;

const Wrapper = styled.div<{ $openBurger: boolean }>`
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100%;
  max-width: 400px;
  z-index: 3000;
  overflow: hidden;
  background-color: ${({theme}) => theme.colors.secondary.dark.one};
  border-left: 1px solid ${({theme}) => theme.colors.secondary.dark.three};

  transition: all 0.08s ease-in-out;
  
  
  ${({ theme, $openBurger }) =>  {
    const { screen, resolutions } = theme;
    if ($openBurger) return `
      right: 0px;
    `;
  
    return `
      right: -400px;
    `;
  }}
`;

const Slide = styled.div`
  height: 100%;
  width: 100%;
  overflow: scroll;
`;

