import {FC, ReactNode} from "react";
import styled from "styled-components";


export const BadgeSmall: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Wrapper>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.small`
  display: inline-flex;
  align-items: center;
  border-radius: 12px;
  font-size: 12px;
  line-height: 16px;
  min-height: 24px;
  max-height: 24px;
  color: ${({theme}) => theme.colors.secondary.dark.five};
  background-color: ${({theme}) => theme.colors.secondary.dark.two};
  padding: 0 8px;
`;
