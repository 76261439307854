import {FC, ReactNode} from "react";
import styled from "styled-components";
import {Scrollbar} from "react-scrollbars-custom";


export const BurgerScroll: FC<{children: ReactNode}> = ({children}) => {
  return (
    <Wrapper>
      <Scrollbar
        renderer={(props) => {
          const { elementRef, ...restProps } = props;
          return <div {...restProps} ref={elementRef} className="scrollbar" />;
        }}
        wrapperProps={{
          renderer: (props) => {
            const { elementRef, ...restProps } = props;
            return <div {...restProps} ref={elementRef} className="scrollbar-wrapper" />;
          },
        }}
        scrollerProps={{
          renderer: (props) => {
            const { elementRef, ...restProps } = props;
            return <div {...restProps} ref={elementRef} className="" />;
          },
        }}
        contentProps={{
          renderer: (props) => {
            const { elementRef, ...restProps } = props;
            return <div {...restProps} ref={elementRef} className="" />;
          },
        }}
        trackXProps={{
          renderer: (props) => {
            const { elementRef, ...restProps } = props;
            return <div {...restProps} ref={elementRef} className="bar-x" />;
          },
        }}
        thumbXProps={{
          renderer: (props) => {
            const { elementRef, ...restProps } = props;
            return <div {...restProps} ref={elementRef} className="indicator-x" />;
          },
        }}
        trackYProps={{
          renderer: (props) => {
            const { elementRef, ...restProps } = props;
            return <div {...restProps} ref={elementRef} className="bar-y" />;
          },
        }}
        thumbYProps={{
          renderer: (props) => {
            const { elementRef, ...restProps } = props;
            return <div {...restProps} ref={elementRef} className="indicator-y" />;
          },
        }}

        style={{
          height: '100%',
          width: '100%',
        }} >
        {children}
      </Scrollbar>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100dvh;
  width: 260px;
  
  .scrollbar-wrapper {
    right: 0!important;
  }
  
  .bar-y {
    width: 8px!important;
    right: 0px!important;
    top: 16px!important;
    bottom: 72px!important;
    background: none!important;
    height: unset!important;
    border-radius: 0!important;
  }
  
  .indicator-y {
    position: relative;
    background: none!important;
    border-radius: 0!important;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 1px;
      background-color: ${({theme}) => theme.colors.secondary.dark.four};
    }
  }
  
  .body {
    display: block!important;
  }
`;
