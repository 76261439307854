import {FC} from "react";
import {TBrand} from "../../types/TBrand";
import styled from "styled-components";
import {Link} from "react-router-dom";

export const FooterProviders: FC<{ data: Array<TBrand> }> = ({ data }) => {
  return (
    <Wrapper>
      <List>
        {data.map((item, index) => <Item key={index}>
          <StyledLink to={item.url}>
            <ImageHover className="hover" src={item.hover} alt={item.title} />
            <Image src={item.image} alt={item.title} />
          </StyledLink>
        </Item>)}
      </List>
    </Wrapper>
  );
};

const Image = styled.img`
  ${({ theme }) => {
    if (theme.screen?.size === 'mob' && theme.screen.touch) return `
      height: 28px;
    `;
    
    return `
      height: 36px;
    `;
  }}
`;

const ImageHover = styled.img`
  position: absolute;
  ${({ theme }) => {
    if (theme.screen?.size === 'mob' && theme.screen.touch) return `
      height: 28px;
    `;

    return `
      height: 36px;
    `;
  }}
`;

const StyledLink = styled(Link)`
  position: relative;
  display: flex;


  img {
    opacity: 1;
    transition: all 0.08s ease-in-out;
  }
  
  .hover {
    opacity: 0;
  }
  
  &:hover {
    img {
      opacity: 0;
    }
    
    .hover {
      opacity: 1;
    }
  }
`;

const Item = styled.li`
  padding: 18px 22px;
`;

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  justify-content: center;
  margin: -18px -22px;
`;

const Wrapper = styled.div`
  overflow: hidden;
  background-color: ${({theme}) => theme.colors.secondary.dark.three};

  ${({ theme }) => {
    const { screen } = theme;
    if (screen?.breakpoint === 'desc_sm' || screen?.size === 'tab') return `
      padding: 40px 32px 60px;
    `;
    
    if (screen?.size === 'mob') return `
      padding: 40px 16px 60px;
    `;

    return `
      padding: 40px 40px 60px;
    `;
  }}
`;
