import {FC, useContext, useState} from "react";
import styled from "styled-components";
import {TGame} from "../../types/TGame";
import {CardGame} from "../../components/Card/CardGame";
import {Link} from "react-router-dom";
import {ThemeContext} from "../../ThemeSetup";
import {useKeenSlider} from "keen-slider/react";

interface IGamesCarousel {
  title: string;
  data: Array<TGame>;
  type?: 'default' | 'dual' | 'rtp';
  moreUrl?: string;
  size?: 'large' | 'default' | 'small' | 'verySmall';
}

export const GamesCarousel: FC<IGamesCarousel> = ({ title, data, type, moreUrl, size }) => {
  const {screen} = useContext(ThemeContext);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);

  const [sliderRef, instanceRef] = useKeenSlider({initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
    created() {
      setLoaded(true)
    },
    loop: false,
    mode: screen?.touch ? "free" :"snap",
    rtl: false,
    breakpoints: {
      "(min-width: 0px)": {
        slides: {
          spacing: 8,
          perView: "auto",
        },
      },
      "(min-width: 640px)": {
        slides: {
          spacing: 12,
          perView: "auto",
        },
      },
    },
  });

  return (
    <Wrapper>
      <Header>
        <HeaderControls>
          <Title>{title}</Title>
          {loaded && (screen?.size !== 'mob' || !screen?.touch) && <Navs>
            <ArrowButton
              onClick={(e: any) =>
                e.stopPropagation() || instanceRef.current?.prev()
              }
              disabled={currentSlide === 0} ><i className="icon-chevron-sm-left" /></ArrowButton>
            <ArrowButton
              onClick={(e: any) =>
                e.stopPropagation() || instanceRef.current?.next()
              }
              disabled={instanceRef.current?.track.details.maxIdx === instanceRef.current?.track.details.abs} ><i className="icon-chevron-sm-right" /></ArrowButton>
          </Navs>}
        </HeaderControls>
        {moreUrl &&
          <MoreLink to={moreUrl}>
            <span>View all</span> <i className="icon-chevron-sm-right" />
          </MoreLink>
        }
      </Header>
      <Body>
        {screen?.size !== 'mob' && <Left className="arrow">
          {loaded && <ArrowButtonRounded
              onClick={(e: any) =>
                e.stopPropagation() || instanceRef.current?.prev()
              }
              disabled={currentSlide === 0} >
              <i className="icon-chevron-sm-left" />
          </ArrowButtonRounded>}
        </Left>}
        <SliderWrapper>
          <Slider ref={sliderRef} className="keen-slider">
            {data.map((item, index) => {
              if (type === 'dual') {
                if (index % 2 === 0) {
                  return (
                    <SliderItem key={item.id} $size={size} className="keen-slider__slide">
                      <Col>
                        <Item>
                          <CardGame data={item} />
                        </Item>
                        {data[index + 1] && <Item>
                          <CardGame data={data[index + 1]} />
                        </Item>}
                      </Col>
                    </SliderItem>
                  )
                }
              } else {
                return (
                  <SliderItem key={item.id} $size={size} className="keen-slider__slide">
                    <CardGame data={item} isRtp={type === 'rtp'} />
                  </SliderItem>
                )
              }
            })}
          </Slider>
        </SliderWrapper>
        {screen?.size !== 'mob' && <Right className="arrow">
          {loaded && <ArrowButtonRounded
            onClick={(e: any) =>
              e.stopPropagation() || instanceRef.current?.next()
            }
            disabled={instanceRef.current?.track.details.maxIdx === instanceRef.current?.track.details.abs} >
            <i className="icon-chevron-sm-right" />
          </ArrowButtonRounded>}
        </Right>}
      </Body>
    </Wrapper>
  );
};


const Wrapper = styled.section`
  overflow: hidden;
  ${({ theme }) => {
    if (theme.screen?.size === 'mob') return `
      padding: 20px 0;
    `;
    
    return `
      padding: 28px 0;
    `;
  }}
`;

const Slider = styled.div`
  overflow: unset!important;
`;

const SliderItem = styled.div<{ $size?: 'large' | 'default' | 'small' | 'verySmall' }>`
  overflow: unset!important;
  ${({theme, $size}) => {
    if ($size === 'large') {
      if (theme.screen?.size === 'mob') return `
        max-width: 132px;
        min-width: 132px;
      `;
      
      if (theme.screen?.size === 'tab') return `
        max-width: 148px;
        min-width: 148px;
      `;

      return `
        max-width: 200px;
        min-width: 200px;
      `;
    }

    if ($size === 'small') {
      if (theme.screen?.size === 'tab') return `
        max-width: 128px;
        min-width: 128px;
      `;

      if (theme.screen?.size === 'mob' && theme.screen.touch) return `
        max-width: 108px;
        min-width: 108px;
      `;

      if (theme.screen?.size === 'mob' && !theme.screen.touch) return `
        max-width: 116px;
        min-width: 116px;
      `;

      return `
        max-width: 160px;
        min-width: 160px;
      `;
    }

    if ($size === 'verySmall') {
      if (theme.screen?.size === 'tab') return `
        max-width: 116px;
        min-width: 116px;
      `;

      if (theme.screen?.size === 'mob' && theme.screen.touch) return `
        max-width: 88px;
        min-width: 88px;
      `;

      if (theme.screen?.size === 'mob' && !theme.screen.touch) return `
        max-width: 116px;
        min-width: 116px;
      `;

      return `
        max-width: 136px;
        min-width: 136px;
      `;
    }
    
    if (theme.screen?.size === 'tab') return `
      max-width: 136px;
      min-width: 136px;
    `;

    if (theme.screen?.size === 'mob' && theme.screen.touch) return `
      max-width: 116px;
      min-width: 116px;
    `;

    if (theme.screen?.size === 'mob' && !theme.screen.touch) return `
      max-width: 132px;
      min-width: 132px;
    `;

    return `
      max-width: 168px;
      min-width: 168px;
    `;
  }}
`;

const ArrowButtonRounded = styled.button`
  border: 0 none;
  cursor: pointer;
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  border-radius: 50%;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({theme}) => theme.colors.secondary.dark.six};
  color: ${({theme}) => theme.colors.secondary.dark.one};
  transition: all 0.12s ease;
  
  &:hover {
    background-color: ${({theme}) => theme.colors.secondary.dark.five};
  }
  
  &:active {
    background-color: ${({theme}) => theme.colors.secondary.dark.six};
  }
  
  &:disabled {
    pointer-events: none;
    opacity: 0;
  }
`;

const Left = styled.div`
  position: relative;
  z-index: 100;
  width: 40px;
  display: flex;
  align-items: center;
  pointer-events: none;

  ${({ theme }) => {
    const { screen } = theme;
    if (screen?.breakpoint === 'desc_sm' || screen?.size === 'tab') return `
      width: 32px;
      >button {
        transform: translateX(12px);
        pointer-events: all;
      }
    `;

    return `
      width: 40px;
      >button {
        transform: translateX(20px);
        pointer-events: all;
      }
    `;
  }}
`;

const Body = styled.div`
  display: flex;
  .arrow {
    opacity: 0;
    transition: all 0.12s ease;
  }
  
  &:hover {
    .arrow {
      opacity: 1;
    }
  }
  
  ${({ theme }) => {
    if (theme.screen?.size === 'mob') return `
      padding: 0 16px;
    `;
    return ``;
  }}
`;

const Right = styled.div`
  position: relative;
  z-index: 100;
  display: flex;
  align-items: center;
  pointer-events: none;

  ${({ theme }) => {
    const { screen } = theme;
    
    if (screen?.breakpoint === 'desc_sm' || screen?.size === 'tab') return `
      width: 32px;
      >button {
        transform: translateX(-12px);
        pointer-events: all;
      }
    `;

    return `
      width: 40px;
      >button {
        transform: translateX(-20px);
        pointer-events: all;
      }
    `;
  }}
`;

const SliderWrapper = styled.div`
  position: relative;
  z-index: 1;
  min-width: 100%;

  ${({ theme }) => {
    const { screen } = theme;
    if (screen?.breakpoint === 'desc_sm' || screen?.size === 'tab') return `
      margin: 0 -32px;
      padding: 0 32px;
    `;

    return `
      margin: 0 -40px;
      padding: 0 40px;
    `;
  }}
`;

const Navs = styled.div`
  display: flex;
  max-height: 24px;
  align-items: center;
  margin-left: 12px;
  >* {
    &:first-child {
      margin-right: 8px;
    }
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ theme }) => {
    const { screen } = theme;
    if (screen?.breakpoint === 'desc_sm' || screen?.size === 'tab') return `
      padding: 0 32px 24px;
    `;
    
    if (screen?.size === 'mob' && theme.screen.touch) return `
      padding: 0 16px 16px;
    `;
    
    if (screen?.size === 'mob' && !theme.screen.touch) return `
      padding: 0 16px 24px;
    `;

    return `
      padding: 0 40px 24px;
    `;
  }}
`;

const HeaderControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h2`
  font-family: ${({theme}) => theme.fonts.bold.family};
  font-weight: ${({theme}) => theme.fonts.bold.weight};
  letter-spacing: 0.12em;
  text-transform: uppercase;
  
  ${({ theme }) => {
    if (theme.screen?.size === 'mob' && theme.screen.touch) return `
      font-size: 18px;
      line-height: 22px;
    `;
    
    return `
      font-size: 20px;
      line-height: 24px;
    `;
  }}
`;

const MoreLink = styled(Link)`
  font-family: ${({theme}) => theme.fonts.bold.family};
  font-weight: ${({theme}) => theme.fonts.bold.weight};
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  text-decoration: none;
  color: ${({theme}) => theme.colors.accent.dark.default};
  
  &:hover {
    color: ${({theme}) => theme.colors.accent.dark.hover};
    span {
      text-decoration: underline;
    }
  }
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  margin: -8px 0;
`;

const Item = styled.div`
  padding: 8px 0;
`;

const ArrowButton = styled.button`
  cursor: pointer;
  border: 0 none;
  font-size: 16px;
  line-height: 22px;
  width: 28px;
  height: 28px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({theme}) => theme.colors.secondary.dark.four};
  color: ${({theme}) => theme.colors.secondary.dark.six};
    
  &:hover {
    background-color: ${({theme}) => theme.colors.secondary.dark.five};
  }
  
  &:active {
    background-color: ${({theme}) => theme.colors.secondary.dark.four};
  }
  
  &:disabled {
    background-color: ${({theme}) => theme.colors.secondary.dark.two};
    color: ${({theme}) => theme.colors.secondary.dark.four};
  }
`;

