import {ChangeEvent, FC, useRef, useState} from "react";
import styled from "styled-components";
import {TInput} from "../../types/TInput";

export const Input: FC<TInput> = (
  {
    name,
    type,
    theme,
    icon,
    error,
    onChange,
    value,
    placeholder,
    description,
    disabled,
    deleteButton,
  }) => {
  const [state, setState] = useState({
    isDelete: value.length > 0 && deleteButton,
  });
  const ref = useRef<HTMLInputElement>(null);

  const handleChange = (value: string) => {
    setState(prev => ({...prev, isDelete: value.length > 0}));
    onChange(value);
  }

  const handleDelete = () => {
    setState(prev => ({...prev, isDelete: false}));
    onChange('');
    ref.current?.focus();
  }

  return (
    <Wrapper>
      {icon && <Icon className={icon} />}
      {state.isDelete && <DeleteButton
        className="icon-delete"
        onClick={handleDelete} >
          <i className="icon-trash" />
      </DeleteButton>}
      <Field
        ref={ref}
        $isIcon={!!icon}
        $isDeleteButton={state.isDelete}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        onChange={e => handleChange(e.target.value)} />
    </Wrapper>
  );
};

const DeleteButton = styled.button`
  cursor: pointer;
  background: none;
  border: 0 none;
  position: absolute;
  top: 0;
  right: 0px;
  width: 40px;
  height: 40px;
  font-size: 16px;
  color: ${({theme}) => theme.colors.secondary.dark.five};
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    color: ${({theme}) => theme.colors.accent.dark.hover};
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Icon = styled.i`
  font-size: 16px;
  line-height: 22px;
  width: 20px;
  height: 20px;
  pointer-events: none;
  color: ${({theme}) => theme.colors.secondary.dark.four};
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 10px;
  left: 12px;
`;

const Field = styled.input<{ $isIcon?: boolean, $isDeleteButton?: boolean }>`
  font-family: ${({theme}) => theme.fonts.regular.family};
  height: 40px;
  background-color: ${({theme}) => theme.colors.secondary.dark.two};
  border: 0 none;
  border-radius: 12px;
  color: ${({theme}) => theme.colors.secondary.dark.six};
  font-size: 16px;
  
  &::placeholder {
    color: ${({theme}) => theme.colors.secondary.dark.four};
  }
  
  &:focus {
    border: 2px solid ${({theme}) => theme.colors.accent.dark.default};
  }
  
  ${({$isIcon, $isDeleteButton}) => {
    if ($isDeleteButton && $isIcon) return `
      padding: 0 40px 0 40px;
      
      &:focus {
        padding: 0 38px 0 38px;
      }
    `;
    
    if ($isIcon) return `
      padding: 0 12px 0 40px;
      
      &:focus {
        padding: 0 10px 0 38px;
      }
    `;
    
    if ($isDeleteButton) return `
      padding: 0 52px 0 12px;
      
      &:focus {
        padding: 0 50px 0 10px;
      }
    `;
    
    return `
      padding: 0 12px;
    `;
  }}
`;
