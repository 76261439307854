import {FC} from "react";
import styled from "styled-components";
import {Chip} from "./Chip";
import {TNav} from "../../types/TNav";

export const ChipList: FC<{ data: Array<TNav> }> = ({ data }) => {
  return (
    <Wrapper>
      {data.map((item, index) =>
        <Item key={index}>
          <Chip text={item.text} link={item.link} />
        </Item>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: -4px;
`;

const Item = styled.li`
  padding: 4px;
`;
