import React, {useEffect} from 'react';
import {BrowserRouter} from "react-router-dom";
import {ThemeSetup} from "./ThemeSetup";
import {MainLayout} from "./layout/MainLayout";
import {NavbarProvider} from "./providers/Nabvar";
import {ModalProvider} from "./providers/ModalProvider";
import {GamesProvider} from "./providers/GamesProvider";

function App() {
  const [state, setState] = React.useState({
    login: '',
    password: '',
    auth: false,
  });

  useEffect(() => {
    const auth = localStorage.getItem('auth');
    if (auth) {
      setState(prev => ({ ...prev, auth: true }));
    }
  }, []);

  const handleAuth = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (state.login === 'dev' && state.password === 'dev123') {
      localStorage.setItem('auth', 'true');
      setState(prev => ({ ...prev, auth: true }));
    }
  }

  if (!state.auth) {
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <h1>Authorize</h1>
        <form
          onSubmit={handleAuth}
          style={{
          display: 'flex',
          flexDirection: 'column',
        }}>
          <input placeholder="Login" value={state.login} onChange={(e) => setState(prev => ({ ...prev, login: e.target.value}))} />
          <input placeholder="Password" value={state.password} onChange={(e) => setState(prev => ({ ...prev, password: e.target.value}))} />
          <button>Auth</button>
        </form>
      </div>
    );
  }

  return (
    <GamesProvider>
      <ThemeSetup>
        <BrowserRouter>
          <NavbarProvider>
            <ModalProvider>
                <MainLayout />
            </ModalProvider>
          </NavbarProvider>
        </BrowserRouter>
      </ThemeSetup>
    </GamesProvider>
  );
}


export default App;
