import {createContext, FC, ReactNode, useState} from "react";
import {TGame} from "../types/TGame";

interface IGamesProvider {
  onOpenGame: (id: string) => void;
  onCloseGame: () => void;
  openedGame: null | TGame;
}

export const GamesContext = createContext<Partial<IGamesProvider>>({});

export const GamesProvider: FC<{ children: ReactNode }> = ({children}) => {
  const [state, setState] = useState<{
    openedGame: null | TGame;
  }>({
    openedGame: null
  });

  const onOpenGame = (id: string) => {
    console.log(`Opening game with id: ${id}`)
    setState(prev => ({
      ...prev,
      openedGame: {
        id: id,
        title: 'Buffalo Trail',
        image: 'cards/games/buffalotrail.png',
        provider: 'netent',
        url: '/buffalotrail',
        isRealMoney: false,
        isNew: false,
        isTop: false,
        isDemoMode: true,
        hotRTP: 96.5,
        jackpot: null,
        tournament: null,
      }
    }))
  }

  const onCloseGame = () => {
    setState(prev => ({
      ...prev, openedGame: null
    }))
  }

  return (
    <GamesContext.Provider value={{
      onOpenGame, onCloseGame,
      openedGame: state.openedGame
    }}>
      { children }
    </GamesContext.Provider>
  );
};
