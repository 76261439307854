import {FC} from "react";
import styled from "styled-components";
import {Badge} from "../Badge/Badge";
import {CounterBox} from "../CounterBox/CounterBox";
import {Timer} from "../CounterBox/Timer";
import {Button} from "../Button/Button";
import {GoogleSmall} from "../App/GoogleSmall";
import {AppleSmall} from "../App/AppleSmall";

export const CardApps: FC = () => {
  return (
    <Wrapper>
      <Content>
        <Header>
          <div>
            <SubTitle>On Your phone</SubTitle>
            <Title>Drip Mobile App</Title>
          </div>
          <BadgeWrapper>
            <Badge>Hot</Badge>
          </BadgeWrapper>
        </Header>
        <Footer>
          <AppWrapper>
            <GoogleSmall link="/google" />
          </AppWrapper>

          <AppWrapper>
            <AppleSmall link="/apple" />
          </AppWrapper>
        </Footer>
      </Content>
      <Background >
        <BackgroundImage src="cards/banner/app.png" alt="Drip Mobile App" />
      </Background>
    </Wrapper>
  );
};

const AppWrapper = styled.div`
  width: 100%;
  padding: 0 8px;
`;

const Wrapper = styled.div`
  position: relative;
  height: 280px;
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const BadgeWrapper = styled.div`
  padding-top: 3px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 21px 24px 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.32) 28.98%, rgba(0, 0, 0, 0) 100%);
`;

const SubTitle = styled.span`
  color: ${({theme}) => theme.colors.secondary.dark.six};
  font-family: ${({theme}) => theme.fonts.bold.family};
  font-weight: ${({theme}) => theme.fonts.bold.weight};
  opacity: 0.6;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
`

const Title = styled.h3<{ $size?: 'small' | 'default' }>`
  color: ${({theme}) => theme.colors.secondary.dark.six};
  font-family: ${({theme}) => theme.fonts.header.family};
  font-weight: ${({theme}) => theme.fonts.header.weight};
  font-size: 28px;
  line-height: 33px;
  margin-top: 5px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 16px 24px;
`;


const Background = styled.div`
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const BackgroundImage = styled.img`
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
