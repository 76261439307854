import {FC} from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";
import {useKeenSlider} from "keen-slider/react";


export const TabsDefault: FC = () => {
  const [sliderRef] = useKeenSlider({
    initial: 0,
    loop: false,
    mode: "snap",
    rtl: false,
    slides: {
      spacing: 0,
      perView: "auto",
    },
  });

  return (
    <Wrapper>
      <Slider ref={sliderRef} className="keen-slider" >
        <SliderItem className="keen-slider__slide" >
          <Item>
            <StyledLink
              $active={true}
              to="/current">Current winners</StyledLink>
          </Item>
        </SliderItem>

        <SliderItem className="keen-slider__slide" >
          <Item>
            <StyledLink
              $preDrop={true}
              to="/big_wins">Big wins</StyledLink>
          </Item>
        </SliderItem>

        <SliderItem className="keen-slider__slide" >
          <Dropper>
            24H <i className="icon-Chevrones" />
          </Dropper>
        </SliderItem>

        <SliderItem className="keen-slider__slide" >
          <Item>
            <StyledLink
              $preDrop={true}
              to="/big_wins">Highest multipliers</StyledLink>
          </Item>
        </SliderItem>

        <SliderItem className="keen-slider__slide" >
          <Dropper>
            24H <i className="icon-Chevrones" />
          </Dropper>
        </SliderItem>
      </Slider>
    </Wrapper>
  );
};

const SliderItem = styled.div`
  overflow: unset!important;
  display: flex;
  width: auto!important;
`;

const Slider = styled.div`
  overflow: visible!important;
`;

const Wrapper = styled.div`
  display: flex;
  list-style: none;
  margin: 0 -16px;
`;

const Item = styled.div`
  font-family: ${({theme}) => theme.fonts.bold.family};
  font-weight: ${({theme}) => theme.fonts.bold.weight};
  color: ${({theme}) => theme.colors.secondary.dark.five};
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
`;

const StyledLink = styled(Link)<{ $preDrop?: boolean, $active?: boolean }>`
  display: flex;
  text-decoration: none;
  position: relative;
  color: ${({theme}) => theme.colors.secondary.dark.five};
  white-space: nowrap;
  
  &:hover {
    color: ${({theme}) => theme.colors.secondary.dark.six};
  }
  
  ${({theme, $preDrop}) => {
    if ($preDrop) return `
      padding: 20px 8px 20px 16px;
      &::after {
        content: '';
        opacity: 0;
        position: absolute;
        bottom: 0;
        left: 16px;
        right: 8px;
        height: 2px;
        background: ${theme.colors.accent.gradient.default};
      }
    `;
    
    return `
      padding: 20px 16px;
      &::after {
        content: '';
        opacity: 0;
        position: absolute;
        bottom: 0;
        left: 16px;
        right: 16px;
        height: 2px;
        background: ${theme.colors.accent.gradient.default};
      }
    `;
  }}
  
  ${({theme, $active}) => {
    if ($active) return `
      color: ${theme.colors.secondary.dark.six};
      
      &::after {
        opacity: 1;
      }
    `;
    
    return ``;
  }}
`;

const Dropper = styled.div`
  font-family: ${({theme}) => theme.fonts.bold.family};
  font-weight: ${({theme}) => theme.fonts.bold.weight};
  color: ${({theme}) => theme.colors.secondary.dark.five};
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-right: 12px;
  
  &:hover {
    color: ${({theme}) => theme.colors.secondary.dark.six};
  }
    
  i {
    font-size: 16px;
    line-height: 24px;
    margin-left: 6px;
  }
`;
