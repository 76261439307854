import {FC} from "react";
import styled from "styled-components";
import {Image} from "../Image/Image";

type TAvatarSize = 'default' | 'small';

export const Avatar: FC<{ link?: string, image?: string, glyph?: string, size?: TAvatarSize }> = (
  { link, image, glyph, size }) => {

  return (
    <Wrapper $size={size}>
      { image && <Image
          src={image}
          alt={glyph}
          size={size === 'small' ? 'medium' : 'veryLarge'} /> }
    </Wrapper>
  );
}

const Wrapper = styled.div<{ $size?: TAvatarSize }>`
  ${({ $size }) => {
    return `
      width: 68px;
      height: 68px;
    `
  }}
`
