import {FC} from "react";
import styled from "styled-components";
import {TProvider} from "../../types/TProvider";
import {Link} from "react-router-dom";

export const Provider: FC<TProvider> = ({ name, title, logoUrl, url, gamesCount  }) => {
  return (
    <Wrapper>
      <ImageWrapper>
        <Image className="image" src={logoUrl} alt={title} />
      </ImageWrapper>
      <Footer>
        {gamesCount} <Muted>Games</Muted>
      </Footer>
      <MainLink to={url} />
    </Wrapper>
  );
};


const Wrapper = styled.div`
  background-color: ${props => props.theme.colors.secondary.dark.three};
  height: 86px;
  border-radius: 12px;    
  display: flex;
  flex-direction: column;
  overflow: hidden;
  
  .image {
    transition: all 0.12s ease;
    transform: scale(1);
  }
  
  &:hover {
    .image {
      transform: scale(1.12);
    }
  }
`;

const MainLink = styled(Link)`
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Image = styled.img`
  
`;

const ImageWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Footer = styled.div`
  background-color: ${props => props.theme.colors.secondary.dark.two};
  min-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${props => props.theme.fonts.header.family};
  font-weight: ${props => props.theme.fonts.header.weight};
  font-size: 12px;
  line-height: 16px;
`;

const Muted = styled.span`
  color: ${props => props.theme.colors.secondary.dark.four};
  padding-left: 3px;
`;
