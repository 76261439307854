import {FC, useContext, useEffect} from "react";
import {ModalContext} from "../providers/ModalProvider";


export const SignUp: FC = () => {
  const { isModal, modalType, onModalOpen, onModalClose } = useContext(ModalContext);
  useEffect(() => {
    onModalOpen && onModalOpen('register');
    return () => {
      onModalClose && onModalClose();
    }
  }, []);

  return null;
}