import {FC, ReactNode, useContext, useEffect, useRef} from "react";
import {Scrollbar} from "react-scrollbars-custom";
import styled from "styled-components";
import {GamesContext} from "../../providers/GamesProvider";

export const RightBarScroll: FC<{children: ReactNode}> = ({children}) => {
  const { openedGame } = useContext(GamesContext);

  const ref = useRef(null);

  useEffect(() => {
    // @ts-ignore
    ref&& ref.current && ref.current?.scrollToTop();
  }, [openedGame]);

  return (
    <Wrapper>
      <Scrollbar
        ref={ref}
        renderer={(props) => {
          const { elementRef, ...restProps } = props;
          return <div {...restProps} ref={elementRef} className="scrollbar" />;
        }}
        wrapperProps={{
          renderer: (props) => {
            const { elementRef, ...restProps } = props;
            return <div {...restProps} ref={elementRef} className="scrollbar-wrapper" />;
          },
        }}
        scrollerProps={{
          renderer: (props) => {
            const { elementRef, ...restProps } = props;
            return <div {...restProps} ref={elementRef} className="" />;
          },
        }}
        contentProps={{
          renderer: (props) => {
            const { elementRef, ...restProps } = props;
            return <div {...restProps} ref={elementRef} className="" />;
          },
        }}
        trackXProps={{
          renderer: (props) => {
            const { elementRef, ...restProps } = props;
            return <div {...restProps} ref={elementRef} className="bar-x" />;
          },
        }}
        thumbXProps={{
          renderer: (props) => {
            const { elementRef, ...restProps } = props;
            return <div {...restProps} ref={elementRef} className="indicator-x" />;
          },
        }}
        trackYProps={{
          renderer: (props) => {
            const { elementRef, ...restProps } = props;
            return <div {...restProps} ref={elementRef} className="bar-y" />;
          },
        }}
        thumbYProps={{
          renderer: (props) => {
            const { elementRef, ...restProps } = props;
            return <div {...restProps} ref={elementRef} className="indicator-y" />;
          },
        }}

        style={{
          height: '100%',
          width: '100%',
        }} >
        {children}
      </Scrollbar>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  
  .scrollbar-wrapper {
    right: 0!important;
  }
  
  .bar-y {
    display: none!important;
    width: 8px!important;
    right: 8px!important;
    top: 16px!important;
    bottom: 16px!important;
    background: none!important;
    height: unset!important;
    border-radius: 4px!important;
  }
  
  .indicator-y {
    position: relative;
    background: none!important;
    border-radius: 4px!important;
    background-color: ${({theme}) => theme.colors.secondary.dark.three}!important;
  }
  
  .body {
    display: block!important;
  }
`;
