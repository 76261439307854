import {FC, useState} from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";
import {Button} from "../Button/Button";
import {ChipList} from "../Chips/ChipList";
import {TNav} from "../../types/TNav";
import {TableGameInfo} from "../Table/TableGameInfo";
import {DropButton} from "../Button/DropButton";

const chips: Array<TNav> = [
  { text: 'Netent', link: '/netent' },
  { text: 'Animals', link: '/animals' },
  { text: 'Minners brothers', link: '/minners-brothers' },
  { text: 'Bonus Buy', link: '/bonus-buy' },
  { text: 'Classic', link: '/classic' },
  { text: '777', link: '/777' },
  { text: '3D', link: '/3d' },
  { text: 'Slots', link: '/slots' },
  { text: 'Bonus Buy', link: '/bonus-buy-2' },
  { text: 'Classic', link: '/classic-2' },
];

const info: Array<{ key: string, value: string }> = [
  { key: 'Min Bet', value: '0.05 EUR' },
  { key: 'Volatility', value: 'Middle' },
  { key: 'Max Bet', value: 'Middle' },
  { key: 'RTP', value: '96.5%' },
  { key: 'Multiplier', value: '40.000' },
  { key: 'Hit Rate', value: 'High' },
  { key: 'Lines', value: '20' },
  { key: 'Bonus Contribution', value: '40%' },
];

export const GameDescription: FC = () => {
  const [state, setState] = useState({
    isDropped: false,
  });

  const handleDrop = () => {
    setState(prev => ({ ...prev, isDropped: !prev.isDropped }));
  };

  return (
    <Wrapper>
      <Provider>
        <ProviderLink to={'netent'} >
          Netent
        </ProviderLink>
      </Provider>

      <Header>
        <HeaderText>
          Creature From the Black Lagoon
        </HeaderText>
        <Favorite>
          <FavoriteButton>
            <i className="icon-heart" />
          </FavoriteButton>
        </Favorite>
      </Header>

      <ButtonsWrapper>
        <Button>Play</Button>
        <Button theme="borderDark">Demo</Button>
      </ButtonsWrapper>

      <RealMoney>
        <i className="icon-real-money" />
        Bonus funds cannot be used in this game
      </RealMoney>

      <CheepsWrapper>
        <ChipList data={chips} />
      </CheepsWrapper>

      <Additional $isDropped={state.isDropped}>
        <TableGameInfo data={info} />
        <AdditionalText>
          It has survived not only five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularised in the 1960s
          with the release of Letraset sheets containing
        </AdditionalText>

        {!state.isDropped && <DropWrapper>
          <DropButton icon="icon-chevron-sm-bottom" onClick={handleDrop}>
            Show more
          </DropButton>
        </DropWrapper>}
      </Additional>
    </Wrapper>
  );
};

const Additional = styled.div<{ $isDropped: boolean }>`
  padding-top: 32px;
  position: relative;
  overflow: hidden;
  transition: all 0.12s ease;
  
  ${({$isDropped}) => {
    if ($isDropped) {
      return `
        max-height: 600px;
      `;
    }
    
    return `
      height: 160px;
      max-height: 160px;
    `;
  }}
`;

const DropWrapper = styled.div`
  height: 100px;
  position: absolute;
  z-index: 200;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(180deg, rgba(22, 25, 25, 0) 0%, #161919 100%);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  pointer-events: none;
  
  >* {
    pointer-events: all;
  }
`;

const AdditionalText = styled.div`
  font-size: 16px;
  line-height: 22px;
  color: ${({theme}) => theme.colors.secondary.dark.five};
  padding: 15px 24px 0;
`;

const RealMoney = styled.div`
  padding: 24px 24px 0;
  color: ${({theme}) => theme.colors.system.yellow};
  text-align: center;
  
  i {
    font-size: 20px;
    line-height: 22px;
    position: relative;
    bottom: -3px;
    margin-right: 8px;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  padding: 24px 20px 0;
  
  >* {
    width: 100%;
    margin: 0 4px;
  }
`;

const CheepsWrapper = styled.div`
  padding: 24px 24px 0;
`;

const Favorite = styled.div`
  
`;

const FavoriteButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 0 none;
  cursor: pointer;
  font-size: 24px;
  line-height: 28px;
  margin-left: 20px;
  background-color: ${({theme}) => theme.colors.secondary.dark.three};
  color: ${({theme}) => theme.colors.secondary.dark.six};
  
  &:hover {
    background-color: ${({theme}) => theme.colors.secondary.dark.four};
    color: ${({theme}) => theme.colors.secondary.dark.six};
  }
`;

const HeaderText = styled.h2`
  font-family: ${({theme}) => theme.fonts.header.family};
  font-weight: ${({theme}) => theme.fonts.header.weight};
  font-size: 28px;
  line-height: 32px;
`;

const Header = styled.div`
  padding: 12px 24px 0;
  display: flex;
`;

const Wrapper = styled.div`
  padding-bottom: 24px;
`;

const Provider = styled.div`
  padding: 24px 24px 0;
`;

const ProviderLink = styled(Link)`
  font-family: ${({theme}) => theme.fonts.bold.family};
  font-weight: ${({theme}) => theme.fonts.bold.weight};
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.13em;
  text-transform: uppercase;
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
`;
