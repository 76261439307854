import {FC} from "react";
import styled from "styled-components";

interface IImage {
  size?: 'verySmall' |'small' | 'medium' | 'large'  | 'veryLarge'
  src?: string
  alt?: string
}

export const Image: FC<IImage> = ({ size, src, alt }) => {
  return (
    <Wrapper $size={size} >
      {src && <ImageBody src={src} alt={alt} /> }
    </Wrapper>
  );
}

const ImageBody = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const Wrapper = styled.div<{
  $size?: 'verySmall' |'small' | 'medium' | 'large'  | 'veryLarge'
}>`
  overflow: hidden;
  position: relative;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.secondary.dark.four};
  
  ${({ $size }) => {
    switch ($size) {
      case 'verySmall':
        return `
          max-width: 24px;
          max-height: 24px;
          min-width: 24px;
          min-height: 24px;
        `;
        
      case 'small':
        return `
          max-width: 32px;
          max-height: 32px;
          min-width: 32px;
          min-height: 32px;
        `;
        
      case 'large':
        return `
          min-width: 56px;
          min-height: 56px;
          max-width: 56px;
          max-height: 56px;
        `;
        
      case 'veryLarge':
        return `
          min-width: 68px;
          min-height: 68px;
          max-width: 68px;
          max-height: 68px;
        `;
        
      default:
        return `
          max-width: 40px;
          max-height: 40px;
          min-width: 40px;
          min-height: 40px;
        `;
    }
  }}
`
