import {FC} from "react";
import Countdown from "react-countdown";
import styled from "styled-components";

export const Timer: FC<{time: number}> = ({ time }) => {
  return (
    <div>
      <Countdown
        date={time}
        renderer={renderer}
      />
    </div>
  );
};


const formatter = (value: number) => {
  return value < 10 ? `0${value}` : value;
}

const renderer: any = ({ hours, minutes, seconds, completed }: {hours: number, minutes: number, seconds: number, completed: string}) => {
  if (completed) {
    //   // Render a completed state
    //   return <Completionist />;
    return <span></span>;
  } else {
    // Render a countdown
    return <Wrapper>
      <Cell>
        {formatter(hours)}
        <Label>D</Label>
      </Cell>
      <Cell>
        {formatter(minutes)}
        <Label>H</Label>
      </Cell>
      <Divider>:</Divider>
      <Cell>
        {formatter(seconds)}<span className="cell-label"></span>
        <Label>M</Label>
      </Cell>
    </Wrapper>;
  }
};

const Wrapper = styled.span`
  position: relative;
  font-family: ${({theme}) => theme.fonts.header.family};
  font-weight: ${({theme}) => theme.fonts.header.weight};
  font-size: 24px;
  line-height: 28px;
`;

const Cell = styled.span`

`;

const Label = styled.span`
  font-size: 16px;
  line-height: 22px;
  margin-right: 4px;
  opacity: 0.6;
`;

const Divider = styled.span`
  font-size: 28px;
  line-height: 20px;
  margin-right: 4px;
`;