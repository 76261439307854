import {FC} from "react";
import styled from "styled-components";
import {ProvidersFilter} from "../blocks/Providers/ProvidersFilter";
import {Divider} from "../components/Divider/Divider";
import {JackpotBanner} from "../components/Jackpot/JackpotBanner";
import {CardApps} from "../components/Card/CardApps";
import {CardBanner} from "../components/Card/CardBanner";
import {TBanner} from "../types/TBanner";

const banner: TBanner = {
  id: 'tournament-01',
  title: 'Mega Million',
  type: 'tournament',
  link: '/tournament',
  image: 'cards/banner/main_02.png',
  backgroundImage: 'cards/banner/banner_02.png',
  prize: {
    value: 30000.00,
    currency: 'USD',
  },
  badge: 'strategySpin',
};

export const RightDefault: FC = () => {

  return (
    <Wrapper>
      <ProvidersFilter />

      <Divider />

      <JackpotBanner />

      <CardApps />

      <Divider />

      <CardBanner data={banner} size="small" />
    </Wrapper>
  );
};

const Wrapper = styled.div`

`;
