import {ChangeEvent, FC, useState} from "react";
import styled from "styled-components";
import {TabsPanel} from "../../components/Tabs/TabsPanel";
import {TTabs} from "../../types/TTabs";
import {Input} from "../../components/Form/Input";
import {Button} from "../../components/Button/Button";
import {TableProviders} from "../../components/Table/TableProviders";
import {TProvider} from "../../types/TProvider";

const dataDefault: Array<TProvider> = [
  {
    name: 'netent',
    title: 'NetEnt',
    logoUrl: 'providers/rounded/netent.svg',
    url: '/bgaming',
    gamesCount: 69,
    jackpot: {
      value: 123453.45,
      currency: 'USD',
    }
  },
  {
    name: 'endorphina',
    title: 'Endorphina',
    logoUrl: 'providers/rounded/endorphina.svg',
    url: '/bgaming',
    gamesCount: 136,
    jackpot: {
      value: 123453.45,
      currency: 'USD',
    }
  },
  {
    name: 'booming',
    title: 'Booming',
    logoUrl: 'providers/rounded/booming.svg',
    url: '/bgaming',
    gamesCount: 234,
    jackpot: {
      value: 123453.45,
      currency: 'USD',
    }
  },
  {
    name: 'egt',
    title: 'EGT',
    logoUrl: 'providers/rounded/egt.svg',
    url: '/egt',
    gamesCount: 216,
    jackpot: {
      value: 123453.45,
      currency: 'USD',
    }
  },
  {
    name: 'evolution',
    title: 'Evolution',
    logoUrl: 'providers/rounded/evolution.svg',
    url: '/evolution',
    gamesCount: 134,
    jackpot: {
      value: 123453.45,
      currency: 'USD',
    }
  },
  {
    name: 'gameart',
    title: 'GameArt',
    logoUrl: 'providers/rounded/gameart.svg',
    url: '/gameart',
    gamesCount: 93,
    jackpot: {
      value: 123453.45,
      currency: 'USD',
    }
  },
  {
    name: 'gamzix',
    title: 'Gamzix',
    logoUrl: 'providers/rounded/gamzix.svg',
    url: '/gamzix',
    gamesCount: 118,
    jackpot: {
      value: 123453.45,
      currency: 'USD',
    }
  },
  {
    name: 'luckystreak',
    title: 'Luckystreak',
    logoUrl: 'providers/rounded/luckystreak.svg',
    url: '/luckystreak',
    gamesCount: 49,
    jackpot: {
      value: 123453.45,
      currency: 'USD',
    }
  },
  {
    name: 'nolimit',
    title: 'NoLimit',
    logoUrl: 'providers/rounded/nolimit.svg',
    url: '/nolimit',
    gamesCount: 141,
    jackpot: {
      value: 123453.45,
      currency: 'USD',
    }
  },
  {
    name: 'playngo',
    title: 'PlayNGo',
    logoUrl: 'providers/rounded/playngo.svg',
    url: '/playngo',
    gamesCount: 93,
    jackpot: {
      value: 123453.45,
      currency: 'USD',
    }
  },
  {
    name: 'pragmaticplay',
    title: 'Pragmatic Play',
    logoUrl: 'providers/rounded/pragmaticplay.svg',
    url: '/pragmaticplay',
    gamesCount: 293,
    jackpot: {
      value: 123453.45,
      currency: 'USD',
    }
  },
  {
    name: 'pushgaming',
    title: 'Push Gaming',
    logoUrl: 'providers/rounded/pushgaming.svg',
    url: '/pushgaming',
    gamesCount: 64,
    jackpot: {
      value: 123453.45,
      currency: 'USD',
    }
  },
  {
    name: 'redtiger',
    title: 'Red Tiger',
    logoUrl: 'providers/rounded/redtiger.svg',
    url: '/redtiger',
    gamesCount: 112,
    jackpot: {
      value: 123453.45,
      currency: 'USD',
    }
  },
  {
    name: 'thunderkick',
    title: 'Thunderkick',
    logoUrl: 'providers/rounded/thunderkick.svg',
    url: '/thunderkick',
    gamesCount: 344,
    jackpot: {
      value: 123453.45,
      currency: 'USD',
    }
  },
  {
    name: 'wazdan',
    title: 'Wazdan',
    logoUrl: 'providers/rounded/wazdan.svg',
    url: '/wazdan',
    gamesCount: 34,
    jackpot: {
      value: 123453.45,
      currency: 'USD',
    }
  },
  {
    name: 'bgaming',
    title: 'BGaming',
    logoUrl: 'providers/rounded/bgaming.svg',
    url: '/bgaming',
    gamesCount: 136,
    jackpot: {
      value: 123453.45,
      currency: 'USD',
    }
  },
  {
    name: 'netent',
    title: 'NetEnt',
    logoUrl: 'providers/rounded/netent.svg',
    url: '/bgaming',
    gamesCount: 69,
    jackpot: {
      value: 123453.45,
      currency: 'USD',
    }
  },
  {
    name: 'endorphina',
    title: 'Endorphina',
    logoUrl: 'providers/rounded/endorphina.svg',
    url: '/bgaming',
    gamesCount: 136,
    jackpot: {
      value: 123453.45,
      currency: 'USD',
    }
  },
  {
    name: 'booming',
    title: 'Booming',
    logoUrl: 'providers/rounded/booming.svg',
    url: '/bgaming',
    gamesCount: 234,
    jackpot: {
      value: 123453.45,
      currency: 'USD',
    }
  },
  {
    name: 'egt',
    title: 'EGT',
    logoUrl: 'providers/rounded/egt.svg',
    url: '/egt',
    gamesCount: 216,
    jackpot: {
      value: 123453.45,
      currency: 'USD',
    }
  },
  {
    name: 'evolution',
    title: 'Evolution',
    logoUrl: 'providers/rounded/evolution.svg',
    url: '/evolution',
    gamesCount: 134,
    jackpot: {
      value: 123453.45,
      currency: 'USD',
    }
  },
  {
    name: 'gameart',
    title: 'GameArt',
    logoUrl: 'providers/rounded/gameart.svg',
    url: '/gameart',
    gamesCount: 93,
    jackpot: {
      value: 123453.45,
      currency: 'USD',
    }
  },
  {
    name: 'gamzix',
    title: 'Gamzix',
    logoUrl: 'providers/rounded/gamzix.svg',
    url: '/gamzix',
    gamesCount: 118,
    jackpot: {
      value: 123453.45,
      currency: 'USD',
    }
  },
  {
    name: 'luckystreak',
    title: 'Luckystreak',
    logoUrl: 'providers/rounded/luckystreak.svg',
    url: '/luckystreak',
    gamesCount: 49,
    jackpot: {
      value: 123453.45,
      currency: 'USD',
    }
  },
  {
    name: 'nolimit',
    title: 'NoLimit',
    logoUrl: 'providers/rounded/nolimit.svg',
    url: '/nolimit',
    gamesCount: 141,
    jackpot: {
      value: 123453.45,
      currency: 'USD',
    }
  },
  {
    name: 'playngo',
    title: 'PlayNGo',
    logoUrl: 'providers/rounded/playngo.svg',
    url: '/playngo',
    gamesCount: 93,
    jackpot: {
      value: 123453.45,
      currency: 'USD',
    }
  },
  {
    name: 'pragmaticplay',
    title: 'Pragmatic Play',
    logoUrl: 'providers/rounded/pragmaticplay.svg',
    url: '/pragmaticplay',
    gamesCount: 293,
    jackpot: {
      value: 123453.45,
      currency: 'USD',
    }
  },
  {
    name: 'pushgaming',
    title: 'Push Gaming',
    logoUrl: 'providers/rounded/pushgaming.svg',
    url: '/pushgaming',
    gamesCount: 64,
    jackpot: {
      value: 123453.45,
      currency: 'USD',
    }
  },
  {
    name: 'redtiger',
    title: 'Red Tiger',
    logoUrl: 'providers/rounded/redtiger.svg',
    url: '/redtiger',
    gamesCount: 112,
    jackpot: {
      value: 123453.45,
      currency: 'USD',
    }
  },
  {
    name: 'thunderkick',
    title: 'Thunderkick',
    logoUrl: 'providers/rounded/thunderkick.svg',
    url: '/thunderkick',
    gamesCount: 344,
    jackpot: {
      value: 123453.45,
      currency: 'USD',
    }
  },
  {
    name: 'wazdan',
    title: 'Wazdan',
    logoUrl: 'providers/rounded/wazdan.svg',
    url: '/wazdan',
    gamesCount: 34,
    jackpot: {
      value: 123453.45,
      currency: 'USD',
    }
  },
];

const dataJackpot: Array<TProvider> = [
  {
    name: 'nolimit',
    title: 'NoLimit',
    logoUrl: 'providers/rounded/nolimit.svg',
    url: '/nolimit',
    gamesCount: 141,
    jackpot: {
      value: 123453.45,
      currency: 'USD',
    }
  },
  {
    name: 'playngo',
    title: 'PlayNGo',
    logoUrl: 'providers/rounded/playngo.svg',
    url: '/playngo',
    gamesCount: 93,
    jackpot: {
      value: 123453.45,
      currency: 'USD',
    }
  },
  {
    name: 'pragmaticplay',
    title: 'Pragmatic Play',
    logoUrl: 'providers/rounded/pragmaticplay.svg',
    url: '/pragmaticplay',
    gamesCount: 293,
    jackpot: {
      value: 123453.45,
      currency: 'USD',
    }
  },
  {
    name: 'pushgaming',
    title: 'Push Gaming',
    logoUrl: 'providers/rounded/pushgaming.svg',
    url: '/pushgaming',
    gamesCount: 64,
    jackpot: {
      value: 123453.45,
      currency: 'USD',
    }
  },
  {
    name: 'redtiger',
    title: 'Red Tiger',
    logoUrl: 'providers/rounded/redtiger.svg',
    url: '/redtiger',
    gamesCount: 112,
    jackpot: {
      value: 123453.45,
      currency: 'USD',
    }
  },
  {
    name: 'thunderkick',
    title: 'Thunderkick',
    logoUrl: 'providers/rounded/thunderkick.svg',
    url: '/thunderkick',
    gamesCount: 344,
    jackpot: {
      value: 123453.45,
      currency: 'USD',
    }
  },
  {
    name: 'wazdan',
    title: 'Wazdan',
    logoUrl: 'providers/rounded/wazdan.svg',
    url: '/wazdan',
    gamesCount: 34,
    jackpot: {
      value: 123453.45,
      currency: 'USD',
    }
  },
  {
    name: 'bgaming',
    title: 'BGaming',
    logoUrl: 'providers/rounded/bgaming.svg',
    url: '/bgaming',
    gamesCount: 136,
    jackpot: {
      value: 123453.45,
      currency: 'USD',
    }
  },
  {
    name: 'netent',
    title: 'NetEnt',
    logoUrl: 'providers/rounded/netent.svg',
    url: '/bgaming',
    gamesCount: 69,
    jackpot: {
      value: 123453.45,
      currency: 'USD',
    }
  },
  {
    name: 'endorphina',
    title: 'Endorphina',
    logoUrl: 'providers/rounded/endorphina.svg',
    url: '/bgaming',
    gamesCount: 136,
    jackpot: {
      value: 123453.45,
      currency: 'USD',
    }
  },
  {
    name: 'booming',
    title: 'Booming',
    logoUrl: 'providers/rounded/booming.svg',
    url: '/bgaming',
    gamesCount: 234,
    jackpot: {
      value: 123453.45,
      currency: 'USD',
    }
  },
  {
    name: 'egt',
    title: 'EGT',
    logoUrl: 'providers/rounded/egt.svg',
    url: '/egt',
    gamesCount: 216,
    jackpot: {
      value: 123453.45,
      currency: 'USD',
    }
  },
  {
    name: 'evolution',
    title: 'Evolution',
    logoUrl: 'providers/rounded/evolution.svg',
    url: '/evolution',
    gamesCount: 134,
    jackpot: {
      value: 123453.45,
      currency: 'USD',
    }
  },
  {
    name: 'gameart',
    title: 'GameArt',
    logoUrl: 'providers/rounded/gameart.svg',
    url: '/gameart',
    gamesCount: 93,
    jackpot: {
      value: 123453.45,
      currency: 'USD',
    }
  },
  {
    name: 'gamzix',
    title: 'Gamzix',
    logoUrl: 'providers/rounded/gamzix.svg',
    url: '/gamzix',
    gamesCount: 118,
    jackpot: {
      value: 123453.45,
      currency: 'USD',
    }
  },
  {
    name: 'luckystreak',
    title: 'Luckystreak',
    logoUrl: 'providers/rounded/luckystreak.svg',
    url: '/luckystreak',
    gamesCount: 49,
    jackpot: {
      value: 123453.45,
      currency: 'USD',
    }
  },
  {
    name: 'nolimit',
    title: 'NoLimit',
    logoUrl: 'providers/rounded/nolimit.svg',
    url: '/nolimit',
    gamesCount: 141,
    jackpot: {
      value: 123453.45,
      currency: 'USD',
    }
  },
];

export const ProvidersFilter: FC = () => {
  const [state, setState] = useState({
    unit: 'providers',
    value: '',
  });

  const handleProvider = (id: string) => {
    console.log(id);
    setState(prev => ({...prev, unit: id}));
  };

  const handleChange = (value: string) => {
    setState(prev => ({...prev, value}));
  };

  const navs: Array<TTabs> = [
    {name: 'providers', active: state.unit === 'providers', text: 'Providers', onClick: handleProvider},
    {name: 'jackpot', active: state.unit === 'jackpot', text: 'Jackpot', onClick: handleProvider},
  ];

  return (
    <Wrapper>
      <TabsWrapper>
        <TabsPanel data={navs} />
      </TabsWrapper>

      <SearchBar>
        <Input
          icon="icon-search"
          placeholder="Start typing..."
          value={state.value}
          onChange={handleChange}
          deleteButton />

        <FilterWrapper>
          <Button theme="muted" size="small" type="icon">
            <i className="icon-filter-alt" />
          </Button>
        </FilterWrapper>
      </SearchBar>

      <TableWrapper>
        {state.unit === 'providers' && <TableProviders data={dataDefault} />}
        {state.unit === 'jackpot' && <TableProviders data={dataJackpot} jackpot />}
      </TableWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 28px 0;
`;

const TableWrapper = styled.div`
  
`;

const FilterWrapper = styled.div`
  padding-left: 10px;
`;

const TabsWrapper = styled.div`
  padding: 0 24px 24px;
`;

const SearchBar = styled.div`
  padding: 0 24px 24px;
  display: flex;
`;

const List = styled.div`
  
`;
