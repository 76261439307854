import {FC} from "react";
import styled from "styled-components";
import {NavLink, useLocation} from "react-router-dom";


export const NavbarRightPanel: FC = () => {
  const location = useLocation();
  let { pathname } = location;
  pathname = pathname.replace('/sign-in', '');
  pathname = pathname.replace('/sign-up', '');

  const signInLink =
    pathname === '/'
      ? '/sign-in'
      : `${pathname}/sign-in`;

  const signUpLink =
    pathname === '/'
      ? '/sign-up'
      : `${pathname}/sign-up`;

  return (
    <Wrapper>
      <BigButton to={signUpLink}>
        <TextButton>
          <BigButtonIcon className="icon-user" /> Sign up
        </TextButton>
      </BigButton>
      <LoginButton to={signInLink}>
        <i className="icon-login" />
      </LoginButton>
    </Wrapper>
  )
}

const LoginButton = styled(NavLink)`
  border: 0;
  cursor: pointer;
  background: none;
  font-size: 20px;
  color: ${({theme}) => theme.colors.accent.yellow.default};
  transition: color 0.04s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-decoration: none;
  
  ${({ theme }) => {
    if (theme.screen?.size === 'mob' && theme.screen.touch) return `
      width: 48px;
      height: 60px;
      padding-right: 16px;
      margin-right: 0;
    `;
    
    if (theme.screen?.size === 'mob') return `
      width: 48px;
      height: 64px;
      padding-right: 16px;
      margin-right: 0;
    `;
    
    return `
      width: 52px;
      height: 64px;
      padding-right: 8px;
      margin-right: 8px;
    `;
  }}
  
  &:hover {
    color: ${({theme}) => theme.colors.accent.yellow.hover};
  }
  
  &:active {
    color: ${({theme}) => theme.colors.accent.yellow.active};
  }
`

const BigButtonIcon = styled.i`
  font-size: 20px;
  margin-right: 8px;
`

const BigButton = styled(NavLink)`
  display: flex;
  align-items: center;
  text-decoration: none;
    
  border: 0;
  cursor: pointer;
  position: relative;
  font-family: ${({theme}) => theme.fonts.bold.family};
  font-weight: ${({theme}) => theme.fonts.bold.weight};
  background: ${({theme}) => theme.colors.accent.gradient.default};
  color: ${({theme}) => theme.colors.secondary.dark.six};
  letter-spacing: 0.04em;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 24px;

  ${({ theme }) => {
    if (theme.screen?.size === 'mob' && theme.screen.touch) return `
      height: 60px;
      padding: 0 16px 0 16px;
    `;

    return `
      height: 64px;
      padding: 0 24px 0 20px;
    `;
  }}
  
  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${({theme}) => theme.colors.accent.gradient.hover};
    opacity: 0;
    transition: opacity 0.04s ease-in-out;
  }
  
  &:hover {
    color: ${({theme}) => theme.colors.secondary.dark.six};
    &::after {
      opacity: 1;
    }
  }
  
  &:active {
    color: ${({theme}) => theme.colors.secondary.dark.six};
    &::after {
      background: ${({theme}) => theme.colors.accent.gradient.active};
      opacity: 1;
    }
  }
  
  span {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
  }
`

const TextButton = styled.span`
  
`

const Wrapper = styled.div`
  display: flex;

  ${({ theme }) => {
    if (theme.screen?.size === 'mob') return `
      padding-right: 0;
    `;

    return `
      padding-right: 24px;
    `;
  }}
`
