import {createContext, FC, ReactNode, useEffect, useState} from "react";
import {DefaultTheme, ThemeProvider} from "styled-components";
import {Resolutions, TScreen} from "./styled";

interface IThemeContext {
  screen: TScreen;
  resolutions: Resolutions;
}

const theme: DefaultTheme = {
  screen: {
    touch: 'ontouchstart' in window || navigator.maxTouchPoints > 0,
    breakpoint: 'mob_md',
    size: 'mob',
  },
  colors: {
    accent: {
      light: {
        default: '#17BC8F',
        hover: '#138868',
        active: '#17BC8F',
        disabled: '#3C7162',
      },
      yellow: {
        default: '#FFDE6A',
        hover: '#E1BE46',
        active: '#EFCD58',
        disabled: '#847953',
      },
      red: {
        default: '#DC5248',
        hover: '#B9423A',
        active: '#F2645A',
        disabled: '#8D5652',
      },
      dark: {
        default: '#01F7B4',
        hover: '#25C498',
        active: '#01F7B4',
        disabled: '#3C7162',
      },
      gradient: {
        default: 'linear-gradient(90deg, #00AEC4 0%, #03D192 100%)',
        hover: 'linear-gradient(90deg, #008596 0%, #00986A 100%)',
        active: 'linear-gradient(90deg, #009DB0 0%, #01AD79 100%)',
        disabled: '',
      }
    },
    secondary: {
      dark: {
        one: '#161919',
        two: '#222525',
        three: '#2D3131',
        four: '#494F4F',
        five: '#868A8A',
        six: '#DFE3E3',
      },
      light: {
        one: '#F0EFF1',
        two: '#DADEE0',
        three: '#A4B9BC',
        four: '#7E8E90',
        five: '#5B676A',
        six: '#4B5457',
      }
    },
    system: {
      red: '#DC5248',
      salmon: '#FD764C',
      indired: '#FF6270',
      orange: '#FAA30C',
      yellow: '#FFDE6A',
      lime: '#B8D086',
      cian: '#70CF98',
      wave: '#94BCAC',
      blue: '#2984FF',
      sea: '#43A1FE',
      darkblue: '#725DFF',
      purple: '#D458FF',
      green: '#95B64F'
    },
    background: {
      page: '#141616',
      dark: '#1D1D1F',
    }
  },
  fonts: {
    regular: {
      family: "'Open Sans', sans-serif",
      weight: 400,
    },
    bold: {
      family: "'Open Sans Condensed', sans-serif",
      weight: 700,
    },
    header: {
      // family: "gilroybold, sans-serif",
      family: "Gilroy, sans-serif",
      weight: 700,
    }
  },
  resolutions: {
    mob_md: {
      rightBar: 0,
      leftBar: 260,
    },
    mob_lg: {
      rightBar: 0,
      leftBar: 260,
    },
    tab_sm: {
      rightBar: 0,
      leftBar: 260,
    },
    tab_md: {
      rightBar: 360,
      leftBar: 260,
    },
    tab_lg: {
      leftBar: 260,
      rightBar: 400,
    },
    desc_sm: {
      leftBar: 260,
      rightBar: 400,
    },
    desc_md: {
      leftBar: 260,
      rightBar: 400,
    },
    desc_lg: {
      leftBar: 260,
      rightBar: 400,
    },
  }
}

export const ThemeContext = createContext<Partial<IThemeContext>>({});
export const ThemeSetup: FC<{ children: ReactNode }> = ({children}) => {
  const [state, setState] = useState(theme);
  const [init, setInit] = useState(false);
  const touch = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

  const checkScreen = () => {
    const width = window.innerWidth;
    let type: TScreen = {
      breakpoint: 'mob_md',
      size: 'mob',
      touch,
    }

    if (width >= 1810) {
      type = { breakpoint: 'desc_lg', size: 'desc', touch }
    } else if (width > 1628) {
      type = { breakpoint: 'desc_md', size: 'desc', touch }
    } else if (width > 1440) {
      type = { breakpoint: 'desc_sm', size: 'desc', touch }
    } else if (width > 1152) {
      type = { breakpoint: 'tab_lg', size: 'tab', touch }
    } else if (width > 1024) {
      type = { breakpoint: 'tab_md', size: 'tab', touch }
    } else if (width > 640) {
      type = { breakpoint: 'tab_sm', size: 'tab', touch }
    } else if (width > 480) {
      type = { breakpoint: 'mob_lg', size: 'mob', touch }
    }

    if (theme.screen.breakpoint !== type.breakpoint) {
      theme.screen = type;
      setState(() => ({...theme}))
    }

    setInit(true)
  }

  useEffect(() => {
    checkScreen();
    window.addEventListener('resize', () => checkScreen());
    return () => window.removeEventListener('resize', checkScreen);
  }, [])

  if (!init) return null;

  return (
    <ThemeContext.Provider value={{
      screen: state.screen,
      resolutions: state.resolutions,
    }}>
      <ThemeProvider theme={ state }>
        { children }
      </ThemeProvider>
    </ThemeContext.Provider>
  )
}