import {FC} from "react";
import styled from "styled-components";
import {TabsDefault} from "../../components/Tabs/TabsDefault";
import {TTableTopWins} from "../../types/TTableTopWins";
import {Link} from "react-router-dom";
import {TableScroll} from "../../components/Scroll/TableScroll";


const data: Array<TTableTopWins> = [
  {
    gameTitle: 'Book of Ancients Lorem ipsum',
    gameImage: 'cards/games/bookofancients.png',
    gameLink: '/bookofacients',
    userName: 'John Doe',
    time: '1234567890',
    bet: {currency: 'EUR', value: 200.50},
    win: {currency: 'EUR', value: 435664.23},
    payout: {currency: 'EUR', value: 2356.23},
    multiplier: 120,
  },
  {
    gameTitle: 'Buffalo Trail',
    gameImage: 'cards/games/buffalotrail.png',
    gameLink: '/buffalotrail',
    userName: 'John Doe',
    time: '1234567890',
    bet: {currency: 'EUR', value: 200.50},
    win: {currency: 'EUR', value: 435664.23},
    payout: {currency: 'EUR', value: 2356.23},
    multiplier: 23,
  },
  {
    gameTitle: 'Max Miner',
    gameImage: 'cards/games/maxminer.png',
    gameLink: '/maxminer',
    userName: 'John Doe',
    time: '1234567890',
    bet: {currency: 'EUR', value: 200.50},
    win: {currency: 'EUR', value: 435664.23},
    payout: {currency: 'EUR', value: 2356.23},
    multiplier: 216,
  },
  {
    gameTitle: 'Max Miner',
    gameImage: 'cards/games/maxminer.png',
    gameLink: '/maxminer',
    userName: 'John Doe',
    time: '1234567890',
    bet: {currency: 'EUR', value: 200.50},
    win: {currency: 'EUR', value: 435664.23},
    payout: {currency: 'EUR', value: 2356.23},
    multiplier: 109,
  },
  {
    gameTitle: 'Withch Treasures',
    gameImage: 'cards/games/witchtreasures.png',
    gameLink: '/maxminer',
    userName: 'John Doe',
    time: '1234567890',
    bet: {currency: 'EUR', value: 200.50},
    win: {currency: 'EUR', value: 435664.23},
    payout: {currency: 'EUR', value: 2356.23},
    multiplier: 109,
  },
  {
    gameTitle: 'Sea Secret',
    gameImage: 'cards/games/seasecret.png',
    gameLink: '/seasecret',
    userName: 'John Doe',
    time: '1234567890',
    bet: {currency: 'EUR', value: 200.50},
    win: {currency: 'EUR', value: 435664.23},
    payout: {currency: 'EUR', value: 2356.23},
    multiplier: 109,
  },
  {
    gameTitle: 'Buffalo Trail',
    gameImage: 'cards/games/buffalotrail.png',
    gameLink: '/buffalotrail',
    userName: 'John Doe',
    time: '1234567890',
    bet: {currency: 'EUR', value: 200.50},
    win: {currency: 'EUR', value: 435664.23},
    payout: {currency: 'EUR', value: 2356.23},
    multiplier: 23,
  },
];

export const TopWinsTable: FC = () => {
  return (
    <Wrapper>
      <Nav>
        <TabsDefault />
      </Nav>
      <TableScroll>
        <Table>
          <TableHeader>
            <CellHeader $isHeader>Game / Player</CellHeader>
            <CellTime $isHeader>Time</CellTime>
            <CellTime $isHeader>Bet</CellTime>
            <CellTime $isHeader>Multiplier</CellTime>
            <CellValue $isHeader>Winnings</CellValue>
            <CellValue $isHeader>Payout</CellValue>
          </TableHeader>

          {data.map((item, i) => <Cell key={i} >
            <CellHeader>
              <StyledLink to={item.gameLink}>
                <ImageGame>
                  <Image src={item.gameImage} alt={item.gameTitle} />
                </ImageGame>
                <Title>
                  <TitleText>{item.gameTitle}</TitleText>
                  <User>{item.userName}</User>
                </Title>
              </StyledLink>
            </CellHeader>
            <CellTime>13:47</CellTime>
            <CellTime>x699</CellTime>
            <CellTime>206 $</CellTime>
            <CellValue>99 996 650.99 $</CellValue>
            <CellValue>99 996 650.99 $</CellValue>
          </Cell>)}
        </Table>
      </TableScroll>
    </Wrapper>
  );
};


const StyledLink = styled(Link)`
  max-width: inherit;
  display: flex;
  text-decoration: none;
  color: ${({theme}) => theme.colors.secondary.dark.six};
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
  
  ${({ theme }) => {
    if (theme?.screen?.size === 'mob' && theme?.screen?.touch) return `
      padding-left: 52px;
    `;
    return `
      padding-left: 72px;
      padding-right: 12px;
    `;
  }}
`;

const TitleText = styled.div`
  font-family: ${({theme}) => theme.fonts.header.family};
  font-weight: ${({theme}) => theme.fonts.header.weight};
  white-space: nowrap;
  letter-spacing: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  
  ${({ theme }) => {
    if (theme?.screen?.size === 'mob' && theme?.screen?.touch) return `
      font-size: 14px;
      line-height: 18px;
    `;
    return `
      font-size: 16px;
      line-height: 20px;
    `;
  }}
`;

const User = styled.div`
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: ${({theme}) => theme.colors.accent.dark.default};

  ${({ theme }) => {
    if (theme?.screen?.size === 'mob' && theme?.screen?.touch) return `
      padding-top: 4px;
    `;
    return `
      padding-top: 8px;
    `;
  }}
`;

const ImageGame = styled.div`
  position: absolute;
  overflow: hidden;
  border-radius: 100px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  
  ${({ theme }) => {
    if (theme?.screen?.size === 'mob' && theme.screen.touch) return `
      min-width: 40px;
      width: 40px;
      height: 40px;
    `;
    
    return `
      width: 56px;
      min-width: 56px;
      height: 56px;
    `;
  }}
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;

const Table = styled.div`
  
  ${({ theme }) => {
    const { screen } = theme;
    if (screen?.size === 'mob') return `
      min-width: 780px;
    `;
    
    return `
      min-width: 966px;
    `;
  }}
  
  >div {
    &:nth-child(even) {
      background: rgba(34, 37, 37, 0.6);
    }
  }
`;

const TableHeader = styled.div`
  display: flex;
  
  >div {
    &:last-child {
      padding-right: 0;
      text-align: right;
      justify-content: flex-end;
    }
  }

  ${({ theme }) => {
    const { screen } = theme;
    if (screen?.breakpoint === 'desc_sm' || screen?.size === 'tab') return `
      padding: 0 32px;
    `;
    
    if (screen?.size === 'mob') return `
      padding: 0 16px;
    `;

    return `
      padding: 0 40px;
    `;
  }}
`;

const Cell = styled.div`
  display: flex;
  
  >div {
    &:last-child {
      padding-right: 0;
      text-align: right;
      justify-content: flex-end;
    }
  }

  ${({ theme }) => {
    const { screen } = theme;
    if (screen?.breakpoint === 'desc_sm' || screen?.size === 'tab') return `
      padding: 0 32px;
      min-height: 88px;
    `;

    if (screen?.size === 'mob') return `
      padding: 0 16px;
      min-height: 72px;
    `;

    return `
      padding: 0 40px;
      min-height: 88px;
    `;
  }}
`;

const CellHeader = styled.div<{ $isHeader?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  
  ${({ theme, $isHeader }) => {
    if (!$isHeader) {

      if (theme.screen?.size === 'mob' && theme.screen.touch) return `
        font-family: ${theme.fonts.bold.family};
        font-weight: ${theme.fonts.bold.weight};
        font-size: 12px;
        line-height: 20px;
        color: ${theme.colors.secondary.dark.four};
        padding: 16px 12px 16px 0;
        min-width: 156px;
        max-width: 156px;
      `;
      
      return `
        font-family: ${theme.fonts.bold.family};
        font-weight: ${theme.fonts.bold.weight};
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.04em;
        color: ${theme.colors.secondary.dark.five};
        
        padding: 16px 12px 16px 0;
        min-width: 292px;
        max-width: 292px;
      `;
    }
    
    if (theme.screen?.size === 'mob' && theme.screen.touch) return `
      font-family: ${theme.fonts.header.family};
      font-weight: ${theme.fonts.header.weight};
      font-size: 12px;
      line-height: 16px;
      color: ${theme.colors.secondary.dark.four};
      padding: 12px 12px 12px 0;
      min-width: 156px;
      max-width: 156px;
    `;
    
    return `
      font-family: ${theme.fonts.header.family};
      font-weight: ${theme.fonts.header.weight};
      font-size: 14px;
      line-height: 18px;
      color: ${theme.colors.secondary.dark.four};
      
      padding: 16px 12px 16px 0;
      min-width: 292px;
      max-width: 292px;
    `;
  }}
`;

const CellTime = styled.div<{ $isHeader?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  
  ${({ theme, $isHeader }) => {
    if (!$isHeader) {
      if (theme.screen?.size === 'mob' && theme.screen.touch) return `
        font-family: ${theme.fonts.bold.family};
        font-weight: ${theme.fonts.bold.weight};
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.04em;
        color: ${theme.colors.secondary.dark.five};
        padding: 16px 12px;
        min-width: 112px;
        max-width: 112px;
      `;
      
      return `
        font-family: ${theme.fonts.bold.family};
        font-weight: ${theme.fonts.bold.weight};
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.04em;
        color: ${theme.colors.secondary.dark.five};
        padding: 16px 12px;
        min-width: 100px;
        max-width: 100px;
      `;
    }

    if (theme.screen?.size === 'mob' && theme.screen.touch) return `
      font-family: ${theme.fonts.header.family};
      font-weight: ${theme.fonts.header.weight};
      font-size: 12px;
      line-height: 16px;
      color: ${theme.colors.secondary.dark.four};
      padding: 12px;
      min-width: 112px;
      max-width: 112px;
    `;
  
    return `
      font-family: ${theme.fonts.header.family};
      font-weight: ${theme.fonts.header.weight};
      font-size: 14px;
      line-height: 18px;
      color: ${theme.colors.secondary.dark.four};
      padding: 16px 12px;
      min-width: 100px;
      max-width: 100px;
    `;
  }}
`;

const CellValue = styled.div<{ $isHeader?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  padding: 16px 12px;
  
  ${({ theme, $isHeader }) => {
    if (!$isHeader) {
      if (theme.screen?.size === 'mob' && theme.screen.touch) return `
        font-family: ${theme.fonts.bold.family};
        font-weight: ${theme.fonts.bold.weight};
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.04em;
        color: ${theme.colors.secondary.dark.five};
        padding: 16px 12px;
      `;
      
      return `
        font-family: ${theme.fonts.bold.family};
        font-weight: ${theme.fonts.bold.weight};
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.04em;
        color: ${theme.colors.secondary.dark.five};
        padding: 16px 12px;
      `;
    }

    if (theme.screen?.size === 'mob' && theme.screen.touch) return `
      font-family: ${theme.fonts.header.family};
      font-weight: ${theme.fonts.header.weight};
      font-size: 12px;
      line-height: 16px;
      color: ${theme.colors.secondary.dark.four};
      padding: 12px;
    `;
  
    return `
      font-family: ${theme.fonts.header.family};
      font-weight: ${theme.fonts.header.weight};
      font-size: 14px;
      line-height: 18px;
      color: ${theme.colors.secondary.dark.four};
      padding: 16px 12px;
    `;
  }}
`;

const Nav = styled.div`
  border-bottom: 1px solid ${({theme}) => theme.colors.secondary.dark.three};

  ${({ theme }) => {
    const { screen } = theme;
    if (screen?.breakpoint === 'desc_sm' || screen?.size === 'tab') return `
      padding: 0 32px;
    `;
    
    if (screen?.size === 'mob') return `
      padding: 0 16px;
    `;

    return `
      padding: 0 40px;
    `;
  }}
`;

const Wrapper = styled.div`
  overflow: hidden;
  
  ${({ theme }) => {
    if (theme.screen?.size === 'mob') return `
      padding: 0 0 36px;
    `;
    
    return `
      padding: 24px 0 36px;
    `;
  }}
`;
