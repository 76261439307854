import {FC, Fragment, useContext, useEffect, useRef, useState} from "react";
import {createPortal} from "react-dom";
import {ModalContext} from "../providers/ModalProvider";
import styled from "styled-components";
import {ThemeContext} from "../ThemeSetup";


export const Modal: FC = () => {
  const { screen, resolutions } = useContext(ThemeContext);
  const { bodyRef, closerRef, position, isClosed } = useBottomSheet();
  const { isModal, modalType, onModalClose } = useContext(ModalContext);
  const [state, setState] = useState({
    modal: false,
    removed: false,
  });


  useEffect(() => {
    isClosed && onModalClose && onModalClose();
  }, [isClosed]);

  const containerRef = useRef(null);
  const contentRef = useRef(null);
  useEffect(() => {
    if (!isModal && state.modal) {
      let t = setTimeout(() => {
        clearTimeout(t);
        setState(prev => {

          return {...prev, removed: !prev.modal };
        });
      }, 1000);
    }

    if (isModal) {
      setState(prev => {
        return {...prev, modal: true, removed: false }
      });
    } else {

      // @ts-ignore
      containerRef.current.scroll({
        top: 0,
        behavior: "smooth"
      });

      // @ts-ignore
      contentRef.current.scroll({
        top: 0,
        behavior: "smooth"
      });

      setState(prev => ({...prev, modal: false }));
    }
  }, [isModal]);

  const style: any = {};
  if (screen?.size === 'mob' && screen.touch) {
    style.transition = (position === 0 || isClosed) ? 'top 0.2s ease-in-out' : 'none';
    style.top = !isModal ? '100%' : position;
  }

  return <Fragment>
    {createPortal(
      <Wrapper
        ref={containerRef}
        $isModal={state.modal}>

        <Container
          style={style}
          ref={bodyRef}
        >
          <Closer
            onClick={onModalClose}
            ref={closerRef} />
          <Body
            ref={contentRef}
            $isModal={!!isModal}
            id="Body">
            <MainContainer>
              Top Top Top <br/>
              Modal Sign up <br/> Modal Sign up <br/> Modal Sign up <br/> Modal Sign up <br/> Modal Sign up <br/>
              <input type="text"/>
              Modal Sign up <br/> Modal Sign up <br/> Modal Sign up <br/> Modal Sign up <br/> Modal Sign up <br/>
              Modal Sign up <br/> Modal Sign up <br/> Modal Sign up <br/> Modal Sign up <br/> Modal Sign up <br/>
              <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/>
              {/*<br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/>*/}
              Modal Sign up <br/> Modal Sign up <br/> Modal Sign up <br/> Modal Sign up <br/> Modal Sign up <br/>
              Modal Sign up <br/> Modal Sign up <br/> Modal Sign up <br/> Modal Sign up <br/> Modal Sign up <br/>
              Modal Sign up <br/> Modal Sign up <br/> Modal Sign up <br/> Modal Sign up <br/> Modal Sign up <br/>
              End
            </MainContainer>
          </Body>
        </Container>

        <Overlay
          $isModal={state.modal} />

      </Wrapper>, document.body
    )}
  </Fragment>
};

const Closer = styled.div`
  position: relative;
  height: 28px;
  min-height: 28px;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 56px;
    height: 4px;
    border-radius: 5px;
    background-color: ${({theme}) => theme.colors.secondary.light.three};
  }
  
  ${({ theme }) => {
    const { screen } = theme;
    const { size, touch } = screen;
    if (size === 'mob' && touch) return ``;

    return `
      display: none;  
    `;
  }}
`;

const MainContainer = styled.div`
  
`;

const Container = styled.div`
  position: relative;
  z-index: 100;
  transform: matrix(1, 0, 0, 1, 0, 0);
  
  ${({ theme }) => {
    const { screen } = theme;
    const { size, touch } = screen;

    if (size === 'mob' && touch) return `
      position: relative;
      background-color: ${theme.colors.secondary.light.one};
      overflow: hidden;
      border-radius: 32px 32px 0 0;
      box-shadow: 1px -20px 52px -8px rgba(0, 0, 0, 0.4);
    `;

    if (size === 'tab') return `
      display: flex;
      justify-content: center;
      padding: 72px 24px;
    `;

    return `
      display: flex;
      justify-content: center;
      padding: 80px 24px;
    `;
  }}
`;

const Body = styled.div<{ $isModal: boolean }>`
  ${({ theme, $isModal }) => {
    const { screen } = theme;
    const { size, touch } = screen;
    
    if ($isModal) return `
      opacity: 1;
      transition: opacity 0.2s ease-in-out;
    `;

    return `
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
    `;
  }}
  
  ${({ theme, $isModal }) => {
    const { screen } = theme;
    const { size, touch } = screen;
    if (size === 'mob' && touch) return ``;
    
    if ($isModal) return `
      position: relative;
      transform: scale(1);
      opacity: 1;
      transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
    `;

    return `
      position: relative;
      transform: scale(0.9);
      opacity: 0;
      transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
    `;
  }}
  
  ${({ theme, $isModal }) => {
    const { screen } = theme;
    const { size, touch } = screen;

    if (size === 'mob' && touch) return `
      height: calc(100% - 28px);
      overflow: auto;
    `;
    
    return `
      width: 100%;
      max-width: 400px;
      border-radius: 32px;
      background-color: ${theme.colors.secondary.light.one};
    `;
  }}
`;

const Wrapper = styled.div<{ $isModal: boolean }>`
  position: fixed;
  z-index: 3000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;  
  
  ${({ theme }) => {
    const { screen } = theme;
    const { size, touch } = screen;
    
    if (size === 'mob' && touch) return `
      padding-top: 60px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    `;
  
    return `
      overflow: auto;
    `;
  }}


  ${({ $isModal }) => {
    if ($isModal) return ``;
  
    return `
      pointer-events: none;
      opacity: 0;
    `;
  }}
`;

const Overlay = styled.div<{ $isModal: boolean }>`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(22, 25, 25, 0.6);
  backdrop-filter: blur(8px);
  transition: all 0.12s ease-in-out;

  ${({ $isModal }) => {
    if ($isModal) return `
      pointer-events: all;
      opacity: 1;
    `;

    return `
      pointer-events: none;
      opacity: 0;
    `;
  }}

  ${({ theme }) => {
    const { screen } = theme;
    const { size, touch } = screen;

    if (size === 'mob' && touch) return `
      top: 60px;
    `;

    return `
      top: 0;
    `;
  }}
`;


// const Closer = styled.div<{ $isModal: boolean }>`
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//
//   ${({ $isModal }) => {
//     if ($isModal) return ``;
//
//     return `
//       pointer-events: none;
//     `;
//   }}
// `;
//
// const ModalMain = styled.div`
//   position: relative;
//   z-index: 100;
//   border-radius: 32px;
//   width: 100%;
//   max-width: 400px;
//   background-color: ${({ theme }) => theme.colors.secondary.light.one};
// `;
//






const useBottomSheet = () => {
  const bodyRef = useRef<HTMLDivElement>(null);
  const closerRef = useRef<HTMLDivElement>(null);
  let pressed = false;
  let start = 0;
  let emmitPosition = 0;

  const [state, setState] = useState({
    position: 0,
    isClosed: false,
    isPreClosed: false,
  });

  // Mouse
  const handleMouseDrag = (e: MouseEvent) => {
    if (!pressed) return;
    let _position = e.screenY;
    let position = _position > start ? _position - start : 0;

    if (_position !== emmitPosition) {
      let isPreClosed = _position > emmitPosition;
      setState(prev => ({ ...prev, position, isPreClosed }));
      emmitPosition = _position;
    }
  };

  const handleMouseStart = (e: MouseEvent) => {
    pressed = true;
    start = e.screenY;

    setState(prev => ({ ...prev, isClosed: false }));
  };

  const handleMouseStop = (e: MouseEvent) => {
    pressed = false;
    setState(prev => {
      start = 0;
      return { ...prev, position: 0, isClosed: prev.isPreClosed }
    });
  };


  // Touch
  const handleTouchDrag = (e: any) => {
    if (!pressed) return;
    let _position = Math.round(e.touches[0].screenY);
    let position = _position > start ? _position - start : 0;

    if (_position !== emmitPosition) {
      let isPreClosed = _position > emmitPosition;

      setState(prev => ({ ...prev, position, isPreClosed }));
      emmitPosition = _position;
    }
  }

  const handleTouchStart = (e: any) => {
    pressed = true;
    start = e.touches[0].screenY;
    setState(prev => ({ ...prev, isClosed: false }));
  };

  const handleTouchStop = (e: any) => {
    pressed = false;
    setState(prev => {
      start = 0;

      return { ...prev, position: 0, isClosed: prev.isPreClosed }
    });
  };

  useEffect(() => {
    const closer = closerRef.current;
    const body = bodyRef.current;
    if (!closer || !body) return;

    body.addEventListener("mousemove", handleMouseDrag);
    closer.addEventListener("mousedown", handleMouseStart);
    closer.addEventListener("mouseup", handleMouseStop);
    body.addEventListener("mouseleave", handleMouseStop);

    body.addEventListener("touchmove", handleTouchDrag);
    closer.addEventListener("touchstart", handleTouchStart);
    closer.addEventListener("touchend", handleTouchStop);

    return () => {
      body.removeEventListener("mousemove", handleMouseDrag);
      closer.removeEventListener("mousedown", handleMouseStart);
      closer.removeEventListener("mouseup", handleMouseStop);
      body.removeEventListener("mouseleave", handleMouseStop);

      body.removeEventListener("touchmove", handleTouchDrag);
      closer.removeEventListener("touchstart", handleTouchStart);
      closer.removeEventListener("touchend", handleTouchStop);
    }
  }, []);

  return {
    bodyRef, closerRef, position: state.position, isClosed: state.isClosed
  }
}
