import {FC} from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";

export const VerifyButton: FC = () => {
  return (
    <Wrapper to="/verify">
      <Icon>
        <Indicator className="indicator" />
        <i className="icon-user" />
      </Icon>
      <Text>
        Need verification
      </Text>
      <Arrow className="icon-chevron-sm-right" />
    </Wrapper>
  );
}

const Wrapper = styled(Link)`
  display: flex;
  width: 100%;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.secondary.dark.six};
  background-color: ${({ theme }) => theme.colors.accent.red.default};
  text-decoration: none;
  padding: 24px 16px 15px 20px;
  
  &:hover {
    background-color: ${({ theme }) => theme.colors.accent.red.hover};
    color: ${({theme}) => theme.colors.secondary.dark.six};
    .indicator {
      border: 4px solid ${({ theme }) => theme.colors.accent.red.hover};
    }
  }
`

const Icon = styled.div`
  width: 32px;
  height: 32px;
  font-size: 20px;
  line-height: 24px;
  position: relative;
`

const Text = styled.div`
  width: 100%;
`

const Indicator = styled.div`
  position: absolute;
  top: -10px;
  right: -4px;
  width: 20px;
  height: 20px;
  background-color: ${({ theme }) => theme.colors.secondary.dark.six};
  border-radius: 50%;
  border: 4px solid ${({ theme }) => theme.colors.accent.red.default};
`

const Arrow = styled.i`
  height: 24px;
  font-size: 20px;
`