import {Component, FC, ReactNode} from "react";
import styled from "styled-components";
import { Scrollbar } from 'react-scrollbars-custom';


type TScrollBox = {
  children: ReactNode;
}

export const TableScroll: FC<TScrollBox> = ({children}) => {
  return (
    <Wrapper>
      <Scrollbar
        renderer={(props) => {
          const { elementRef, ...restProps } = props;
          return <div {...restProps} ref={elementRef} className="кастомизировать можно все что хош" />;
        }}
        wrapperProps={{
          renderer: (props) => {
            const { elementRef, ...restProps } = props;
            return <div {...restProps} ref={elementRef} className="если ты разраб" />;
          },
        }}
        scrollerProps={{
          renderer: (props) => {
            const { elementRef, ...restProps } = props;
            return <div {...restProps} ref={elementRef} className="либо ты рукожоп" />;
          },
        }}
        contentProps={{
          renderer: (props) => {
            const { elementRef, ...restProps } = props;
            return <div {...restProps} ref={elementRef} className="body (зы) Типа пасхалка блэт" />;
          },
        }}
        trackXProps={{
          renderer: (props) => {
            const { elementRef, ...restProps } = props;
            return <div {...restProps} ref={elementRef} className="bar-x" />;
          },
        }}
        thumbXProps={{
          renderer: (props) => {
            const { elementRef, ...restProps } = props;
            return <div {...restProps} ref={elementRef} className="indicator-x" />;
          },
        }}
        trackYProps={{
          renderer: (props) => {
            const { elementRef, ...restProps } = props;
            return <div {...restProps} ref={elementRef} className="bar-y" />;
          },
        }}
        thumbYProps={{
          renderer: (props) => {
            const { elementRef, ...restProps } = props;
            return <div {...restProps} ref={elementRef} className="indicator-y" />;
          },
        }}

        translateContentSizeYToHolder
        disableTracksWidthCompensation
        style={{
          width: '100%',
      }} >
        {children}
      </Scrollbar>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .indicator-x {
    background: ${({theme}) => theme.colors.secondary.dark.three} !important;
  }
  
  .body {
    display: block!important;
  }
  
  ${({theme}) => {
    if (theme.screen?.size === 'mob') return `
      .bar-x {
        background: ${theme.colors.secondary.dark.two} !important;
        height: 8px!important;
        bottom: -24px!important;
        left: 16px!important;
        right: 16px!important;
        width: calc(100% - 32px)!important;
      }
    `;
    
    return `
      .bar-x {
        background: ${theme.colors.secondary.dark.two} !important;
        height: 8px!important;
        bottom: -24px!important;
        left: 32px!important;
        right: 32px!important;
        width: calc(100% - 64px)!important;
      }
    `;
  }}
`;

