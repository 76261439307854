import {FC} from "react";
import styled from "styled-components";


const data: Array<{ name: string, image: string }> = [
  {name: 'visa', image: 'payments/visa.svg'},
  {name: 'mastercard', image: 'payments/mastercard.svg'},
  {name: 'mir', image: 'payments/mir.svg'},
  {name: 'piastrix', image: 'payments/piastrix.svg'},
  {name: 'skypay', image: 'payments/skypay.svg'},
  {name: 'monetix', image: 'payments/monetix.svg'},
  {name: 'btc', image: 'payments/btc.svg'},
];

export const PaymentsBrands: FC = () => {
  return (
    <Wrapper>
      <List>
        {data.map(({ name, image }) =>
          <Item key={name}>
            <Image src={image} alt={name} />
          </Item>
        )}
      </List>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.secondary.dark.two};
  display: flex;
  justify-content: center;
  
  ${({theme}) => {
    const {screen} = theme;

    if (screen?.breakpoint === 'desc_sm' || screen?.size === 'tab') return `
      padding: 28px 32px;
    `;

    return `
      padding: 28px 40px;
    `;
  }}
`;

const List = styled.ul`
  display: flex;
  list-style: none;
  margin: 0 -16px;
`;

const Item = styled.li`
  display: flex;
  padding: 0 16px;
  min-height: 24px;
  max-height: 24px;
`;

const Image = styled.img`
  height: 24px;
`;
