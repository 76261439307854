import {FC} from "react";
import styled from "styled-components";
import {NavList} from "../Nav/NavList";
import {TNav} from "../../types/TNav";
import {Divider} from "../Divider/Divider";
import {ProfileWidget} from "../Profile/ProfileWidget";
import {NavItem} from "../Nav/NavItem";

const mainNavs: Array<TNav> = [
  {icon: 'icon-slot', text: 'Slots', link: '/slots-online'},
  {icon: 'icon-dealer', text: 'Live-casino', link: '/casino-live'},
  {icon: 'icon-dice', text: 'Table games', link: '/table-games'},
  {icon: 'icon-analitic', text: 'Instant games', link: '/instant-games'},
  {icon: 'icon-circle-star', text: 'Sport', link: '/sports-betting'},
];

const promoNavs: Array<TNav> = [
  {icon: 'icon-percent_fill', text: 'Promo', link: '/tournaments'},
  {icon: 'icon-gift_fill', text: 'Bonus', link: '/bonuses'},
  {icon: 'icon-cup_fill', text: 'VIP-club', link: '/statuses'},
];

const infoNavs: Array<TNav> = [
  {text: 'FAQ', link: '/frequently-asked-questions'},
  {text: 'Terms & Rules', link: '/terms-and-conditions'},
  {text: 'News', link: '/news'},
];

const supportNavs: Array<TNav> = [
  {icon: 'icon-telegram', text: 'Telegram', link: 'https://google.com', target: '_blank'},
  {icon: 'icon-questions', text: 'LiveChat', link: 'https://google.com', target: '_blank'},
];

const socialNavs: Array<TNav> = [
  {icon: 'icon-telegram', text: 'Telegram', link: 'https://google.com', target: '_blank'},
  {icon: 'icon-facebook', text: 'Facebook', link: 'https://google.com', target: '_blank'},
  {icon: 'icon-google', text: 'Google', link: 'https://google.com', target: '_blank'},
];

export const Burger: FC = () => {
  return (
    <Wrapper>
      <ProfileWidget />
      <Divider />
      <NavList
        data={mainNavs} box />
      <Divider />
      <NavList
        data={promoNavs} box />
      <Divider />
      <NavList
        title={'Information'}
        data={infoNavs} box />
      <Divider />
      <NavList
        title={'Support'}
        data={supportNavs} box />
      <Divider />
      <NavList
        title={'Social Links'}
        data={socialNavs} box />
      <Divider />
      <Exiter>
        <NavItem link="/logout" text="Exit" icon="icon-logout" />
      </Exiter>
    </Wrapper>
  )
}

const Exiter = styled.div`
  padding: 16px 0;
`

const Wrapper = styled.div`
  padding: 16px 0 58px;
`
