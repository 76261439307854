import {FC} from "react";
import {TJackpotProvider} from "../../types/TJackpotProvider";
import styled from "styled-components";
import {Link} from "react-router-dom";

export const JackpotProvider: FC<TJackpotProvider> = ({ name, title, url, logoUrl, gamesCount }) => {
  return (
    <Wrapper>
      <Header>
        <Image className="image" src={logoUrl} alt={title} />
      </Header>
      <Footer>
        <Values>
          <span>212 332 765.87 $</span> <span>{gamesCount}</span>
        </Values>
        <Labels>
          <span>Jackpot</span> <span>Games</span>
        </Labels>
      </Footer>
      <StyledLink to={url} />
    </Wrapper>
  );
};

const StyledLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.secondary.dark.two};
  border-radius: 16px;
  overflow: hidden;
  height: 142px;
  
  .image {
    transform: scale(1);
    transition: transform 0.12s ease;
  }
  
  &:hover {
    .image {
      transform: scale(1.12);
    }
  }
`;

const Values = styled.div`
  font-family: ${({ theme }) => theme.fonts.header.family};
  font-weight: ${({ theme }) => theme.fonts.header.weight};
  color: ${({ theme }) => theme.colors.secondary.dark.six};
  font-size: 14px;
  line-height: 16px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 3px;
  
  >span {
    &:first-child {
      color: ${({ theme }) => theme.colors.accent.yellow.default};
    }
  }
`;

const Labels = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 10px;
  line-height: 12px;
  color: ${({ theme }) => theme.colors.secondary.dark.five};
`;

const Image = styled.img`
  height: 50px;
`;

const Header = styled.div`
  height: 100%;
  background-color: ${({ theme }) => theme.colors.secondary.dark.three};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Footer = styled.div`
  height: 54px;
  padding: 12px 12px 12px;
`;
