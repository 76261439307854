import {FC, ReactNode} from "react";
import styled, {css} from "styled-components";
import {Link} from "react-router-dom";

interface IButton {
  type?: 'default' | 'icon';
  theme?: 'default' | 'gradient' | 'muted' | 'borderDark';
  size?: 'default' | 'small' | 'verySmall';
  children?: ReactNode;
  url?: string;
  onClick?: () => void;
}

export const Button: FC<IButton> = (
  {
    children,
    url,
    onClick,
    size,
    type,
    theme,
  }) => {

  if (url) return (
    <WrapperLink to={url} $theme={theme} $size={size} $type={type}>
      <Text $size={size} >{children}</Text>
    </WrapperLink>
  );

  return (
    <WrapperBtn onClick={onClick} $theme={theme} $size={size} $type={type}>
      <Text $size={size} >{children}</Text>
    </WrapperBtn>
  );
};

const sizeDefault = css`
  white-space: nowrap;
  display: inline-flex;
  padding: 12px 28px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  &::after {
    border-radius: 12px;
  }
`;

const sizeSmall = css`
  white-space: nowrap;
  display: inline-flex;
  padding: 12px 28px;
  white-space: nowrap;
  display: inline-flex;
  padding: 12px 16px;
  border-radius: 12px;
  justify-content: center;
  align-items: center;

  &::after {
    border-radius: 12px;
  }
`;

const sizeIconVerySmall = css`
  white-space: nowrap;
  width: 28px;
  height: 28px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  &::after {
    border-radius: 8px;
  }
`;

const sizeIconSmall = css`
  white-space: nowrap;
  width: 40px;
  height: 40px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;

  &::after {
    border-radius: 12px;
  }
`;

const WrapperLink = styled(Link)<{
  $size?: 'default' | 'small' | 'verySmall';
  $theme?: 'default' | 'gradient' | 'muted' | 'borderDark';
  $type?: 'default' | 'icon';
}>`
  text-decoration: none;
  
  ${({$size, $type}) => {
    if ($size === 'verySmall') {
      if ($type === 'icon') return sizeIconVerySmall;
      return sizeSmall;
    }
    
    if ($size === 'small') {
      if ($type === 'icon') return sizeIconSmall;
      return sizeSmall;
    }
    
    return sizeDefault;
  }}
  
  ${({$theme}) => {
    if ($theme === 'muted') return themeMuted;
    if ($theme === 'borderDark') return borderDark;
    return themeGradient;
  }}
`;

const WrapperBtn = styled.button<{
  $size?: 'default' | 'small' | 'verySmall';
  $theme?: 'default' | 'gradient' | 'muted' | 'borderDark';
  $type?: 'default' | 'icon';
}>`
  border: 0;
  cursor: pointer;
  
  ${({$size, $type}) => {
    if ($size === 'verySmall') {
      if ($type === 'icon') return sizeIconVerySmall;
      return sizeSmall;
    }

    if ($size === 'small') {
      if ($type === 'icon') return sizeIconSmall;
      return sizeSmall;
    }
    
    return sizeDefault;
  }}

  ${({$theme}) => {
    if ($theme === 'muted') return themeMuted;
    if ($theme === 'borderDark') return borderDark;
    return themeGradient;
  }}
`;

const Text = styled.span<{ $size?: 'default' | 'small' | 'verySmall'; }>`
  position: relative;
  z-index: 2;
  font-family: ${({theme}) => theme.fonts.bold.family};
  font-weight: ${({theme}) => theme.fonts.bold.weight};
  letter-spacing: 0.04em;
  text-transform: uppercase;
  
  ${({$size}) => {
    if ($size === 'small' || $size === 'verySmall') return `
      font-size: 14px;
      line-height: 16px;
    `;
    
    return `
      font-size: 16px;
      line-height: 24px;
    `;
  }}
`;

const themeMuted = css`
  position: relative;
  background-color: ${props => props.theme.colors.secondary.dark.three};
  color: ${({theme}) => theme.colors.secondary.dark.six};
  
  &:hover {
    color: ${({theme}) => theme.colors.secondary.dark.five};
  }
  
  &:active {
    background-color: ${props => props.theme.colors.secondary.dark.four};
    color: ${({theme}) => theme.colors.secondary.dark.five};
  }
`;

const borderDark = css`
  position: relative;
  background: none;
  color: ${({theme}) => theme.colors.accent.yellow.default};
  border: 1px solid ${({theme}) => theme.colors.accent.yellow.default};
  
  &:hover {
    color: ${({theme}) => theme.colors.accent.yellow.hover};
    border: 1px solid ${({theme}) => theme.colors.accent.yellow.hover};
  }
  
  &:active {
    color: ${({theme}) => theme.colors.accent.yellow.active};
    border: 1px solid ${({theme}) => theme.colors.accent.yellow.active};
  }
`;

const themeGradient = css`
  position: relative;
  background: ${props => props.theme.colors.accent.gradient.default};
  color: ${({theme}) => theme.colors.secondary.dark.six};
  
  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${({theme}) => theme.colors.accent.gradient.hover};
    opacity: 0;
    transition: opacity 0.08s ease-in-out;
  }
  
  &:hover {
    color: ${({theme}) => theme.colors.secondary.dark.six};
    &::after {
      opacity: 1;
    }
  }
  
  &:active {
    &::after {
      background: ${({theme}) => theme.colors.accent.gradient.active};
      opacity: 1;
    }
  }
`;
