import {FC} from "react";
import styled from "styled-components";
import {TNav} from "../../types/TNav";
import {useKeenSlider} from "keen-slider/react";
import {NavLink} from "react-router-dom";

export const NavMainTabs: FC<{data: Array<TNav>}> = ({data}) => {
  const [sliderRef] = useKeenSlider({
    initial: 0,
    loop: false,
    mode: "snap",
    rtl: false,
    slides: {
      spacing: 0,
      perView: "auto",
    },
  });

  return (
    <Wrapper>
      <Slider ref={sliderRef} className="keen-slider" >
        {data.map((item, index) =>
          <SliderItem key={index} className="keen-slider__slide" >
            <StyledLink to={item.link}>
              {item.text}
            </StyledLink>
          </SliderItem>
        )}
      </Slider>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  overflow: hidden;
  padding: 0 4px;
`;

const Slider = styled.div`
  overflow: visible!important;
`;

const SliderItem = styled.div`
  overflow: unset!important;
  display: block;
  width: auto!important;
`;

const StyledLink = styled(NavLink)`
  white-space: nowrap;
  font-family: ${({ theme }) => theme.fonts.bold.family};
  font-weight: ${({ theme }) => theme.fonts.bold.weight};
  color: ${({ theme }) => theme.colors.secondary.dark.six};
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.13em;
  text-transform: uppercase;
  text-decoration: none;
  min-height: 52px;
  max-height: 52px;
  display: flex;
  padding: 16px 12px 0;

  &::before {
    content: '';
    height: 2px;
    background: ${({ theme }) => theme.colors.accent.gradient.default};
    position: absolute;
    bottom: 0;
    left: 12px;
    right: 12px;
    opacity: 0;
  }

  &.active {
    color: ${({ theme }) => theme.colors.accent.dark.default};
    
    &::before {
      opacity: 1;
    }
  }
`;