import {FC} from "react";
import styled from "styled-components";

type TRibbon = 'new' | 'top' | 'active';
type TRibbonDir = 'left' | 'right';

export const Ribbon: FC<{ type: TRibbon, dir: TRibbonDir }> = ({ type, dir }) => {
  const content = () => {
    switch (type) {
      case 'new': return 'New';
      case 'top': return 'Top';
      case 'active': return 'Active';
      default: throw new Error('Ribbon type not found');
    }
  }

  return (
    <Wrapper $type={type}>
      <Body $type={type}>
        {content()}
      </Body>
    </Wrapper>
  );
}

const Body = styled.div<{ $type: TRibbon }>`
  font-family: ${({theme}) => theme.fonts.header.family};
  font-weight: ${({theme}) => theme.fonts.header.weight};
  font-size: 10px;
  width: 56px;
  height: 15px;    
  justify-content: center;
  display: flex;
  align-items: center;
  transform: rotate(-45deg);
  position: absolute;
  top: 4px;
  right: -4px;
  
  ${({ $type, theme }) => {
    if ($type === 'top') return `
      background-color: ${theme.colors.system.red};
    `;
    
    return `
      background-color: ${theme.colors.system.green};
    `;
  }}
`;

const Wrapper = styled.div<{ $type: TRibbon }>`
  position: relative;
  width: 36px;
  height: 36px;
  overflow: hidden;
`;
