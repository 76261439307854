import {FC, MutableRefObject, useContext, useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {NavMainTabs} from "../../components/Nav/NavMainTabs";
import {TNav} from "../../types/TNav";
import {NavMainIcons} from "../../components/Nav/NavMainIcons";
import {TBanner} from "../../types/TBanner";
import {CardHero} from "../../components/Card/CardHero";
import {
  useKeenSlider,
  KeenSliderPlugin,
  KeenSliderInstance,
} from "keen-slider/react"
import {NavbarContext} from "../../providers/Nabvar";

const dataNavs: Array<TNav> = [
  {icon: 'icon-slot', text: 'Slots', link: '/'},
  {icon: 'icon-dealer', text: 'Live Games', link: '/casino-live'},
  {icon: 'icon-balls-bal', text: 'Sports', link: '/table-games'},
  {icon: 'icon-dice', text: 'Table Games', link: '/instant-games'},
  {icon: 'icon-analitic', text: 'Instant', link: '/sports-betting'},
];

function ThumbnailPlugin(
  mainRef: MutableRefObject<KeenSliderInstance | null>
): KeenSliderPlugin {
  return (slider) => {
    function removeActive() {
      slider.slides.forEach((slide) => {
        slide.classList.remove("active")
      })
    }
    function addActive(idx: number) {
      slider.slides[idx].classList.add("active")
    }

    function addClickEvents() {
      slider.slides.forEach((slide: any, idx: any) => {
        slide.addEventListener("click", () => {
          if (mainRef.current) mainRef.current.moveToIdx(idx)
        })
      })
    }

    slider.on("created", () => {
      if (!mainRef.current) return
      addActive(slider.track.details.rel)
      addClickEvents()
      mainRef.current.on("animationStarted", (main: any) => {
        removeActive()
        const next = main.animator.targetIdx || 0
        addActive(main.track.absToRel(next))
        slider.moveToIdx(Math.min(slider.track.details.maxIdx, next))
      })
    })
  }
}

export const MainBanner: FC<{ data: Array<TBanner> }> = ({ data }) => {
  const [sliderMainRef, instanceRef] = useKeenSlider({
    initial: 0,
    loop: false,
    mode: "snap",
    rtl: false,
    slides: {
      spacing: 40,
      perView: 1,
    },
  });

  const [thumbnailRef] = useKeenSlider<HTMLDivElement>(
    {
      initial: 0,
      slides: {
        perView: 1,
        spacing: 0,
      },
    },
    [ThumbnailPlugin(instanceRef)]
  );

  const [state, setState] = useState({
    fixed: false,
    radius: 32,
    bodyHeight: 0,
    height: 0,
    navbarOpacity: 1,
    parallax: 0,
    opacityMain: 0,
  });

  const { isBurger } = useContext(NavbarContext);
  const containerRef = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);

  const navbarHeight = 60;
  const firstPointer = 100;
  const parallaxDelimiter = -6;

  const handleScroll = () => {
    const { scrollTop } = document.documentElement;
    const bodyHeight = bodyRef.current?.offsetHeight ? bodyRef.current?.offsetHeight - navbarHeight : 0;
    const height = containerRef.current?.offsetHeight ? containerRef.current?.offsetHeight - 32 : 0;
    const fixed = scrollTop >= bodyHeight;

    let radius = 32;
    let navbarOpacity = 1;

    const firstRefs = bodyHeight - firstPointer;
    if (scrollTop > firstRefs) {
      if (fixed) {
        radius = 0;
        navbarOpacity = 0;
      } else {
        const delimiter = (bodyHeight - firstRefs) / 32;
        radius = (bodyHeight - scrollTop) / delimiter;
        navbarOpacity = Math.ceil(bodyHeight - scrollTop) / 100;
      }
    }

    let opacityMain = 1;
    let parallax = 0;
    if (fixed) {
      opacityMain = 0;
      parallax = bodyHeight / parallaxDelimiter;
    } else {
      const delimiter = bodyHeight / 100;
      opacityMain = Math.ceil((bodyHeight - scrollTop) / delimiter) / 100;
      parallax = Math.floor(scrollTop / parallaxDelimiter);
    }

    setState(prev => ({
      ...prev, height, fixed, bodyHeight, radius, navbarOpacity, opacityMain, parallax,
    }));
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  return (
    <Wrapper
      $height={state.height}>
      <StickyWrapper
        $onBurger={!!isBurger}
        $fixed={state.fixed}
        $bodyHeight={state.bodyHeight}
        ref={containerRef} >
        <Container
          $fixed={state.fixed} id="Container">
          <Overlay
            style={{
              opacity: 1 - state.opacityMain,
            }} />
          <Body id="Body" ref={bodyRef}>
            <Slider ref={sliderMainRef} className="keen-slider">
              {data.map((item, index) => <SliderItem key={index} className="keen-slider__slide">
                  <CardHero data={item} />
                </SliderItem>
              )}
            </Slider>
          </Body>
          <Footer>
            <FooterTabs $opacity={state.navbarOpacity} >
              <NavMainTabs data={dataNavs} />
            </FooterTabs>
            <FooterIcons $opacity={state.navbarOpacity} >
              <NavMainIcons data={dataNavs} />
            </FooterIcons>
          </Footer>
          <Thumbnail
            style={{
              transform: `translateY(${state.parallax}px)`,
            }}>
            <Slider ref={thumbnailRef} className="keen-slider">
              {data.map((item, index) => <SliderThumbnailItem key={index} className="keen-slider__slide">
                  <ThumbnailImage
                    src={item.backgroundImage}
                    alt={item.title} />
                </SliderThumbnailItem>
              )}
            </Slider>
          </Thumbnail>
          <Brow
            style={{
              borderRadius: `${state.radius}px ${state.radius}px 0 0`,
            }} />
        </Container>
      </StickyWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ $height: number }>`
  position: relative;
  z-index: 900;
  margin-bottom: 32px;

  ${({$height}) => {
    if ($height > 0) return `
      height: ${$height}px;
    `;
  
    return ``;
  }}
`;

const StickyWrapper = styled.div<{ $onBurger: boolean, $fixed: boolean, $bodyHeight: number }>`
  width: 100%;
  padding-bottom: 32px;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  
  ${({$onBurger, $fixed}) => {
    if ($onBurger && $fixed) return `
      left: 50%;
    `;
  
    return `
      left: 0;
    `;
  }}
  
  ${({$fixed, $bodyHeight}) => {
    if ($fixed && $bodyHeight > 0) return `
      position: fixed;
      top: -${$bodyHeight}px;
    `;
  
    return `
      position: relative;
    `;
  }}
`;

const Container = styled.div<{ $fixed: boolean }>`
  background-color: ${({theme}) => theme.colors.secondary.dark.three};
  max-width: 100%;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: -32px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 28.26%, rgba(0, 0, 0, 0.04) 52.1%, rgba(0, 0, 0, 0.22) 62.47%, rgba(0, 0, 0, 0.6) 100%);
  }

  ${({$fixed}) => {
    if ($fixed) return `
        overflow: hidden;
      `;
    return ``;
  }}
`;

const ThumbnailImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Overlay = styled.div`
  position: absolute;
  z-index: 200;
  top: 0;
  left: 0;
  right: 0;
  bottom: -32px;
  background: rgba(22, 25, 25, 0.6);
  backdrop-filter: blur(8px);
  pointer-events: none;
`;

const Thumbnail = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: -80px;
`;

const FooterIcons = styled.div<{ $opacity: number }>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: ${({$opacity}) => $opacity};
  pointer-events: ${({$opacity}) => $opacity < 0.5 ? 'none' : 'auto'};
`;

const FooterTabs = styled.div<{ $opacity: number }>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: ${({$opacity}) => 1 - $opacity};
  pointer-events: ${({$opacity}) => $opacity > 0.5 ? 'none' : 'auto'};
`;

const Body = styled.div`
  padding-top: 60px;
  padding-bottom: 32px;
  position: relative;
  z-index: 100;
`;

const Footer = styled.div`
  min-height: 52px;
  max-height: 52px;
  position: relative;
  z-index: 300;
`;

const Brow = styled.div`
  position: absolute;
  z-index: 400;
  bottom: -32px;
  left: 0;
  right: 0;
  background-color: ${({theme}) => theme.colors.background.page};
  height: 32px;
`;

const Slider = styled.div`
  overflow: unset!important;
  width: 100%;
  height: 100%;
`;

const SliderItem = styled.div<{ $size?: 'large' | 'default' | 'small' | 'verySmall' }>`
  overflow: unset!important;
`;

const SliderThumbnailItem = styled.div<{ $size?: 'large' | 'default' | 'small' | 'verySmall' }>`
  overflow: unset!important;
  position: relative;
  z-index: 20;

  &:before {
    content: '';
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 28.26%, rgba(0, 0, 0, 0.04) 52.1%, rgba(0, 0, 0, 0.22) 62.47%, rgba(0, 0, 0, 0.6) 100%);
  }
`;
