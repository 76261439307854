import {FC} from "react";
import {TCardFeatures} from "../../types/TCardFeatures";
import styled from "styled-components";
import {BadgeSmall} from "../Badge/BadgeSmall";
import {Image} from "../Image/Image";
import {Link} from "react-router-dom";


export const FeatureGames: FC<TCardFeatures> = ({ title, url, color, itemsCount, previewGames }) => {
  return (
    <Wrapper>
      <TitleWrapper>
        <Title>{title}</Title>
        <Info>
          <i className="icon-circle-info" />
        </Info>
      </TitleWrapper>

      <Footer>
        <BadgeSmall>{itemsCount}</BadgeSmall>
        <Games>
          {previewGames.map((item, index) =>
            <GameLink key={index} to={item.url} $color={color}>
              <Image src={item.image} alt={item.title}/>
            </GameLink>
          )}
        </Games>
      </Footer>

      <MainLink to={url} />

      <Background className="background" $color={color} />
    </Wrapper>
  );
};


const Wrapper = styled.div`
  position: relative;
  height: 180px;
  padding: 18px 20px 20px;
  
  .background {
    transition: all 0.12s ease;
    transform: scale(1);
    border-radius: 16px;
  }
  
  &:hover {
    .background {
      transform: scale(1.08);
      border-radius: 20px;
    }
  }
`;

const Background = styled.div<{ $color: string }>`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  
  ${({theme, $color}) => {
    if (theme.colors.system[$color]) {
      return `
        background-color: ${theme.colors.system[$color]};
      `;
    }

    return `
      background-color: ${theme.colors.accent.light.default};
    `;
  }}
`;

const MainLink = styled(Link)`
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Info = styled.span`
  position: relative;
  z-index: 200;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({theme}) => theme.colors.secondary.dark.six};
  font-size: 20px;
  line-height: 20px;
  height: 20px;
  width: 20px;
  margin-left: 8px;
  margin-top: 2px;
`;

const Title = styled.h3`
  position: relative;
  z-index: 100;
  font-family: ${({theme}) => theme.fonts.header.family};
  font-weight: ${({theme}) => theme.fonts.header.weight};
  font-size: 20px;
  line-height: 24px;
`;

const Footer = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
`;

const Games = styled.div`
  display: flex;
  margin-bottom: -4px;
  margin-left: -4px;
  padding-top: 8px;
`;

const GameLink = styled(Link)<{ $color: string }>`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: -12px;
  position: relative;
  z-index: 200;
  transition: transform 0.12s ease;
  transform: scale(1);
  
  ${({theme, $color}) => {
    if (theme.colors.system[$color]) {
      return `
        background-color: ${theme.colors.system[$color]};
      `;
    }

    return `
      background-color: ${theme.colors.accent.light.default};
    `;
  }}
  
  &:hover {
    z-index: 200;
    transform: scale(1.16);
  }
`;
