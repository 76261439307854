import React, {FC} from "react";
import {TGame} from "../../types/TGame";
import styled from "styled-components";
import {GamesCarousel} from "../Games/GamesCarousel";
import {TJackpotProvider} from "../../types/TJackpotProvider";
import {ProvidersJackpot} from "../Providers/ProvidersJackpot";
import {JackpotCounter} from "../../components/Jackpot/JackpotCounter";

interface IJackpotCarousel {
  data: Array<TGame>;
  providers: Array<TJackpotProvider>;
}

export const JackpotCarousel: FC<IJackpotCarousel> = ({ data, providers }) => {

  return (
    <Wrapper>
      <Header>
        <Title>Jackpot</Title>
        <Jackpot>
          <JackpotCounter value={12345} currency={'EUR'} />
        </Jackpot>
      </Header>
      <GamesCarousel
        title="Games"
        size="verySmall"
        moreUrl="/live"
        data={data} />

      <ProvidersJackpot data={providers} />
    </Wrapper>
  );
};

const Header = styled.div`
  ${({ theme }) => {
    const { screen } = theme;

    if (screen?.breakpoint === 'desc_sm' || screen?.breakpoint === 'tab_lg') return `
      padding: 32px 32px 0;
    `;

    if (screen?.size === 'mob') return `
      padding: 32px 16px 0;
    `;

    return `
      padding: 36px 40px 0;
    `;
  }}
`;

const Jackpot = styled.div`
  ${({ theme }) => {
    if (theme.screen?.size === 'mob' && theme.screen.touch) return `
      padding-top: 10px;
      padding-bottom: 3px;
    `;
    
    return `
      padding-top: 15px;
      padding-bottom: 14px;
    `;
  }}
`;

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fonts.header.family};
  font-weight: ${({ theme }) => theme.fonts.header.weight};
  text-align: center;
  
  ${({ theme }) => {
    const { screen } = theme;

    if (screen?.size === 'tab' || screen?.size === 'mob') return `
      font-size: 20px;
      line-height: 24px;
    `;

    return `
      font-size: 24px;
      line-height: 28px;
    `;
  }}
`;

const Wrapper = styled.section`
  overflow: hidden;
`;
