import {FC, useState} from "react";
import styled from "styled-components";
import {TProvider} from "../../types/TProvider";
import {Image} from "../Image/Image";
import {Link} from "react-router-dom";
import { Currency } from "../Currency/Currency";
import {DropButton} from "../Button/DropButton";

export const TableProviders: FC<{ data: Array<TProvider>, jackpot?: boolean }> = ({ data, jackpot }) => {
  const [state, setState] = useState({
    isDropped: false,
  });

  const handleDrop = () => {
    setState(prev => ({ ...prev, isDropped: !prev.isDropped }));
  }

  return (
    <Wrapper $isDropped={state.isDropped}>
      <List>
        {data.map((item, index) => {
          if (index % 2 === 0) {
            const even = data[index + 1];
            return <Row key={index}>
              <Item>
                <ImageWrapper className="image">
                  <Image size="small" src={item.logoUrl} alt={item.title} />
                </ImageWrapper>
                <TextWrapper>
                  <Title className="text">{item.title}</Title>
                  {jackpot && item.jackpot && <Jackpot>
                    <Currency value={item.jackpot?.value} code={item.jackpot?.currency} />
                  </Jackpot>}
                </TextWrapper>
                {item.gamesCount}
                <MainLink to={item.url} />
              </Item>

              {even && <Item>
                <ImageWrapper className="image">
                  <Image size="small" src={even.logoUrl} alt={even.title} />
                </ImageWrapper>
                <TextWrapper>
                  <Title className="text">{even.title}</Title>
                  {jackpot && even.jackpot && <Jackpot>
                    <Currency value={even.jackpot?.value} code={even.jackpot?.currency} />
                  </Jackpot>}
                </TextWrapper>
                {even.gamesCount}
                <MainLink to={even.url} />
              </Item>}
            </Row>
          }
        })}
      </List>
      {!state.isDropped && <DropWrapper>
        <DropButton icon="icon-chevron-sm-bottom" onClick={handleDrop}>
          Show more
        </DropButton>
      </DropWrapper>}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ $isDropped: boolean }>`
  position: relative;
  overflow: hidden;
  transition: height 0.2s ease-in-out;
  
  ${({ $isDropped }) => {
    if ($isDropped) return `
      height: auto;
    `;
    
    return `
      height: 448px;
    `;
  }}
`;

const DropWrapper = styled.div`
  height: 100px;
  position: absolute;
  z-index: 200;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(180deg, rgba(22, 25, 25, 0) 0%, #161919 100%);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  pointer-events: none;
  
  >* {
    pointer-events: all;
  }
`;

const List = styled.div`
  position: relative;
  z-index: 100;
  
  >div {
    &:nth-child(odd) {
      background-color: ${({theme}) => theme.colors.secondary.dark.two};
    }
  }
`;

const Item = styled.div`
  font-family: ${({theme}) => theme.fonts.bold.family};
  font-weight: ${({theme}) => theme.fonts.bold.weight};
  letter-spacing: 0.04em;
  text-transform: uppercase;
  width: 100%;
  padding: 16px 12px;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  position: relative;
  
  .image {
    transition: all 0.12s ease;
    transform: scale(1);
  }
  
  .text {
    transition: all 0.12s ease;
  }
  
  &:hover {
    .image {
      transform: scale(1.32);
    }

    .text {
      color: ${({theme}) => theme.colors.secondary.dark.six};
    }
  }
`;

const Row = styled.div`
  display: flex;
  padding: 0 12px;
`;

const MainLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

const TextWrapper = styled.div`
  width: 100%;
  padding: 0 12px;
  max-height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.div`
  width: 100%;
  color: ${({theme}) => theme.colors.secondary.dark.five};
`;

const Jackpot = styled.span`
  margin-top: -4px;
  color: ${({theme}) => theme.colors.system.yellow};
`;

const ImageWrapper = styled.div`
  
`;
