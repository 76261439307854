import {FC, ReactNode} from "react";
import styled from "styled-components";

export const CounterBox: FC<{children: ReactNode, label: string, right?: boolean}> = ({ children, label, right }) => {
  return (
    <Wrapper>
      {children}
      <Label>{label}</Label>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  font-family: ${({theme}) => theme.fonts.header.family};
  font-weight: ${({theme}) => theme.fonts.header.weight};
  font-size: 24px;
  line-height: 28px;    
  display: flex;
  flex-direction: column;
`;

const Label = styled.span`
  font-family: ${({theme}) => theme.fonts.bold.family};
  font-weight: ${({theme}) => theme.fonts.bold.weight};
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  opacity: 0.6;
  padding-top: 5px;
`;
