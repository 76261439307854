import {FC} from "react";
import styled from "styled-components";

export const Divider: FC<{ small?: boolean }> = ({ small }) => <Box $small={small} />

const Box = styled.div<{ $small?: boolean }>`
  ${({ $small }) => {
    if ($small) return `
      height: 1px;
    `;
    return `
      height: 8px;
    `;
  }}
  background: ${({ theme }) => theme.colors.secondary.dark.two};
`