import {FC, Fragment, useContext} from "react";
import styled from "styled-components";
import {Social} from "../../components/Social/Social";
import {NavList} from "../../components/Nav/NavList";
import {TNav} from "../../types/TNav";
import {ThemeContext} from "../../ThemeSetup";

const threeCols: Array<Array<TNav>> = [
  [
    {text: 'About us', link: '/about'},
    {text: 'Contact us', link: '/contact-us'},
    {text: 'Accounts', link: '/accounts'},
    {text: 'Privacy, Management of Personal Data and Cookie Policy', link: '/privacy'},
  ],
  [
    {text: 'KYC Policies', link: '/about'},
    {text: 'Deposits & Payouts', link: '/contact-us'},
    {text: 'Responsible Gambling Policy', link: '/accounts'},
    {text: 'Self-Exclusion', link: '/privacy'},
  ],
  [
    {text: 'Dispute Resolution', link: '/about'},
    {text: 'Anti-Money Laundering', link: '/contact-us'},
    {text: 'Fairness & RNG Testing Methods', link: '/accounts'},
    {text: 'Affiliates', link: '/privacy'},
  ],
];

const twoCols: Array<Array<TNav>> = [
  [
    {text: 'About us', link: '/about'},
    {text: 'Contact us', link: '/contact-us'},
    {text: 'Accounts', link: '/accounts'},
    {text: 'Privacy, Management of Personal Data and Cookie Policy', link: '/privacy'},
    {text: 'KYC Policies', link: '/about'},
    {text: 'Deposits & Payouts', link: '/contact-us'},
  ],
  [
    {text: 'Responsible Gambling Policy', link: '/accounts'},
    {text: 'Self-Exclusion', link: '/privacy'},
    {text: 'Dispute Resolution', link: '/about'},
    {text: 'Anti-Money Laundering', link: '/contact-us'},
    {text: 'Fairness & RNG Testing Methods', link: '/accounts'},
    {text: 'Affiliates', link: '/privacy'},
  ],
];

const oneCols: Array<Array<TNav>> = [
  [
    {text: 'About us', link: '/about'},
    {text: 'Contact us', link: '/contact-us'},
    {text: 'Accounts', link: '/accounts'},
    {text: 'Privacy, Management of Personal Data and Cookie Policy', link: '/privacy'},
    {text: 'KYC Policies', link: '/about'},
    {text: 'Deposits & Payouts', link: '/contact-us'},
    {text: 'Responsible Gambling Policy', link: '/accounts'},
    {text: 'Self-Exclusion', link: '/privacy'},
    {text: 'Dispute Resolution', link: '/about'},
    {text: 'Anti-Money Laundering', link: '/contact-us'},
    {text: 'Fairness & RNG Testing Methods', link: '/accounts'},
    {text: 'Affiliates', link: '/privacy'},
  ],
];

const social: Array<TNav> = [
  {icon: 'icon-telegram', text: 'Telegram', link: '/telegram'},
  {icon: 'icon-facebook', text: 'Facebook', link: '/facebook'},
  {icon: 'icon-google', text: 'Google', link: '/google'},
];

export const Footer: FC = () => {
  const { screen } = useContext(ThemeContext);
  const list = (screen?.breakpoint !== 'tab_sm' && screen?.size !== 'mob')
    ? <>
      {threeCols.map((col, index) =>
        <NavBlockItem key={index}>
          <NavList
              data={col} flat divider />
        </NavBlockItem>
      )}
    </>
    : (screen?.breakpoint === 'tab_sm')
      ? <>
        {twoCols.map((col, index) =>
          <NavBlockItem key={index}>
            <NavList
              data={col} flat divider />
          </NavBlockItem>
        )}
      </>
      : <>
        {oneCols.map((col, index) =>
          <NavBlockItem key={index}>
            <NavList
              data={col} flat={screen?.size !== 'mob'} divider />
          </NavBlockItem>
        )}
      </>;

  return (
    <Wrapper>
      <Header>
        <Logo>
          <LogoImage src="./logo.svg" alt="Drip" />
        </Logo>

        {screen?.size !== 'mob' && <SocialBar>
          Follow us:
          <Social />
        </SocialBar>}
      </Header>

      <NavBlock id="NavBlock">
        {list}
      </NavBlock>

      {screen?.size === 'mob' && <>
        <DividerLarge/>
        <SocialBlock>
          <NavList
            flat={screen?.size !== 'mob'}
            title="Follow us"
            data={social} divider box iconMuted />
        </SocialBlock>
      </>}

      <License>
        <LicenseImage src="license.png" alt="License" />
        <LicenseText>
          The information on the Website is provided by the Website operator GALAKTIKA N.V., registered at Scharlooweg 39, Willemstad, Curaçao. The activities of GALAKTIKA N.V. are fully licensed and regulated by Antillephone N.V. (license No. 8048/JAZ2016-050 valid till 15 August 2023), Curaçao laws and regulations. The payment processing is managed by Unionstar Limited (registered under No. HE 356131 at Agias Fylaxeos & Zinonos Rossidi 2, 1st Floor, 3082 Limassol, Cyprus), subsidiary of GALAKTIKA N.V. The Website is operated by GALAKTIKA N.V. under the license of Unionstar Limited. <br/>
          Any person under 18 years old or under the legal age for gambling in a particular jurisdiction (“Eligible Age“) may not use the services of this Website under any circumstances.
        </LicenseText>
      </License>

      <Divider />

      <Copyright>
        <span>© 2023 Drip Casino. All rights reserved.</span>
        <Pegi src="./pegi.svg" alt="pegi" />
      </Copyright>
    </Wrapper>
  );
};

const Copyright = styled.div`
  color: ${({ theme }) => theme.colors.secondary.dark.five};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 40px;

  ${({ theme }) => {
    if (theme.screen?.size === 'mob') return `
      flex-direction: column-reverse;
      padding: 36px 16px;
    `;

    return `
    
    `;
  }}
`;

const Pegi = styled.img`
  height: 28px;

  ${({ theme }) => {
    if (theme.screen?.size === 'mob') return `
      margin-bottom: 28px;
    `;

    return ``;
  }}
`;

const LicenseImage = styled.img`
  width: 80px;
  height: 80px;
`;

const Divider = styled.div`
  background-color: ${({ theme }) => theme.colors.secondary.dark.three};
  height: 1px;
`;

const DividerLarge = styled.div`
  background-color: ${({ theme }) => theme.colors.secondary.dark.three};
  height: 8px;
`;

const License = styled.div`
  display: flex;
  padding-bottom: 40px;
  
  ${({ theme }) => {
    if (theme.screen?.size === 'mob') return `
      flex-direction: column;
      align-items: center;
      padding: 20px 16px 36px;
    `;
    
    return ``;
  }}
`;

const LicenseText = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.secondary.dark.four};

  ${({ theme }) => {
    if (theme.screen?.size === 'mob') return `
      text-align: center;
      font-size: 16px;
      line-height: 22px;
      padding-top: 20px;
    `;

    return `
      padding-left: 40px;
    `;
  }}
`;

const NavBlock = styled.div`
  display: flex;

  ${({ theme }) => {
    const { screen } = theme;
    if (screen?.size === 'mob') return `
      margin: 0 -12px;
    `;

    return `
      margin: 0 -24px;
    `;
  }}
  
  ${({ theme }) => {
    const { screen } = theme;
    
    if (screen?.size === 'mob') return `
      padding: 32px 0 0px;
    `;

    return `
      padding: 32px 0 52px;
    `;
  }}
`;

const SocialBlock = styled.div`
  display: flex;
  flex-direction: column;
  
  ${({ theme }) => {
    const { screen } = theme;
    
    if (screen?.size === 'mob') return `
      padding: 0 0 16px;
    `;

    return `
      padding: 32px 0 52px;
    `;
  }}
`;

const NavBlockItem = styled.div`
  width: 100%;

  ${({ theme }) => {
    const { screen } = theme;
    if (screen?.size === 'mob') return `
      padding: 0 12px;
    `;

    return `
      padding: 0 24px;
    `;
  }}
`;

const Wrapper = styled.div`
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.secondary.dark.two};
  padding: 40px;

  ${({ theme }) => {
    const { screen } = theme;
    if (screen?.breakpoint === 'desc_sm' || screen?.size === 'tab') return `
      padding: 40px 32px;
    `;
    
    if (screen?.size === 'mob') return `
      padding: 40px 0 116px;
    `;

    return `
      padding: 40px;
    `;
  }}
`;

const SocialBar = styled.div`
  font-family: ${({ theme }) => theme.fonts.bold.family};
  font-weight: ${({ theme }) => theme.fonts.bold.weight};
  color: ${({ theme }) => theme.colors.secondary.dark.four};
  font-size: 14px;
  line-height: 22px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  
  ul {
    margin-left: 16px;
  }
`;

const Logo = styled.div`
  height: 32px;
`;

const LogoImage = styled.img`

`;

const Header = styled.div`
  display: flex;
  ${({ theme }) => {
    if (theme.screen?.size === 'mob') return `
      justify-content: center;
    `;
    
    return `
      justify-content: space-between;
    `;
  }}
`;
