import {createContext, FC, ReactNode, useState} from "react";

interface INavbarContext {
  isBurger: boolean;
  onBurger: () => void;
  onBgOpacity: (value: number) => void;
  bgOpacity?: number;
}

export const NavbarContext = createContext<Partial<INavbarContext>>({});

export const NavbarProvider: FC<{ children: ReactNode }> = ({children}) => {
  const [state, setState] = useState({
    isBurger: false,
    bgOpacity: 60,
  });

  const onBurger = () => {
    setState(prev => ({...prev, isBurger: !prev.isBurger}));
  }

  const onBgOpacity = (bgOpacity: number) => {
    setState(prev => ({...prev, bgOpacity}));
  }

  return (
    <NavbarContext.Provider value={{
      ...state, onBurger, onBgOpacity,
    }}>
      { children }
    </NavbarContext.Provider>
  );
};
