import {FC} from "react";
import styled from "styled-components";
import {JackpotCounter} from "./JackpotCounter";
import {Button} from "../Button/Button";

export const JackpotBanner: FC = () => {
  return (
    <Wrapper>
      <Header>Jackpot</Header>
      <JackpotCounter value={23432.23} currency="EUR" size="small" />
      <ButtonWrapper>
        <Button url="/jackpot">Jackpot games</Button>
      </ButtonWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 24px 24px 32px;
`;

const ButtonWrapper = styled.div`
  padding-top: 40px;
`;

const Header = styled.div`
  font-family: ${({theme}) => theme.fonts.header.family};
  font-weight: ${({theme}) => theme.fonts.header.weight};
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  padding-bottom: 18px;
`;
