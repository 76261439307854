import {FC} from "react";
import {TBlogger} from "../../types/TBlogger";
import styled from "styled-components";
import {Image} from "../Image/Image";
import {Link} from "react-router-dom";
import {Badge} from "../Badge/Badge";
import {Ribbon} from "../Ribbon/Ribbon";

export const Blogger: FC<TBlogger> = ({title, isViewed, url, image, game}) => {
  return (
    <Wrapper>
      <ImageWrapper>
        {!isViewed && <BadgeWrapper>
            <Ribbon dir="left" type="new" />
          </BadgeWrapper>
        }
        <MainLink to={url}><span style={{display: 'none'}}>{title}</span></MainLink>
        <Title>{title}</Title>
        <MainImage className="image" src={image} alt={title} $isViewed={isViewed} />
      </ImageWrapper>

      <Footer>
        <FooterImage className="game-image">
          <Image src={game.image} alt={game.title} />
        </FooterImage>
        <GameInfo>
          <GameTitle>{game.title}</GameTitle>
          <Provider to={game.provider.url}>{game.provider.title}</Provider>
        </GameInfo>
        <FooterLink to={game.url}><span style={{display: 'none'}}>{game.provider.title}</span></FooterLink>
      </Footer>
    </Wrapper>
  );
};

const MainLink = styled(Link)`
  position: absolute;
  z-index: 300;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const BadgeWrapper = styled.div`
  position: absolute;
  z-index: 200;
  top: 0;
  left: 0;
`;

const FooterImage = styled.div`
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
`;

const Footer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 16px;
  position: relative;
  
  .game-image {
    transition: all 0.12s ease;
    transform: scale(1);
  }
  
  &:hover {
    .game-image {
      transform: scale(1.16);
    }
  }
`;

const GameInfo = styled.div`
  padding-left: 52px;    
  padding-top: 4px;
  width: 100%;
`;

const GameTitle = styled.div`
  font-family: ${({theme}) => theme.fonts.header.family};
  font-weight: ${({theme}) => theme.fonts.header.weight};
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Provider = styled(Link)`
  font-family: ${({theme}) => theme.fonts.bold.family};
  font-weight: ${({theme}) => theme.fonts.bold.weight};
  color: ${({theme}) => theme.colors.accent.dark.default};
  font-size: 10px;
  line-height: 12px;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  position: relative;
  z-index: 100;
  
  &:hover {
    color: ${({theme}) => theme.colors.accent.dark.default};
    text-decoration: underline;
  }
`;

const FooterLink = styled(Link)`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const ImageWrapper = styled.div`
  position: relative;
  padding-top: 175%;
  overflow: hidden;
  border-radius: 12px;
`;

const MainImage = styled.img<{ $isViewed: boolean }>`    
  position: absolute;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  object-fit: cover;
  
  ${({$isViewed}) => {
    if ($isViewed) {
      return `
        filter: grayscale(100%);
      `;
    };
    return ``;
  }}
`;

const Title = styled.h3`
  font-family: ${({theme}) => theme.fonts.header.family};
  font-weight: ${({theme}) => theme.fonts.header.weight};
  font-size: 16px;
  line-height: 20px;    
  display: flex;
  align-items: end;
  padding: 0 12px 16px;
  position: absolute;
  z-index: 200;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
  
  ${({theme}) => {
    if (theme.screen.size === 'mob' && theme.screen.touch) return `
      font-size: 12px;
      line-height: 16px;
    `;
    
    return `
      font-size: 16px;
      line-height: 20px; 
    `;
  }}
`;

const Wrapper = styled.div`
  .image {
    transition: transform 0.12s ease;
    transform: scale(1);
  }
  
  &:hover {
    .image {
      transform: scale(1.08);
    }
  }
`;
