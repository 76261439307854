import {FC} from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";

export const AppleSmall: FC<{ link: string }> = ({ link }) => {
  return (
    <Wrapper to={link}>
      <Label>Download on the</Label>
      <Image src="apps/apple.svg" alt="Apple" />
      <ImageHover className="hover" src="apps/apple_h.svg" alt="Google Play" />
    </Wrapper>
  );
};

const Wrapper = styled(Link)`
  display: flex;
  background-color: ${({ theme }) => theme.colors.secondary.dark.one};
  max-height: 62px;
  min-height: 62px;
  border-radius: 6px;
  width: 100%;
  position: relative;
  color: ${({ theme }) => theme.colors.secondary.dark.six};
  transition: all 0.12s ease;
  
  img {
    transition: all 0.12s ease;
    opacity: 1;
  }
  
  .hover {
    opacity: 0;
  }
  
  &:hover {
    color: ${({ theme }) => theme.colors.secondary.dark.one};
    background-color: ${({ theme }) => theme.colors.secondary.dark.six};
    
    img {
      opacity: 0;
    }

    .hover {
      opacity: 1;
    }
  }
`;

const Label = styled.span`
  display: inline-block;
  position: absolute;
  font-size: 8px;
  line-height: 12px;
  left: 50%;
  transform: translateX(-50%);
  top: 14px;
  padding-left: 14px;
  white-space: nowrap;
`;

const Image = styled.img`
  position: absolute;
  left: 50%;
  top: 16px;
  transform: translateX(-50%);
`;

const ImageHover = styled.img`
  position: absolute;
  left: 50%;
  top: 16px;
  transform: translateX(-50%);
`;
