import {FC} from "react";
import styled, {css} from "styled-components";
import {TTabs} from "../../types/TTabs";
import {Link} from "react-router-dom";

export const TabsPanel: FC<{ data: Array<TTabs> }> = ({ data }) => {
  return (
    <Wrapper>
      {data.map((item, index) => <Item key={index}>
        { item.link
          ? <StyledLink to={item.link}>
            {item.text}
          </StyledLink>
          : <PsLink $active={item.active} onClick={() => item.onClick && item.onClick(item.name)}>
            {item.text}
          </PsLink>
        }
      </Item>)}
    </Wrapper>
  );
};

const Wrapper = styled.ul`
  list-style: none;
  display: flex;
  background-color: ${({theme}) => theme.colors.secondary.dark.two};
  border-radius: 12px;
  padding: 4px;
`;

const Item = styled.li`
  width: 100%;
`;

const styledLink = css`
  height: 42px;    
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  transition: all 0.04s ease;
  font-size: 14px;
  line-height: 22px;
  font-family: ${({theme}) => theme.fonts.bold.family};
  font-weight: ${({theme}) => theme.fonts.bold.weight};
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: ${({theme}) => theme.colors.secondary.dark.five};
  
  &:hover {
    color: ${({theme}) => theme.colors.secondary.dark.six};
  }
`;

const StyledLink = styled(Link)`
  ${styledLink}
`;

const PsLink = styled.span<{ $active?: boolean }>`
  cursor: pointer;
  ${styledLink}
  
  ${({theme, $active}) => {
    if ($active) {
      return `
        background-color: ${theme.colors.secondary.dark.three};
        color: ${theme.colors.secondary.dark.six};
      `;
    }
    
    return `
      
    `;
  }}
`;
