import {FC, ReactNode} from "react";
import styled, {css} from "styled-components";
import {Link} from "react-router-dom";

interface IDropButton {
  isLink?: boolean
  url?: string
  children?: ReactNode
  onClick?: () => void
  icon?: string
  indicator?: boolean
}

export const DropButton: FC<IDropButton> = (
  {
    children,
    isLink,
    url,
    onClick,
    icon,
    indicator,
  }) => {
  if (isLink && !url) {
    throw new Error('DropButton: isLink is true, but url is not defined')
  }

  return (
    <Wrapper>
      {indicator && <Indicator />}
      {isLink && url
        ? <StyledLink to={url} >{children}{ icon && <Icon className={icon} /> }</StyledLink>
        : <Button onClick={onClick} >{children}{ icon && <Icon className={icon} /> }</Button>
      }
    </Wrapper>
  );
}

const Indicator = styled.div`
  position: absolute;
  right: -8px;
  top: -4px;
  width: 20px;
  height: 20px;
  background: ${({ theme }) => theme.colors.system.red};
  border-radius: 50%;
  border: 4px solid ${({ theme }) => theme.colors.secondary.dark.one};
`

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
`

const Icon = styled.i`
  margin-left: 8px;
  font-size: 16px;
  line-height: 24px;
`

const StyledButton = css`
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 0;
  max-height: 40px;
  min-height: 40px;
  background: ${({ theme }) => theme.colors.secondary.dark.three};
  transition: background 0.04s ease-in-out;
  font-family: ${({theme}) => theme.fonts.bold.family};
  font-weight: ${({theme}) => theme.fonts.bold.weight};
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.secondary.dark.five};
  padding: 8px 16px;
  border-radius: 37px;
  text-decoration: none;
  
  &:hover {
    background: ${({ theme }) => theme.colors.secondary.dark.four};
    color: ${({ theme }) => theme.colors.secondary.dark.six};
  }
`

const Button = styled.button`
  ${StyledButton}
`

const StyledLink = styled(Link)`
  ${StyledButton}
`
