import {FC, ReactNode} from "react";
import styled from "styled-components";

export const Badge: FC<{children: ReactNode, icon?: string}> = ({children, icon}) => {
  return (
    <Wrapper>
      {children}
      {icon && <Icon className={icon} />}
    </Wrapper>
  );
};

const Icon = styled.i`
  font-size: 16px;
  margin-left: 4px;
`;

const Wrapper = styled.span`
  color: ${({theme}) => theme.colors.secondary.dark.three};
  font-family: ${({theme}) => theme.fonts.bold.family};
  font-weight: ${({theme}) => theme.fonts.bold.weight};
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  background-color: ${({theme}) => theme.colors.secondary.dark.six};
  padding: 12px 12px;
  display: inline-flex;
  border-radius: 12px;
  align-items: center;
  
  .muted {
    color: ${({theme}) => theme.colors.secondary.dark.five};
    margin-right: 2px;
  }
`;
